import React, { useState } from "react";
import styles from "./OffersTable.module.css";
import { Creatives } from "../Creatives/Creatives";
import { Table } from "../../../../ui/Table/Table";
import { OfferTableRow } from "../OfferTableRow/OfferTableRow";
import { BackButton } from "../../../../ui/BackButton/BackButton";
import Pagination from "../../../../ui/pagination/Pagination";
import { useSelector } from "react-redux";

export const OffersTable = ({ onChangePage }) => {
	const { pagination, items, loading } = useSelector(state => state.ordOffers);
	const advertiser = useSelector(state => state.advertisers.current);
	const { title } = advertiser;
	const [selectedOffer, setSelectedOffer] = useState(null);
	
	const handleSelectOffer = (offerId) => () => {
		setSelectedOffer(offerId);
	};
	
	const handleBack = () => {
		setSelectedOffer(null);
	};
	
	if (selectedOffer) {
		const offer = items.find(item => item._id === selectedOffer);
		return <Creatives offer={offer} onBack={handleBack}/>;
	}
	
	
	return (
		<>
			<BackButton to="/ord-advertisers-empty"/>
			<h2 className={styles.title}>{title}</h2>
			<Table
				thead={
					<tr>
						<th>ID X-Partners</th>
						<th>Название</th>
						<th>Статус</th>
						<th>Количество креативов ОРД</th>
						<th></th>
					</tr>
				}
				loading={loading}
				tbody={items.map(item => <OfferTableRow key={item._id} item={item}
				                                        onClick={handleSelectOffer(item._id)}/>)}
			/>
			<Pagination paginationData={pagination} setParamData={onChangePage}/>
		</>
	);
};