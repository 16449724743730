import { format } from "date-fns";
import styles from "./ContractTableRow.module.css";
import {
	ReactComponent as EditIcon
} from "../../../../../assets/img/edit-icon-2.svg";

export const ContractTableRow = ({ item, onEdit }) => {
	return (
		<tr>
			<td className={styles.number}>{item.ordId ?? "-"}</td>
			<td>
				<div>
					{item.number}
					<br/>
					<span
						className={styles.secondary}>{item?.date && format(new Date(item.date), "dd.MM.yyyy")}</span>
				</div>
			</td>
			<td>status</td>
			<td>
				Company name
				<br/>
				ИНН {item.customerInn}
			</td>
			<td>
				Company name
				<br/>
				ИНН {item.executorInn}
			</td>
			<td>{item.isInitialContract ? "Да" : "-"}</td>
			<td>{item.description}</td>
			<td>
				<button className={styles.editBtn} onClick={onEdit}>
					<EditIcon className={styles.editIcon}/>
				</button>
			</td>
		</tr>
	);
};