import { format } from "date-fns";
import styles from "./ActsTableRow.module.css";
import {
	ReactComponent as EditIcon
} from "../../../../../assets/img/edit-icon-2.svg";

export const ActsTableRow = ({ item, onEdit }) => {
	const formatMask = "dd.MM.yyyy";
	const startDate = `С ${format(new Date(item.startDate), formatMask)}`;
	const endDate = `по ${format(new Date(item.endDate), formatMask)}`;
	
	return (
		<tr>
			<td className={styles.number}>{item.ordId ?? "-"}</td>
			<td>
				<div>
					{item.number}
					<br/>
					<span
						className={styles.secondary}>от {item?.date && format(new Date(item.date), "dd.MM.yyyy")}</span>
				</div>
			</td>
			<td>status</td>
			<td>
				{startDate}
				<br/>
				{endDate}
			</td>
			<td>
				{item.amount}
				<br/>
				<span
					className={styles.secondary}>{item.isVat ? "с НДС" : "без НДС"}</span>
			</td>
			<td>{item.description}</td>
			<td>
				<button className={styles.editBtn} onClick={onEdit}>
					<EditIcon className={styles.editIcon}/>
				</button>
			</td>
		</tr>
	);
};