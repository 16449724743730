/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './PartnersFilters.module.css';
import Button from "../../../ui/button_v2/ButtonV2";
import Search from "../../../ui/seacrh/Search";
import { useDispatch } from 'react-redux';
import { fetchManagers } from '../../../../actions/managers';
import { setFilterParam } from '../../../../core/helpers';
import SelectUi from '../../../ui/select/SelectUi';
import './PartnersFilters.css'

const PartnersFilters = ({ filterData, setFilterData }) => {
    const dispatch = useDispatch();
    const [fillData, setFillData] = useState({ manager: -2, search: '' });
    const [managers, setManagers] = useState([]);
    const [width, setWidth] = useState(0);
    const [openState, setOpenState] = useState(false)

    useEffect(() => {
        managers.forEach((item) => {
            if (width < item.label.length) {
                setWidth(item.label.length);
            }
        });

        const list = document.querySelector('.selPref__menu');

        if (list) {
            const parentElement = list.closest('.partner_filter_select');

            if (parentElement) {
                list.style.minWidth = `${width * 7.5}px`;
                list.style.display = `block`;
            }
        }

    }, [managers, width, openState]);

    useEffect(() => {
        if (fillData?.search?.key) {
            fillData.search = fillData?.search?.value || "";
            setFillData({ ...fillData });
            requestFilterData(filterData);
        }
    }, [fillData]);

    const requestFilterData = (filterData) => {
        setFilterData(
            {
                filter:
                    [
                        setFilterParam(filterData, 'manager', fillData.manager),
                        setFilterParam(filterData, 'search', fillData.search),
                    ],
                page: 1
            }
        );
    };

    const setFormattedManagers = (data) => {
        const managers = data.reduce((acc,cur)=>{
            acc.push({label: `${cur.lastName} ${cur.firstName} - ${cur.email}`, value: cur.email})

            return acc;
        },[]);

        setManagers(managers);
    }

    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchManagers(setFormattedManagers))
        }, 200);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.input_block} >
                <Search 
                    fontSize={14} 
                    maxWidth='unset' 
                    placeholder='id, e-mail, login' 
                    label='Партнер' 
                    value={fillData.search}
                    setValue={(val) => setFillData({ ...fillData, search: val })} 
                    default={fillData} 
                    requestFilterData={requestFilterData}
                />
            </div>
            <div className={`${styles.input_block} partner_filter_select`} 
                onClick={() => setOpenState(!openState)}
            >
                <SelectUi
                    label='Менеджер'
                    placeholder='Все'
                    options={[{label: 'Все', value: -2}, ...managers]}
                    onChange={val => {
                        setFillData({ ...fillData, manager: val === -2 ? -2 : [val] });
                    }}
                    classes='partners_filter'
                />
            </div>
            <div className={`${styles.BtnBlock}`}>
                <Button 
                    type={'primary'} 
                    onClick={() => {
                        requestFilterData(filterData);
                    }}>
                        Показать
                </Button>
            </div>
        </div>
    );
};

export default PartnersFilters;
