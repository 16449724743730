/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { createNotification } from "../../../../actions/app";
import { addComment, deletePayment, getBillingById, getCurrencyRates, payment } from "../../../../actions/billing";
import { NDS } from "../../../../core/config";
import { formatCurrencyAmount, isSuitCurrency } from "../../../../core/helpers";
import { addModal, closeModal, removeModal } from "../../../../reducers/appReducer";
import { setBillingComment, setBillingCommentOpenState, setCurrentBilling, setVerification } from "../../../../reducers/billingReducer";
import { delayByLoader } from '../../../../utils/deleyByLoader';
import AppContent from "../../../appContent/AppContent";
import Button from "../../../ui/button_v2/ButtonV2";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";
import Timer from "../../../ui/timer/Timer";
import styles from "./SingleBilling.module.css";
import ConvertTab from "./singleBillingTabs/convertTab/ConvertTab";
import GeneralTab from "./singleBillingTabs/generalTab/GeneralTab";
import HistoryTab from "./singleBillingTabs/historyTab/HistoryTab";
import InfoTab from "./singleBillingTabs/infoTab/InfoTab";
import Input from '../../../ui/input/Input';
import CommentRemoveIcon from '../../../../assets/img/comment-remove-icon.png'
import AddCommentIcon from '../../../../assets/img/add-comment.png'
import SelectUiv2 from "../../../ui/select/SelectUiv2";
import BillingPDFConfirmModal from '../../../modals/billingPDFConfirmModal/BillingPDFConfirmModal';

const BillingModalVerification = lazy(() => import("../../../modals/billingModalVerification/BillingModalVerification"));

const SingleBilling = () => {
    const dispatch = useDispatch();
    const { billingId } = useParams();
    const history = useHistory();
    const modals = useSelector(state => state.app.modals);
    const billing = useSelector(state => state.billing.current);
    const user = useSelector(state => state.user.currentUser);
    const billingCommentOpenState = useSelector(state => state.billing.billingCommentOpenState);
    const verification = useSelector(state => state.billing.verification);
    const billingInvoicePDFPath = useSelector(state => state.billing.billingInvoicePDFPath);
    const isNullInvoice = useSelector(state => state.billing.isNullInvoice);
    const [loading, setLoading] = useState(false);
    const [contentLoader, setContentLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('general');
    const [convert, setConvert] = useState(null);
    const [balance, setBalance] = useState(null);
    const [timer, setTimer] = useState('-');
    const [convertTime, setConvertTime] = useState();
    const [sendInvoiceAllowed, setSendInvoiceAllowed] = useState(false);
    const [payAllowed, setPayAllowed] = useState(false);
    const [action, setAction] = useState(null);
    const [billingPeriod, setBillingPeriod] = useState('');
    const [needCConvert, setNeedCConvert] = useState(false);
    const [commentText, setCommentText] = useState(billing?.billingComment || '');
    const [uaeCompany, setUaeCompany] = useState('');
    const [payCompany, setPayCompany] = useState('');
    const commentRef = useRef(null);
    const [activeHover, setActiveHover] = useState(false);
    const [billingConvertCurrency, setBillingConvertCurrency] = useState(null);

    const payerOptions = [
        {label: 'M HIPPO FZ-LLC', value: 2 },
        {label: 'IT SOLUTIONS FZCO', value: 1 }
    ];

    const uaeOptions = [
        {label: 'Да', value: true },
        {label: 'Нет', value: false }
    ];

    const handleConfirmClose = async () => {
        setLoading(true);
        setAction('setNullInvoicePaid');
        await dispatch(payment({
            id: billing.id,
            code: '',
            email: billing.partner.email,
            payType: 'setPaidWiretransferNullInvoice' // Изменили тип платежа
        }));
        setLoading(false);
    };
    
    useEffect(()=>{
        if (billingInvoicePDFPath){
            dispatch(
                addModal({
                    id: "BillingPDFConfirmModal",
                    size: "bigger",
                    children: (
                        <Suspense fallback={<ContentLoader />}>
                            {billingInvoicePDFPath && (
                                <BillingPDFConfirmModal
                                    data={{ billingInvoicePDFPath ,send}}
                                    onError={() => {
                                        dispatch(removeModal("BillingPDFConfirmModal"));
                                    }}
                                />
                            )}
                        </Suspense>
                    ),
                })
            );
        }
    }, [billingInvoicePDFPath])

    useEffect(() => {
        if (isNullInvoice) {
            dispatch(
                addModal({
                    id: "BillingPDFConfirmModal",
                    size: "middle",
                    children: (
                        <Suspense fallback={<ContentLoader />}>
                            <BillingPDFConfirmModal
                                data={{
                                    isNullInvoice: true,
                                    message: 'Платёж будет полностью засчитан в счёт долга партнёра. Пожалуйста, подтвердите списание.',
                                    confirmClose: handleConfirmClose
                                }}
                                onError={() => {
                                    dispatch(removeModal("BillingPDFConfirmModal"));
                                }}
                            />
                        </Suspense>
                    ),
                })
            );
        }
    }, [isNullInvoice, dispatch]);
    
    
    

    useEffect(() => {
        setCommentText(billing?.billingComment);
        dispatch(setBillingComment(billing?.billingComment))
    }, [billing]);

    useEffect(() => {
        setLoading(true);
        dispatch(getBillingById(billingId))
            .then(result => {
                setLoading(false);
                if (!result) {
                    history.push('/billing');
                }
            });

        return () => {
            dispatch(setCurrentBilling(null))
        };
    }, [dispatch, history, billingId]);


    useEffect(() => {
        dispatch(getCurrencyRates());
        if (billing) {
            if (billing.convertAmount) {
                setConvert({ amount: billing.convertAmount, currency: billing.convertCurrency });
            }

            setBalance({
                amount: billing.partner.revenue ? billing.partner.revenue : 0,
                currency: billing.partner.revenue ? billing.partner.currency : null
            });

            setConvertTime(billing.convertDate);

            if ((billing.status === 1 || billing.status === -1 || billing.status === 5)
                && !billing.archive && !billing.partner.archive) {
                if (billing.payAgent === 3) {
                    if ( billing.paySystem === 2 ) {
                        setSendInvoiceAllowed(true);
                    }
                } else {
                    setPayAllowed(true);
                }
            } else {
                setPayAllowed(false);
                setSendInvoiceAllowed(false);
            }

            if (isSuitCurrency(billing)) {
                setNeedCConvert(false);
            } else {
                setNeedCConvert(true);
            }
        }

    }, [billing, dispatch]);

    useEffect(() => {
        if (verification === true) {
            dispatch(addModal({
                id: 'billingModalVerification',
                size: 'big',
                children:
                    <Suspense fallback={<ContentLoader />}>
                        {BillingModalVerification &&
                            <BillingModalVerification
                                billing={billing}
                                type={action}
                                params={{
                                    billingPeriod: billingPeriod,
                                    isUae: uaeCompany,
                                    billedTo: payCompany,
                                }}
                                onError={() => {
                                    dispatch(removeModal('billingModalVerification'))
                                }}
                            />}
                    </Suspense>
            }));
            dispatch(setVerification(false));
        }
        // eslint-disable-next-line
    }, [billing, verification]);

    useEffect(() => {
        const currentModals = modals.find(item => item.id === 'billingModalVerification');

        if (timer === false && currentModals
            && currentModals.children.props.children.props.type !== 'billingConvertCurrency') {

            dispatch(closeModal('billingModalVerification'));

        }
        // eslint-disable-next-line
    }, [timer]);

    const send = async (PDFPath) => {
        await dispatch(payment({
            id: billing.id,
            code: true,
            payType: 'sendInvoice',
            billingPeriod: billingPeriod,
            isUae: uaeCompany,
            billedTo: payCompany,
            isCreate: false,
            PDFPath
        }));
    };

    const makePayment = async () => {
        if (timer !== false) {
            setLoading(true);
            setAction(null);
            await dispatch(payment({ id: billing.id, code: '', email: billing.partner.email }));
            setLoading(false);
        }
    };

    const setBillingPaid = async () => {
        setLoading(true);
        setAction('billingSetPaid');
        await dispatch(payment({ id: billing.id, code: '', email: billing.partner.email, payType: 'setPaid' }));
        setLoading(false);
    };

    const deleteBilling = async () => {
        setLoading(true);
        setAction('billingDeletePayment');
        await dispatch(deletePayment({ id: billing.id, code: '' }));
        setLoading(false);
    };

    const sendInvoice = async () => {
        if (!billingPeriod) {
            dispatch(createNotification({
                type: 'error',
                children: 'Заполните поле платежного периода'
            }));
            return;
        }
        if (uaeCompany === '') {
            dispatch(createNotification({
                type: 'error',
                children: 'Задайте признак UAE компании'
            }));
            return;
        }
        if (payCompany === '') {
            dispatch(createNotification({
                type: 'error',
                children: 'Задайте компанию-плательщика XP'
            }));
            return;
        }

        if (timer !== false) {
            setLoading(true);
            setAction('billingSendInvoice');
            await dispatch(payment({
                    id: billing.id,
                    code: '',
                    email: billing.partner.email,
                    payType: 'sendInvoice',
                    billingPeriod: billingPeriod,
                    isUae: uaeCompany,
                    billedTo: payCompany,
                }));
                setLoading(false);
        }
    };

    const onTabClickHandler = (value) => {
        delayByLoader(true, setContentLoader, 300);
        setActiveTab(value);
        dispatch(setBillingCommentOpenState(false));
        setCommentText(billing?.billingComment);
        dispatch(setBillingComment(billing?.billingComment))

    };

    const addBillingComment = (text, isRemove) => {
        if (!text) {
            setCommentText("");
            dispatch(setBillingComment(''))
        }
        const body = {
            id: billingId,
            comment: text || ""
        };
        dispatch(addComment(body, addBillingCommentSuccess));
    };

    const addBillingCommentSuccess = (data) => {
        delayByLoader(true, setLoading, 200);
        dispatch(setBillingCommentOpenState(false));
        dispatch(setCurrentBilling({ ...billing, billingComment: data.billingComment }));
        setCommentText('');
        dispatch(setBillingComment(''))
    };

    const onSetComment = (value) => {
        setCommentText(value);
        dispatch(setBillingComment(value))
    }

    return (
        <AppContent>
            <div className="container wrapper">
                <div className='row'>
                    <div className="col-12">
                        <div className='m-3'><NavLink className={styles.BackLink} to="/billing">Назад</NavLink></div>
                        <div className={styles.BillingContent}>
                            {loading && <ContentLoader />}
                            {billing &&
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-10 offset-2">
                                                <div className={styles.Header}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginBottom: '30px',
                                                    }}>
                                                        <h1 className={styles.Title}>
                                                        {billing.billingType === 'credit'
                                                            ? 'Аванс'
                                                            : billing.isConversionAndRef
                                                                ? 'Оплата за реферальные и конверсии'
                                                                : billing.referral
                                                                    ? 'Реферальные выплаты'
                                                                    : 'Оплата за конверсии'}
                                                            <div className={styles.TransactionId}>
                                                                <span>affise ID: {billing.affiseId}</span>
                                                            </div>
                                                        </h1>
                                                        <div className={`${styles.add_comment} add_comment`}>
                                                            {billingCommentOpenState ?
                                                                <div className={styles.add_comment_content} >
                                                                    <Input style={{ border: '1px solid #000', height: '28px' }} type='comment' placeholder="Введите комментарий" setValue={onSetComment} addBillingComment={addBillingComment} value={commentText} />
                                                                    <img className={styles.add_icon} onClick={() => addBillingComment(commentText)} src={AddCommentIcon} alt="comment" />
                                                                </div>
                                                                : !billing.billingComment ? <p className={styles.add_comment_text} onClick={() => dispatch(setBillingCommentOpenState(!billingCommentOpenState))}>+ Комментарий к транзакции</p> :
                                                                    <div className={styles.comment_content}>
                                                                        <p className={`${styles.add_comment_text} ${styles.add_comment_text_show}`} style={{ color: '#B23333', border: 'none' }} onClick={() => dispatch(setBillingCommentOpenState(!billingCommentOpenState))}>{billing.billingComment}</p>
                                                                        <img onClick={() => addBillingComment("", true)} src={CommentRemoveIcon} alt="comment" />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="d-flex">
                                                        {payAllowed &&
                                                            <div style={{  height: 'fit-content', position: 'relative' }} onMouseEnter={() => setActiveHover(true)} onMouseLeave={() => setActiveHover(false)}>
                                                                <div ref={commentRef} className={styles.tooltip_button} style={{
                                                                    visibility: timer && billing?.partner?.stopped && activeHover ? 'visible' : 'hidden'
                                                                }}>Запрет вывода средств</div>
                                                            <Button
                                                                className={'d-flex align-items-center'}
                                                                type={'primary'}
                                                                disabled={timer === false || needCConvert}
                                                                onClick={() => {
                                                                    if (!billing.manager || !billing.manager.id) {
                                                                        dispatch(createNotification({
                                                                            type: 'error',
                                                                            children: 'Менеджер не назначен - платежи недоступны'
                                                                        }));
                                                                        return;
                                                                    }
                                                                    makePayment();
                                                                }}
                                                                style={{ opacity: timer && billing?.partner?.stopped ? 0.3 : 1, pointerEvents: timer && billing?.partner?.stopped ? 'none' : 'all' }}
                                                            >
                                                                {timer === false ? 'Обновите конвертацию' : 'Оплатить'}
                                                                {convertTime &&
                                                                    <Timer
                                                                        expiryTimestamp={new Date(convertTime).setSeconds(new Date(billing.convertDate).getSeconds() + 300)}
                                                                        timer={timer}
                                                                        setTimer={setTimer}
                                                                    />
                                                                }
                                                            </Button>
                                                         </div>
                                                        }
                                                        {sendInvoiceAllowed &&
                                                            <>
                                                                <SelectUiv2
                                                                    options={payerOptions}
                                                                    value={payerOptions.find(item =>
                                                                        item.value === payCompany
                                                                    )}
                                                                    onChange={value => setPayCompany(value)}
                                                                    placeholder={'Плательщик XP'}
                                                                />
                                                                <input
                                                                    className={styles.PeriodInput}
                                                                    value={billingPeriod} onChange={(e) => setBillingPeriod(e.target.value)}
                                                                    placeholder="Платежный период"
                                                                    title="Введите платежный период в формате June'23"
                                                                />
                                                                <SelectUiv2
                                                                    options={uaeOptions}
                                                                    value={uaeOptions.find(item =>
                                                                        item.value === uaeCompany
                                                                    )}
                                                                    onChange={value => setUaeCompany(value)}
                                                                    placeholder={'Партнер из UAE?'}
                                                                />
                                                                <div style={{ height: 'fit-content', position: 'relative' }} onMouseEnter={() => setActiveHover(true)} onMouseLeave={() => setActiveHover(false)}>
                                                                    <div ref={commentRef} className={styles.tooltip_button} style={{
                                                                        visibility: timer && billing?.partner?.stopped && activeHover ? 'visible' : 'hidden'
                                                                    }}>Запрет вывода средств</div>
                                                                    <Button
                                                                        className={'d-flex align-items-center ml-1'}
                                                                        type={'primary'}
                                                                        disabled={timer === false || (!!billing?.convertDate === false && billingConvertCurrency !== billing?.currency) || (user?.capability !== 1 && billing?.manager?.email !== user?.email)}
                                                                        onClick={() => {
                                                                            console.log(billing.manager);
                                                                            if (!billing.manager || !billing.manager.id) {
                                                                                dispatch(createNotification({
                                                                                    type: 'error',
                                                                                    children: 'Менеджер не назначен - платежи недоступны'
                                                                                }));
                                                                                return;
                                                                            }
                                                                            sendInvoice();
                                                                        }}
                                                                        style={{ opacity: timer && billing?.partner?.stopped  ? 0.3 : 1, pointerEvents: timer && billing?.partner?.stopped ? 'none' : 'all' }}
                                                                    >
                                                                        {timer === false ? 'Обновите конвертацию' : 'Оплатить'}
                                                                        {convertTime &&
                                                                            <Timer
                                                                                expiryTimestamp={new Date(convertTime).setSeconds(new Date(billing.convertDate).getSeconds() + 300)}
                                                                                timer={timer}
                                                                                setTimer={setTimer}
                                                                            />
                                                                        }
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        }
                                                        {billing.affiseId.indexOf('adm') !== -1 && (billing.status === 1 || billing.status === -1) &&
                                                            <Button
                                                                type={'danger'}
                                                                className="ml-3"
                                                                onClick={deleteBilling}
                                                            >
                                                                Удалить
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`col-2 pr-0 ${styles.BorderRight}`}>
                                                <ul className={styles.TabLinks}>
                                                    <li className={activeTab === 'general' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('general')}>Основное</li>
                                                    <li className={activeTab === 'info' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('info')}>Доп. информация</li>
                                                    <li className={activeTab === 'history' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('history')}>История</li>
                                                    <li className={activeTab === 'convert' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('convert')}>Конвертация</li>
                                                </ul>
                                            </div>
                                            <div className="col-10 px-4">
                                                <div className={styles.GeneralInfo}>
                                                    <div className="row mb-3">
                                                        <div className="col-5">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label className={styles.Label}>Вебмастер</label>
                                                                    <div className={`${styles.Email} mt-0`}>{billing.partner.email}</div>
                                                                    <div className={`${styles.Small} ${styles.Gray} mt-1`}>
                                                                        ID: {billing.partner.affiseId}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className={styles.Label}>Менеджер</label>
                                                                    <div className={`${styles.Email} mt-0`}>{billing.manager.email}</div>
                                                                    <div className={`${styles.Small} ${styles.Gray} mt-1`}>
                                                                        {`${billing.manager.firstName} ${billing.manager.lastName}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="row">
                                                            <div className="col-4">
                                                                    <label className={styles.Label}>Сумма</label>
                                                                    <div className={styles.Amount}>
                                                                        {formatCurrencyAmount(billing.amount, billing.currency)}
                                                                    </div>
                                                                    {billing.refAmount &&
                                                                         <div className={styles.RefAmount}>
                                                                             <div>В т.ч. рферальные выплаты:</div>
                                                                             <div className="font-weight-bold">{formatCurrencyAmount(billing.refAmount, billing.currency)}</div>
                                                                         </div>
                                                                    }
                                                                    {billing.nds !== undefined &&
                                                                        <div className="mt-3">
                                                                            <label className={styles.Label}>
                                                                                {billing.nds
                                                                                    ? "НДС"
                                                                                    : "Без НДС"
                                                                                }
                                                                            </label>
                                                                            {billing.nds &&
                                                                                <div className={styles.NdsAmount}>
                                                                                    {formatCurrencyAmount(billing.amount * NDS / 100, billing.currency)}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-4">
                                                                    <label className={styles.Label}>Баланс</label>
                                                                    {balance &&
                                                                        <div className={`${styles.Amount} ${balance.amount < 0 ? styles.Red : styles.Blue}`}>
                                                                            {formatCurrencyAmount(balance.amount, balance.currency)}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {convert &&
                                                                    <div className="col-4">
                                                                        <label className={styles.Label}>Конвертировано</label>
                                                                        <div className={`${styles.Amount} ${styles.Gray}`}>
                                                                            {formatCurrencyAmount(convert.amount, convert.currency)}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    contentLoader && <div className={styles.contentLoader}>
                                                        <ContentLoader />
                                                    </div>
                                                }
                                                {activeTab === 'general' && <GeneralTab timer={timer} billing={billing} setLoading={setLoading} />}
                                                {activeTab === 'info' &&
                                                    <InfoTab
                                                        billing={billing}
                                                        payAllowed={payAllowed}
                                                        timer={timer}
                                                        setBillingPaid={setBillingPaid}
                                                    />
                                                }
                                                {activeTab === 'history' && <HistoryTab billing={billing} />}
                                                {activeTab === 'convert' && <ConvertTab setBillingConvertCurrency={setBillingConvertCurrency} billing={billing} setLoading={setLoading} setTimer={setTimer} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AppContent >
    );
};

export default SingleBilling;
