import React, {useState} from 'react';
import styles from '../AdvertiserCard.module.css';
import {OptionsAdvertiserEntityType} from "../../../../../core/config";
import SelectUi from "../../../../ui/select/SelectUi";
import InputLabel from "../../../../ui/input/inputLabel/InputLabel";
import InputRadio from "../../../../ui/input/InputRadio/InputRadio";
import {update} from "../../../../../actions/advertisers";
import {useDispatch } from "react-redux";
import Button from "../../../../ui/button_v2/ButtonV2";

const GeneralAdvTab = ({ advertiser }) => {
    const dispatch = useDispatch();

    const [fields, setFields] = useState({
        id: advertiser.id,
        entity: advertiser.entity,
        url: advertiser.url,
        inn: advertiser.inn,
        nds: advertiser.nds,
    });

    const updateAdv =  () => {
         dispatch( update( fields ) );
    };

    return (
        <div className={styles.AdvTabContent}>

            <Button type={'primary'} className={styles.Save} onClick={updateAdv} >Сохранить</Button>

            <div className="row pt-1">
                <div className="col-4">
                    <label className={styles.FieldLabel}>Рекламодатель</label>
                    <div className={`${styles.LightBold} mt-3`}>{advertiser.title}</div>
                    <div className={`${styles.LightBold} mt-1`}>{advertiser.email}</div>
                </div>
                <div className="col-4">
                    <label className={styles.FieldLabel}>Менеджер</label>
                    <div className={`${styles.LightBold} mt-3`}>{`${advertiser.manager.firstName} ${advertiser.manager.lastName}`}</div>
                </div>
            </div>

            <div className={`${styles.AdvCommonData} row mt-5`}>
                <div className="col-6 d-flex align-items-center justify-content-between">
                    <div className={styles.FieldLabel}>Юридическое лицо</div>
                    <div className={styles.InputBlock}>
                        <SelectUi
                            options={ OptionsAdvertiserEntityType }
                            defaultValue={ OptionsAdvertiserEntityType.find( item => item.value === fields.entity ) }
                            onChange={ val => setFields({ ...fields, entity: val }) }
                        />
                    </div>
                </div>
                <div className="col-5 d-flex align-items-center">
                    <div className={`${styles.FieldLabel} mr-4`}>Сайт</div>
                    <div className={styles.InputBlock}>
                        <InputLabel
                            className={styles.AdvCardInput}
                            value={fields.url}
                            setValue={ val => setFields({ ...fields, url: val }) }
                        />
                    </div>
                </div>
            </div>

            <div className={`${styles.AdvCommonData} row mt-3`}>
                <div className="col-6 d-flex align-items-center justify-content-between">
                    <div className={styles.FieldLabel}>ИНН</div>
                    <div className={styles.InputBlock}>
                        <InputLabel
                            className={styles.AdvCardInput}
                            value={fields.inn}
                            setValue={ val => setFields({ ...fields, inn: val }) }
                        />
                    </div>
                </div>
                <div className="col-5 d-flex align-items-center">
                    <div className={`${styles.FieldLabel} mr-3`}>Страна </div>
                    <div className="d-flex align-items-center" style={{height: 40}}>{advertiser.country}</div>
                </div>
            </div>

            {advertiser.country === 'RU' &&
                <div className="row mt-3">
                    <div className="col-6 d-flex justify-content-end">
                        <div className={styles.RadioBlockFlex}>
                            <InputRadio
                                value={false}
                                check={ fields.nds === false }
                                label='Без НДС'
                                name='NDS'
                                required={true}
                                id='NDS2'
                                setValue={ val => setFields({ ...fields, nds: val }) }

                            />
                            <InputRadio
                                value={true}
                                check={ fields.nds === true }
                                label='С НДС'
                                required={true}
                                name='NDS'
                                id='NDS1'
                                setValue={ val => setFields({ ...fields, nds: val }) }
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default GeneralAdvTab;
