import * as yup from 'yup';

export const fieldNames = {
	initialContractId: "initialContractId",
	type: "type",
	form: "form",
	isNative: "isNative",
	isSocial: "isSocial",
	urls: "urls",
	okveds: "okveds",
	description: "description",
	file: "file"
};

export const validationSchema = yup.object({
	[fieldNames.initialContractId]: yup.object().typeError('Обязательное поле').required('Обязательное поле'),
	[fieldNames.urls]: yup.string().required('Обязательное поле'),
	[fieldNames.okveds]: yup.string().required('Обязательное поле'),
	[fieldNames.description]: yup.string().required('Обязательное поле')
})