import Status from "../components/pages/billing/billingStatus/BillingStatus";
import ConversionsStatus from "../components/pages/conversions/conversionsStatus/ConversionsStatus";
import OptionColoredLabel from "../components/ui/multiSelect/selectCheckboxOption/optionColoredLabel/OptionColoredLabel";

require('dotenv').config();
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_HOST = process.env.REACT_APP_HOST;
export const NDS = 20;
export const RulesData = {
    structure: {
        rules: {
            'users': {id: 'users', content: 'Управление пользователями'},
            'billing': {id: 'billing', content: 'Биллинг'},
            'conversions': {id: 'conversions', content: 'Управление конверсиями'},
            'partners': {id: 'partners', content: 'Вебмастера'},
            'offers': {id: 'offers', content: 'Офферы'},
            'offersPayments': {id: 'offersPayments', content: 'Выплаты в офферах'},
            'advertisers':{id:'advertisers',content:'Рекламодатели'},
            'paymentOrder':{id:'paymentOrder',content:'Загрузка пл./поручений'},
            'convertCurrency':{id:'convertCurrency',content:'Конвертация'},
            'knowledge':{id:'knowledge',content:'База знаний'},
            'reports':{id:'reports',content:'Отчеты медиабаинга'},
            'mbProjects':{id:'mbProjects',content:'Проекты медиабаинга'},
            'expenses':{id:'expenses',content:'Расходы вебмастеров'},
            'income':{id:'income',content:'Доходы вебмастеров'},
            'currency':{id:'currency',content:'Курсы валют'},
            'billingComment':{id:'billingComment',content:'Комментарии к биллингам'},
        },
        columns: {
            '3': {
                id: '3',
                title: 'Доступ закрыт',
                ruleIds: []
            },
            '2': {
                id: '2',
                title: 'Чтение',
                ruleIds: []
            },
            '1': {
                id: '1',
                title: 'Редактирование',
                ruleIds: []
            },
        },
        columnOrder: ['3', '2', '1']
    },
    default: {
        admin: [
            {name: 'users', value: '1'},
            {name: 'billing', value: '1'},
            {name: 'conversions', value: '1'},
            {name: 'partners', value: '1'},
            {name: 'offers', value: '1'},
            {name: 'offersPayments', value: '1'},
            {name: 'advertisers', value: '1'},
            {name: 'paymentOrder', value: '1'},
            {name: 'convertCurrency', value: '1'},
            {name: 'knowledge', value: '1'},
            {name: 'reports', value: '1'},
            {name: 'expenses', value: '1'},
            {name: 'income', value: '1'},
            {name: 'mbProjects', value: '1'},
            {name: 'currency', value: '1'},
            {name: 'billingComment', value: '1'},
        ],
        manager: [
            {name: 'users', value: '2'},
            {name: 'billing', value: '2'},
            {name: 'conversions', value: '2'},
            {name: 'partners', value: '2'},
            {name: 'offers', value: '2'},
            {name: 'offersPayments', value: '2'},
            {name: 'advertisers', value: '2'},
            {name: 'paymentOrder', value: '2'},
            {name: 'convertCurrency', value: '2'},
            {name: 'knowledge', value: '2'},
            {name: 'reports', value: '3'},
            {name: 'expenses', value: '3'},
            {name: 'income', value: '3'},
            {name: 'mbProjects', value: '3'},
            {name: 'currency', value: '1'},
            {name: 'billingComment', value: '1'},
        ],
        guest: [
            {name: 'users', value: '3'},
            {name: 'billing', value: '3'},
            {name: 'conversions', value: '3'},
            {name: 'partners', value: '3'},
            {name: 'offers', value: '3'},
            {name: 'offersPayments', value: '3'},
            {name: 'advertisers', value: '3'},
            {name: 'paymentOrder', value: '3'},
            {name: 'convertCurrency', value: '3'},
            {name: 'knowledge', value: '2'},
            {name: 'reports', value: '3'},
            {name: 'expenses', value: '3'},
            {name: 'income', value: '3'},
            {name: 'mbProjects', value: '3'},
            {name: 'currency', value: '3'},
            {name: 'billingComment', value: '3'},
        ]
    }
};
export const BillingStatus = [
    {name: 'Отклонено', value: -1},
    {name: 'Ожидает', value: 1},
    {name: 'Оплачено', value: 2},
    {name: 'Частично оплачено', value: 3},
    {name: 'В обработке', value: 4},
];
export const BillingCurrency = [
    {name: 'USD', symbol: '$', value: 2},
    {name: 'EUR', symbol: '€', value: 3},
    {name: 'KZT', symbol: '₸', value: 85},
    {name: 'RUB', symbol: '₽', value: 1},
    {name: 'AED', symbol: 'DH', value: 4},
    {name: 'UZS', symbol: 'UZS', value: 152},
    {name: 'USDTt', symbol: '₮', value: 200},

];
export const CurrencyByFilter = [
    {label: 'USD ($)', value: 2},
    {label: 'EUR (€)', value: 3},
    {label: 'KZT (₸)', value: 85},
    {label: 'RUB (₽)', value: 1},
    {label: 'AED (DH)', value: 4},
    {label: 'UZS', value: 152},
    {label: 'USDTt (₮)', value: 200},

];
export const BillingPaySystems = [
    {name: 'Webmoney WMZ', value: 1},
    {name: 'Wire transfer', value: 2},
    {name: 'Capitalist USD', value: 3},
    {name: 'Capitalist EUR', value: 4},
    {name: 'Capitalist RUR', value: 5},
    {name: 'Capitalist USDT (Trc20)', value: 15},
    {name: 'Card RUR', value: 6},
    {name: 'ООО/ИП', value: 7},
    {name: 'Самозанятый', value: 8},
    {name: 'ЮMoney', value: 9},
    {name: 'QIWI', value: 10},
    {name: 'USDT (Trc20)', value: 12},
    {name: 'Card KZT', value: 13},
    {name: 'Взаимозачет', value: 14},
    {name: 'Взаимозачет', value: 14},
    {name: 'Card UZS', value: 16},
];
export const FilterPeriod = [
    {value: 'all', label: 'За все время'},
    {value: 'today', label: 'Сегодня'},
    {value: 'yesterday', label: 'Вчера'},
    {value: 'day7', label: 'Последние 7 дней'},
    {value: 'previousWeek', label: 'Предыдущая неделя'},
    {value: 'currentWeek', label: 'Текущая неделя'},
    {value: 'day30', label: 'Последние 30 дней'},
    {value: 'currentMonth', label: 'Текущий месяц'},
    {value: 'previousMonth', label: 'Предыдущий месяц'},
    {value: 'range', label: 'Произвольный период'},
];
export const FilterCountry = [
    {value: 'all', label: 'Все'},
    {value: 'RU', label: 'Россия'},
    {value: 'NORU', label: 'Зарубежье'},
];
export const FilterEntity = [
    {value: 'all', label: 'Все'},
    {value: 'clear', label: 'Незаполненые'},
    {value: 1, label: 'ООО Банкинет'},
    {value: 2, label: 'ООО ЭмДжиКом'},
    {value: 3, label: 'ООО Маркетинг'},
    {value: 4, label: 'ТОО MarketingCorp'},
    {value: 5, label: 'IT Solutions FZCO'},
];
export const FilterPaySystem = [
    {label: 'Webmoney WMZ', value: 1},
    {label: 'Wire transfer', value: 2},
    {label: 'Capitalist USD', value: 3},
    {label: 'Capitalist EUR', value: 4},
    {label: 'Capitalist RUR', value: 5},
    {label: 'Capitalist USDT (Trc20)', value: 15},
    {label: 'Card RUR', value: 6},
    {label: 'ООО/ИП', value: 7},
    {label: 'ЮMoney', value: 9},
    {label: 'QIWI', value: 10},
    {label: 'USDT (Trc20)', value: 12},
    {label: 'Card KZT', value: 13},
    {label: 'Взаимозачет', value: 14},
    {label: 'Card UZS', value: 16},
];

export const FilterTransactionType = [
    {label: 'Реферальные выплаты', value: true},
    {label: 'Оплата за конверсии', value: false},
    {label: 'Реф. выплаты + конверсии (ID <=1848)', value: 'both'}
];

export const FilterPayAccount = [
    {label: 'Capitalist', value: 1},
    {label: 'QIWI', value: 2},
    {label: 'Расчетный счет', value: 3},
];
export const FilterStatus = [
    {value: -1, label: <Status status={-1}/>},
    {value: 1, label: <Status status={1}/>},
    {value: 2, label: <Status status={2}/>},
    {value: 4, label: <Status status={4}/>},
    {value: 3, label: <Status status={3}/>},
    {value: 5, label: <Status status={5}/>},
    {value: 99, label: <Status status={99}/>},

];
export const FilterStatusOptions = [
    {value: -1, label: <OptionColoredLabel text={'Отклонено'} color={'red'}/>},
    {value: 1, label: <OptionColoredLabel text={'Ожидает'} color={'yellow'}/>},
    {value: 2, label: <OptionColoredLabel text={'Оплачено'} color={'green'}/>},
    {value: 4, label: <OptionColoredLabel text={'В обработке'} color={'blue'}/>},
    {value: 5, label: <OptionColoredLabel text={'В обработке (недостаточно средств)'} color={'orange'}/>},
    {value: 3, label: <OptionColoredLabel text={'Частично оплачено'} color={'greenBorder'}/>},
    {value: 99, label: <OptionColoredLabel text={'В архиве'} color={'grey'}/>},
];

export const getFilterStatusOptions = (comment, classes = null)=> {
  return [
    {value: -1, label: <OptionColoredLabel text={'Отклонено'} color={'red'} comment={comment} classes={classes}/>},
    {value: 1, label: <OptionColoredLabel text={'Ожидает'} color={'yellow'} comment={comment} classes={classes}/>},
    {value: 2, label: <OptionColoredLabel text={'Оплачено'} color={'green'} comment={comment} classes={classes}/>},
    {value: 4, label: <OptionColoredLabel text={'В обработке'} color={'blue'} comment={comment} classes={classes}/>},
    {value: 5, label: <OptionColoredLabel text={'В обработке (недостаточно средств)'} color={'orange'} comment={comment} classes={classes}/>},
    {value: 3, label: <OptionColoredLabel text={'Частично оплачено'} color={'greenBorder'} comment={comment} classes={classes}/>},
    {value: 99, label: <OptionColoredLabel text={'В архиве'} color={'grey'} comment={comment} classes={classes}/>},
  ];
}

export const ConversionsStatusSelect = [
    {value: '-1', label: 'Статус выплаты'},
    {value: 'confirmed', label: <ConversionsStatus status='confirmed'/>},
    {value: 'pending', label: <ConversionsStatus status='pending'/>},
    {value: 'declined', label: <ConversionsStatus status='declined'/>},
    {value: 'hold', label: <ConversionsStatus status='hold'/>},
];
export const FilterSort = [
    {value: "all", label: 'Все'},
    {value: "date", label: "По дате"},
    {value: "status", label: "По статусам"},

];
export const filterParams = [
    {name: 'period', value: 'day30'},
    {name: 'status', value: -2},
    {name: 'payAgent', value: -2},
    {name: 'paySystem', value: -2},
    {name: 'transactionType', value: -2},
    {name: 'currency', value: -2},
    {name: 'search', value: ''},
    {name: 'transactionId', value: ''},
]

export const knowledgeFilterParams = [
    {name: 'section', value: -2},
    {name: 'article', value: -2},
    {name: 'search', value: ''},
]

export const partnersFilterParams = [
    {name: 'manager', value: -2},
    {name: 'search', value: ''},
]

export const filterParamsAdvertiser = [
    {name: 'country', value: 'RU'},
    {name: 'search', value: ''},
    {name: 'entity', value: 'all'},
]
export const OptionsDeposit = [
    {label: 'Card', value: 6},
    {label: 'QIWI', value: 10},
    {label: 'USDT (Trc20)', value: 12},
    {label: 'Счет Capitalist', value: 'Capitalist'},
    {label: 'ЮMoney', value: 9},
    {label: 'Wire transfer', value: 2},
    {label: 'ООО/ИП', value: 7},
    {label: 'Взаимозачет', value: 14},

];
export const OfferStatus = [
    {name: 'Активен', value: 'active'},
    {name: 'Отключен', value: 'stopped'},
    {name: 'В паузе', value: 'suspended'},
];
export const OfferPrivacy = [
    {name: 'Публичный', value: 'public'},
    {name: 'Премодерация', value: 'protected'},
    {name: 'Приватный', value: 'private'},
];
export const OptionsCurrencyFromMethodPay = {
    1: [
        {label: 'RUB', value: 1}
    ],
    2:[
        {label: 'RUB', value: 1},
        {label: 'USD', value: 2},
        {label: 'EUR', value: 3},
        {label: 'AED', value: 4},
    ],
    3:[{label: 'USD', value: 2}],
    4:[{label: 'EUR', value: 3}],
    5:[{label: 'RUB', value: 1}],
    6:[{label: 'RUB', value: 1}],
    7:[
        {label: 'RUB', value: 1},
        {label: 'USD', value: 2},
        {label: 'EUR', value: 3},
        {label: 'AED', value: 4},
    ],
    9:[{label: 'RUB', value: 1}],
    10:[{label: 'RUB', value: 1}],
    12:[{label: 'USD', value: 2}],
    13:[{label: 'USD', value: 2}],
    14:[
        {label: 'RUB', value: 1},
        {label: 'USD', value: 2},
        {label: 'EUR', value: 3},
        {label: 'AED', value: 4},
    ],
    15:[{label: 'USDTt', value: 200}],
    16:[
        {label: 'RUB', value: 1},
        {label: 'USD', value: 2},
        {label: 'EUR', value: 3},
    ]
};
export const OptionsContractType = [
    {label: 'Посреднический договор', value: 'Intermediary'},
    {label: 'Договор оказания услуг', value: 'Original'},
    {label: 'Дополнительное соглашение', value: 'Additional'}
];
export const OptionsContractActionType = [
    {label: 'Действия в целях распространения рекламы', value: 'Distribution'},
    {label: 'Заключение договоров', value: 'Conclude'},
    {label: 'Коммерческое представительство', value: 'Commercial'},
    {label: 'Нет', value: 'None'}
];
export const OptionsContractSubjectType = [
    {label: 'Представительство', value: 'Representation'},
    {label: 'Иное', value: 'Other'},
    {label: 'Договор на организацию распространения рекламы', value: 'DistributionOrganization'},
    {label: 'Посредничество', value: 'Mediation'},
    {label: 'Договор на распространение рекламы', value: 'Distribution'},
];
export const OptionsInvoiceRole = [
    {label: 'Рекламное агентство', value: 'AdvertisingAgency'},
    {label: 'Рекламораспространитель', value: 'AdvertisingDistributor'},
    {label: 'Договор на организацию распространения рекламы', value: 'AdvertisingSystemOperator'},
    {label: 'Оператор рекламной системы', value: 'Advertiser'},
    {label: 'Рекламодатель', value: 'Distribution'},
];
export const OptionsAdvertiserEntityType= [
    {value: 1, label: 'ООО Банкинет'},
    {value: 2, label: 'ООО ЭмДжиКом'},
    {value: 3, label: 'ООО Маркетинг'},
    {value: 4, label: 'ТОО MarketingCorp'},
    {value: 5, label: 'IT Solutions FZCO'},
];
export const MonthsName = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь',
};
export  const CountryList =  [
    {"alpha2":"af","name":"Afghanistan"},
    {"alpha2":"ax","name":"Åland Islands"},
    {"alpha2":"al","name":"Albania"},
    {"alpha2":"dz","name":"Algeria"},
    {"alpha2":"as","name":"American Samoa"},
    {"alpha2":"ad","name":"Andorra"},
    {"alpha2":"ao","name":"Angola"},
    {"alpha2":"ai","name":"Anguilla"},
    {"alpha2":"aq","name":"Antarctica"},
    {"alpha2":"ag","name":"Antigua and Barbuda"},
    {"alpha2":"ar","name":"Argentina"},
    {"alpha2":"am","name":"Armenia"},
    {"alpha2":"aw","name":"Aruba"},
    {"alpha2":"au","name":"Australia"},
    {"alpha2":"at","name":"Austria"},
    {"alpha2":"az","name":"Azerbaijan"},
    {"alpha2":"bs","name":"Bahamas"},
    {"alpha2":"bh","name":"Bahrain"},
    {"alpha2":"bd","name":"Bangladesh"},
    {"alpha2":"bb","name":"Barbados"},
    {"alpha2":"by","name":"Belarus"},
    {"alpha2":"be","name":"Belgium"},
    {"alpha2":"bz","name":"Belize"},
    {"alpha2":"bj","name":"Benin"},
    {"alpha2":"bm","name":"Bermuda"},
    {"alpha2":"bt","name":"Bhutan"},
    {"alpha2":"bo","name":"Bolivia (Plurinational State of)"},
    {"alpha2":"bq","name":"Bonaire, Sint Eustatius and Saba"},
    {"alpha2":"ba","name":"Bosnia and Herzegovina"},
    {"alpha2":"bw","name":"Botswana"},
    {"alpha2":"bv","name":"Bouvet Island"},
    {"alpha2":"br","name":"Brazil"},
    {"alpha2":"io","name":"British Indian Ocean Territory"},
    {"alpha2":"bn","name":"Brunei Darussalam"},
    {"alpha2":"bg","name":"Bulgaria"},
    {"alpha2":"bf","name":"Burkina Faso"},
    {"alpha2":"bi","name":"Burundi"},
    {"alpha2":"cv","name":"Cabo Verde"},
    {"alpha2":"kh","name":"Cambodia"},
    {"alpha2":"cm","name":"Cameroon"},
    {"alpha2":"ca","name":"Canada"},
    {"alpha2":"ky","name":"Cayman Islands"},
    {"alpha2":"cf","name":"Central African Republic"},
    {"alpha2":"td","name":"Chad"},
    {"alpha2":"cl","name":"Chile"},
    {"alpha2":"cn","name":"China"},
    {"alpha2":"cx","name":"Christmas Island"},
    {"alpha2":"cc","name":"Cocos (Keeling) Islands"},
    {"alpha2":"co","name":"Colombia"},
    {"alpha2":"km","name":"Comoros"},
    {"alpha2":"cg","name":"Congo"},
    {"alpha2":"cd","name":"Congo, Democratic Republic of the"},
    {"alpha2":"ck","name":"Cook Islands"},
    {"alpha2":"cr","name":"Costa Rica"},
    {"alpha2":"ci","name":"Côte d'Ivoire"},
    {"alpha2":"hr","name":"Croatia"},
    {"alpha2":"cu","name":"Cuba"},
    {"alpha2":"cw","name":"Curaçao"},
    {"alpha2":"cy","name":"Cyprus"},
    {"alpha2":"cz","name":"Czechia"},
    {"alpha2":"dk","name":"Denmark"},
    {"alpha2":"dj","name":"Djibouti"},
    {"alpha2":"dm","name":"Dominica"},
    {"alpha2":"do","name":"Dominican Republic"},
    {"alpha2":"ec","name":"Ecuador"},
    {"alpha2":"eg","name":"Egypt"},
    {"alpha2":"sv","name":"El Salvador"},
    {"alpha2":"gq","name":"Equatorial Guinea"},
    {"alpha2":"er","name":"Eritrea"},
    {"alpha2":"ee","name":"Estonia"},
    {"alpha2":"sz","name":"Eswatini"},
    {"alpha2":"et","name":"Ethiopia"},
    {"alpha2":"fk","name":"Falkland Islands (Malvinas)"},
    {"alpha2":"fo","name":"Faroe Islands"},
    {"alpha2":"fj","name":"Fiji"},
    {"alpha2":"fi","name":"Finland"},
    {"alpha2":"fr","name":"France"},
    {"alpha2":"gf","name":"French Guiana"},
    {"alpha2":"pf","name":"French Polynesia"},
    {"alpha2":"tf","name":"French Southern Territories"},
    {"alpha2":"ga","name":"Gabon"},
    {"alpha2":"gm","name":"Gambia"},
    {"alpha2":"ge","name":"Georgia"},
    {"alpha2":"de","name":"Germany"},
    {"alpha2":"gh","name":"Ghana"},
    {"alpha2":"gi","name":"Gibraltar"},
    {"alpha2":"gr","name":"Greece"},
    {"alpha2":"gl","name":"Greenland"},
    {"alpha2":"gd","name":"Grenada"},
    {"alpha2":"gp","name":"Guadeloupe"},
    {"alpha2":"gu","name":"Guam"},
    {"alpha2":"gt","name":"Guatemala"},
    {"alpha2":"gg","name":"Guernsey"},
    {"alpha2":"gn","name":"Guinea"},
    {"alpha2":"gw","name":"Guinea-Bissau"},
    {"alpha2":"gy","name":"Guyana"},
    {"alpha2":"ht","name":"Haiti"},
    {"alpha2":"hm","name":"Heard Island and McDonald Islands"},
    {"alpha2":"va","name":"Holy See"},
    {"alpha2":"hn","name":"Honduras"},
    {"alpha2":"hk","name":"Hong Kong"},
    {"alpha2":"hu","name":"Hungary"},
    {"alpha2":"is","name":"Iceland"},
    {"alpha2":"in","name":"India"},
    {"alpha2":"id","name":"Indonesia"},
    {"alpha2":"ir","name":"Iran (Islamic Republic of)"},
    {"alpha2":"iq","name":"Iraq"},
    {"alpha2":"ie","name":"Ireland"},
    {"alpha2":"im","name":"Isle of Man"},
    {"alpha2":"il","name":"Israel"},
    {"alpha2":"it","name":"Italy"},
    {"alpha2":"jm","name":"Jamaica"},
    {"alpha2":"jp","name":"Japan"},
    {"alpha2":"je","name":"Jersey"},
    {"alpha2":"jo","name":"Jordan"},
    {"alpha2":"kz","name":"Kazakhstan"},
    {"alpha2":"ke","name":"Kenya"},
    {"alpha2":"ki","name":"Kiribati"},
    {"alpha2":"kp","name":"Korea (Democratic People's Republic of)"},
    {"alpha2":"kr","name":"Korea, Republic of"},
    {"alpha2":"kw","name":"Kuwait"},
    {"alpha2":"kg","name":"Kyrgyzstan"},
    {"alpha2":"la","name":"Lao People's Democratic Republic"},
    {"alpha2":"lv","name":"Latvia"},
    {"alpha2":"lb","name":"Lebanon"},
    {"alpha2":"ls","name":"Lesotho"},
    {"alpha2":"lr","name":"Liberia"},
    {"alpha2":"ly","name":"Libya"},
    {"alpha2":"li","name":"Liechtenstein"},
    {"alpha2":"lt","name":"Lithuania"},
    {"alpha2":"lu","name":"Luxembourg"},
    {"alpha2":"mo","name":"Macao"},
    {"alpha2":"mg","name":"Madagascar"},
    {"alpha2":"mw","name":"Malawi"},
    {"alpha2":"my","name":"Malaysia"},
    {"alpha2":"mv","name":"Maldives"},
    {"alpha2":"ml","name":"Mali"},
    {"alpha2":"mt","name":"Malta"},
    {"alpha2":"mh","name":"Marshall Islands"},
    {"alpha2":"mq","name":"Martinique"},
    {"alpha2":"mr","name":"Mauritania"},
    {"alpha2":"mu","name":"Mauritius"},
    {"alpha2":"yt","name":"Mayotte"},
    {"alpha2":"mx","name":"Mexico"},
    {"alpha2":"fm","name":"Micronesia (Federated States of)"},
    {"alpha2":"md","name":"Moldova, Republic of"},
    {"alpha2":"mc","name":"Monaco"},
    {"alpha2":"mn","name":"Mongolia"},
    {"alpha2":"me","name":"Montenegro"},
    {"alpha2":"ms","name":"Montserrat"},
    {"alpha2":"ma","name":"Morocco"},
    {"alpha2":"mz","name":"Mozambique"},
    {"alpha2":"mm","name":"Myanmar"},
    {"alpha2":"na","name":"Namibia"},
    {"alpha2":"nr","name":"Nauru"},
    {"alpha2":"np","name":"Nepal"},
    {"alpha2":"nl","name":"Netherlands"},
    {"alpha2":"nc","name":"New Caledonia"},
    {"alpha2":"nz","name":"New Zealand"},
    {"alpha2":"ni","name":"Nicaragua"},
    {"alpha2":"ne","name":"Niger"},
    {"alpha2":"ng","name":"Nigeria"},
    {"alpha2":"nu","name":"Niue"},
    {"alpha2":"nf","name":"Norfolk Island"},
    {"alpha2":"mk","name":"North Macedonia"},
    {"alpha2":"mp","name":"Northern Mariana Islands"},
    {"alpha2":"no","name":"Norway"},
    {"alpha2":"om","name":"Oman"},
    {"alpha2":"pk","name":"Pakistan"},
    {"alpha2":"pw","name":"Palau"},
    {"alpha2":"ps","name":"Palestine, State of"},
    {"alpha2":"pa","name":"Panama"},
    {"alpha2":"pg","name":"Papua New Guinea"},
    {"alpha2":"py","name":"Paraguay"},
    {"alpha2":"pe","name":"Peru"},
    {"alpha2":"ph","name":"Philippines"},
    {"alpha2":"pn","name":"Pitcairn"},
    {"alpha2":"pl","name":"Poland"},
    {"alpha2":"pt","name":"Portugal"},
    {"alpha2":"pr","name":"Puerto Rico"},
    {"alpha2":"qa","name":"Qatar"},
    {"alpha2":"re","name":"Réunion"},
    {"alpha2":"ro","name":"Romania"},
    {"alpha2":"ru","name":"Russian Federation"},
    {"alpha2":"rw","name":"Rwanda"},
    {"alpha2":"bl","name":"Saint Barthélemy"},
    {"alpha2":"sh","name":"Saint Helena, Ascension and Tristan da Cunha"},
    {"alpha2":"kn","name":"Saint Kitts and Nevis"},
    {"alpha2":"lc","name":"Saint Lucia"},
    {"alpha2":"mf","name":"Saint Martin (French part)"},
    {"alpha2":"pm","name":"Saint Pierre and Miquelon"},
    {"alpha2":"vc","name":"Saint Vincent and the Grenadines"},
    {"alpha2":"ws","name":"Samoa"},
    {"alpha2":"sm","name":"San Marino"},
    {"alpha2":"st","name":"Sao Tome and Principe"},
    {"alpha2":"sa","name":"Saudi Arabia"},
    {"alpha2":"sn","name":"Senegal"},
    {"alpha2":"rs","name":"Serbia"},
    {"alpha2":"sc","name":"Seychelles"},
    {"alpha2":"sl","name":"Sierra Leone"},
    {"alpha2":"sg","name":"Singapore"},
    {"alpha2":"sx","name":"Sint Maarten (Dutch part)"},
    {"alpha2":"sk","name":"Slovakia"},
    {"alpha2":"si","name":"Slovenia"},
    {"alpha2":"sb","name":"Solomon Islands"},
    {"alpha2":"so","name":"Somalia"},
    {"alpha2":"za","name":"South Africa"},
    {"alpha2":"gs","name":"South Georgia and the South Sandwich Islands"},
    {"alpha2":"ss","name":"South Sudan"},
    {"alpha2":"es","name":"Spain"},
    {"alpha2":"lk","name":"Sri Lanka"},
    {"alpha2":"sd","name":"Sudan"},
    {"alpha2":"sr","name":"Suriname"},
    {"alpha2":"sj","name":"Svalbard and Jan Mayen"},
    {"alpha2":"se","name":"Sweden"},
    {"alpha2":"ch","name":"Switzerland"},
    {"alpha2":"sy","name":"Syrian Arab Republic"},
    {"alpha2":"tw","name":"Taiwan, Province of China"},
    {"alpha2":"tj","name":"Tajikistan"},
    {"alpha2":"tz","name":"Tanzania, United Republic of"},
    {"alpha2":"th","name":"Thailand"},
    {"alpha2":"tl","name":"Timor-Leste"},
    {"alpha2":"tg","name":"Togo"},
    {"alpha2":"tk","name":"Tokelau"},
    {"alpha2":"to","name":"Tonga"},
    {"alpha2":"tt","name":"Trinidad and Tobago"},
    {"alpha2":"tn","name":"Tunisia"},
    {"alpha2":"tr","name":"Türkiye"},
    {"alpha2":"tm","name":"Turkmenistan"},
    {"alpha2":"tc","name":"Turks and Caicos Islands"},
    {"alpha2":"tv","name":"Tuvalu"},
    {"alpha2":"ug","name":"Uganda"},
    {"alpha2":"ua","name":"Ukraine"},
    {"alpha2":"ae","name":"United Arab Emirates"},
    {"alpha2":"gb","name":"United Kingdom of Great Britain and Northern Ireland"},
    {"alpha2":"us","name":"United States of America"},
    {"alpha2":"um","name":"United States Minor Outlying Islands"},
    {"alpha2":"uy","name":"Uruguay"},
    {"alpha2":"uz","name":"Uzbekistan"},
    {"alpha2":"vu","name":"Vanuatu"},
    {"alpha2":"ve","name":"Venezuela (Bolivarian Republic of)"},
    {"alpha2":"vn","name":"Viet Nam"},
    {"alpha2":"vg","name":"Virgin Islands (British)"},
    {"alpha2":"vi","name":"Virgin Islands (U.S.)"},
    {"alpha2":"wf","name":"Wallis and Futuna"},
    {"alpha2":"eh","name":"Western Sahara"},
    {"alpha2":"ye","name":"Yemen"},
    {"alpha2":"zm","name":"Zambia"},
    {"alpha2":"zw","name":"Zimbabwe"}
];

export const companyTypes = [
    'LegalPerson', // Юридическое лицо
    'PhysicalPerson', // Физическое лицо
    'IndividualEntrepreneur' // Индивидуальный предприниматель
]

export const companyTypesOptions = [
    {label: 'Юридическое лицо (Резидент РФ)', value: companyTypes[0]},
    {label: 'Физическое лицо', value: companyTypes[1]},
    {label: 'Индивидуальный предприниматель', value: companyTypes[2]},
]

export const contractTypes = [
    'Original', // Договор оказания услуг
    'Intermediary', // Посреднический договор
    'Additional', // Дополнительное соглашение
    'SelfPromotion' // Самореклама
]

export const contractTypesOptions = [
    {label: 'Договор оказания услуг', value: contractTypes[0]},
    {label: 'Посреднический договор', value: contractTypes[1]},
    {label: 'Дополнительное соглашение', value: contractTypes[2]},
    {label: 'Самореклама', value: contractTypes[3]},
]

const subjectTypes = [
    'Other', // Другое
    'Representation', // Представительство
    'DistributionOrganization', // Договор на организацию распространения рекламы
    'Mediation', // Посредничество
    'Distribution' // Договор на распространение рекламы
]

export const subjectTypesOptions = [
    {label: 'Другое', value: subjectTypes[0]},
    {label: 'Представительство', value: subjectTypes[1]},
    {label: 'Договор на организацию распространения рекламы', value: subjectTypes[2]},
    {label: 'Посредничество', value: subjectTypes[3]},
    {label: 'Договор на распространение рекламы', value: subjectTypes[4]},
]

const typeAdvertisingCompany = [
    'Other', // Другое
    'PayForViews', // Оплата за показы. CPM (Cost per Mille)
    'PayForClicks', // Оплата за клики. CPC (Cost per Click)
    'PayForActions', // Оплата за действия. CPA (Cost per Action)
]

export const typeAdvertisingCompanyOptions = [
    {label: 'Иное', value: typeAdvertisingCompany[0]},
    {label: 'CPM (Cost per Mille)', value: typeAdvertisingCompany[1]},
    {label: 'CPC (Cost per Click)', value: typeAdvertisingCompany[2]},
    {label: '(CPA, Cost per Action)', value: typeAdvertisingCompany[3]},
]

const formAdvertisingCompany = [
    'Other', // Другое
    'Banner', // Баннер
    'TextBlock', // Текстовый блок
    'TextGraphicBlock', // Текстово-графический блок
    'Video', // Видео блок
    'AudioRecord', // Аудиозапись
    'LiveAudio', // Прямой эфир аудио
    'LiveVideo', // Прямой эфир видео
]

export const formAdvertisingCompanyOptions = [
    {label: 'Иное', value: formAdvertisingCompany[0]},
    {label: 'Баннер', value: formAdvertisingCompany[1]},
    {label: 'Текстовый блок (Пост/ТБ)', value: formAdvertisingCompany[2]},
    {label: 'Текстово-графический блок (Статья/ТГБ)', value: formAdvertisingCompany[3]},
    {label: 'Видеоролик', value: formAdvertisingCompany[4]},
    {label: 'Аудиозапись', value: formAdvertisingCompany[5]},
    {label: 'Аудиотрансляция в прямом эфире', value: formAdvertisingCompany[6]},
    {label: 'Видеотрансляция в прямом эфире', value: formAdvertisingCompany[7]},
]

const actionTypes = [
    'None', // Нет
    'Other', // Другое
    'Distribution', // Действия в целях распространения рекламы
    'Conclude', // Заключение договоров
    'Commercial' // Коммерческие действия
]

export const actionTypesOptions = [
    {label: 'Нет', value: actionTypes[0]},
    {label: 'Другое', value: actionTypes[1]},
    {label: 'Действия в целях распространения рекламы', value: actionTypes[2]},
    {label: 'Заключение договоров', value: actionTypes[3]},
    {label: 'Коммерческие действия', value: actionTypes[4]},
]

const customerOrExecutorRoleInAct = [
    "AdvertisingAgency", // Рекламное агентство
    "AdvertisingDistributor", // Рекламный распространитель
    "AdvertisingSystemOperator", // Оператор рекламной системы
    "Advertiser" // Рекламодатель
]

export const customerOrExecutorRoleOptions = [
    {label: 'Рекламное агентство', value: customerOrExecutorRoleInAct[0]},
    {label: 'Рекламный распространитель', value: customerOrExecutorRoleInAct[1]},
    {label: 'Оператор рекламной системы', value: customerOrExecutorRoleInAct[2]},
    {label: 'Рекламодатель', value: customerOrExecutorRoleInAct[3]},
]

export const EntityOptions = [
    {value: 'ООО «Маркетинг»', label: 'ООО «Маркетинг»'},
    {value: 'ООО «Эмджиком»', label: 'ООО «Эмджиком»'},
    {value: 'ООО «Медиа технологии»', label: 'ООО «Медиа технологии»'},
    {value: 'ООО «Диджитал путь»', label: 'ООО «Диджитал путь»'},
];

export const PlatformTypeOptions = [
    {value: 'Site', label: 'Сайт'},
    {value: 'Application', label: 'Приложение'},
    {value: 'InformationSystem', label: 'Информационная система'},
]

export const PlatformCategoryOptions = [
    {value: 'All', label: 'Все'},
    {value: 'Dating', label: 'Dating'},
    {value: 'Gambling', label: 'Gambling'},
    {value: 'Betting', label: 'Betting'},
    {value: 'E-commerce', label: 'E-commerce'},
    {value: 'Edtech', label: 'Edtech'},
]