import $api from "../core/api";
import {createNotification} from "./app";
import {setAdvertisersPageItems, setAdvertiserItem, setCurrentAdvertiser} from "../reducers/advertisersReduser";
import {v4 as uuidv4} from "uuid";
import {processError} from "../core/helpers";
import { closeModal } from "../reducers/appReducer";
import { delayByLoader } from "../utils/deleyByLoader";

export const fetchAdvertisers = (params,setLoading) => {
    setLoading?.(true)
    return async dispatch => {
        try {
            const response = await $api.post(`/api/advertisers`, params);
            dispatch(setAdvertisersPageItems(response.data));
            return {success: true};
        } catch (e) {
            processError(dispatch, e);
        }finally{
          !!setLoading && delayByLoader(false, setLoading, 200 );
        }
    }
}

export const fetchAdvertiserById = ( id, setLoading) => {
    return async dispatch => {
        try {
            const response = await $api.get('/api/advertisers/' + id, {withCredentials: true});
            if (response.data && response.data.success) {
                if (response.data.advertiser) {
                    dispatch(setCurrentAdvertiser(response.data.advertiser));
                    return true;
                }
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
        }finally{
            delayByLoader(false, setLoading, 600 );
        }
    }
}

export const update = ( params )=>{
    return async dispatch => {
        try {
            const response = await $api.post(`/api/advertisers/update`, params);
            if (response.data) {
                dispatch(setCurrentAdvertiser(response.data));
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Данные успешно ообновлены!'
                }));
            }
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const deleteInvoiceDocument = (params,setLoading,onSuccess)=>{
    return async dispatch => {
        try {
            const response = await $api.post(`/api/advertisers/deleteinvoicedocument`, params);
            dispatch(setCurrentAdvertiser(response.data));
            onSuccess()
            return {success: true};

        } catch (e) {
            processError(dispatch, e);
            setLoading(false)
        }
    }
}
export const deleteInvoice = (params,setLoading)=>{
    return async dispatch => {
        try {

            const response = await $api.post(`/api/advertisers/deleteinvoice`, params);
            dispatch(setCurrentAdvertiser(response.data));
            dispatch(closeModal('createInvoice'));
            return {success: true};

        } catch (e) {
            processError(dispatch, e);
        }finally{
          setLoading(false)
        }
    }
}

export const createInvoice = (params,setLoading)=>{
    return async dispatch => {
        try {
            params.amount = params.amount.toString().replace(' ','');
            let formData = new FormData();
            const fileName = uuidv4();
            const format = params.document.name ? params.document.name.split('.') : '';
            if(params.document.type) {
                formData.append('document', params.document, `${fileName}-document.${format[format.length - 1]}`);
            }
            formData.append('advertiserId', params.advertiserId);
            formData.append('number', params.number);
            formData.append('invoiceDate', params.invoiceDate);
            formData.append('contractId', params.contractId);
            formData.append('invoiceStartDate', params.invoiceStartDate);
            formData.append('invoiceEndDate', params.invoiceEndDate);
            formData.append('amount', parseInt(params.amount));
            formData.append('isVat', params.isVat);
            formData.append('type', params.type);
            formData.append('customerRole', params.customerRole);
            formData.append('executorRole', params.executorRole);
            formData.append('isReadyForErir', params.isReadyForErir);
            const response = await $api.post(`/api/advertisers/createinvoice`, formData);
            dispatch(setCurrentAdvertiser(response.data));
            dispatch(closeModal('createInvoice'));
            return response.data;

        } catch (e) {
            console.log(e)
            processError(dispatch, e);
        }
        finally{
          setLoading(false)
        }
    }
}

export const updateInvoice = (params,setLoading)=>{
    return async dispatch => {
        try {
            params.amount = params.amount.toString().replace(' ','');
            let formData = new FormData();
            const fileName = uuidv4();
            const format = params.document.name ? params.document.name.split('.') : '';
            if(!params.document.path && params.document.type) {
                formData.append('document', params.document, `${fileName}-document.${format[format.length-1]}`);
                formData.append('id', params.id);
                formData.append('advertiserId', params.advertiserId);
                formData.append('number', params.number);
                formData.append('contractId', params.contractId);
                formData.append('invoiceDate', params.invoiceDate);
                formData.append('invoiceStartDate', params.invoiceStartDate);
                formData.append('invoiceEndDate', params.invoiceEndDate);
                formData.append('amount', parseInt(params.amount));
                formData.append('isVat', params.isVat);
                formData.append('type', params.type);
                formData.append('customerRole', params.customerRole);
                formData.append('executorRole', params.executorRole);
                formData.append('isReadyForErir', params.isReadyForErir);
                const response = await $api.post(`/api/advertisers/updateinvoice`, formData);
                dispatch(setAdvertiserItem(response.data));
            }else{
                const response = await $api.post(`/api/advertisers/updateinvoice`, params);
                dispatch(setAdvertiserItem(response.data));
            }
                dispatch(closeModal('createInvoice'));

            return {success: true};

        } catch (e) {
            console.log(e)
            processError(dispatch, e);
        }finally{
          setLoading(false)
        }
    }
}

export const getInvoiceDocument = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/advertisers/getinvoicedocument`, params);
            if (response.data) {
                return response.data;
            }
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const uploadLegalDocs = ( advId, docs ) => {
    return async dispatch => {
        try {
            if ( !docs.charter && !docs.taxStatement &&!docs.legalEntityCard ) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Нет файлов для загрузки'
                }));
                return;
            }

            const formData = new FormData();
            formData.append('advId', advId);

            if ( docs.charter ) {
                formData.append('charter', docs.charter, docs.charter.name);
            }
            if ( docs.taxStatement ) {
                formData.append('taxStatement', docs.taxStatement, docs.taxStatement.name);
            }
            if ( docs.legalEntityCard ) {
                formData.append('legalEntityCard', docs.legalEntityCard, docs.legalEntityCard.name);
            }

            const response = await $api.post(`/api/advertisers/upload-docs`, formData, {withCredentials: true} );

           if ( response.data ) {
               if ( response.data.success ) {
                   dispatch(setCurrentAdvertiser(response.data.advertiser));
                   dispatch(createNotification({
                       type: 'primary',
                       children: 'Файлы загружены'
                   }));
               } else {
                   if ( response.data.errors ) {
                       return {errors: response.data.errors};
                   }
               }
               return true;
           }
           return false;

        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const deleteLegalDoc = ( advId, doc ) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/advertisers/delete-doc`, {advId, doc}, {withCredentials: true});
            if (response.data && response.data.success) {
                dispatch(setCurrentAdvertiser(response.data.advertiser));
                dispatch(createNotification({
                    type: 'primary',
                    children: 'Файл удален'
                }));
                return true;
            }
            dispatch(createNotification({
                type: 'error',
                children: 'Что-то пошло не так, попробуйте перезагрузить страницу и повторить операцию'
            }));
            return false;

        } catch (e) {
            processError(dispatch, e);
        }
    }
}
