import $api from "../core/api";
import { processError } from "../core/helpers";
import { fetchOffersSuccess } from "../reducers/ordOffersReducer";

const prefixUrl = "/api/offers";

export const fetchOrdOffers = (params) => {
	return async dispatch => {
		try {
			const response = await $api.get(`${prefixUrl}/get-offers-by-advertiser`, { params });
			if (response && response.data) {
				dispatch(fetchOffersSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};