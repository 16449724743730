import styles from "./AddPlatformForm.module.css";
import { BackButton } from "../../../ui/BackButton/BackButton";
import { Button } from "../../../ui/updated/Button/Button";
import { Controller, useForm } from "react-hook-form";
import { SelectPartner } from "./SelectParnter/SelectPartner";
import { fieldNames, validationSchema } from "./validationSchema";
import { Select } from "../../../ui/updated/Select/Select";
import {
	EntityOptions,
	PlatformCategoryOptions,
	PlatformTypeOptions
} from "../../../../core/config";
import { Checkbox } from "../../../ui/updated/Checkbox/Checkbox";
import { Input } from "../../../ui/updated/Input/Input";
import { useDispatch } from "react-redux";
import {
	createPlatform,
	deletePlatform,
	updatePlatform
} from "../../../../actions/platform";
import { yupResolver } from "@hookform/resolvers/yup";
import { SendingStatus } from "../../../ui/sendingStatus/SendingStatus";
import {
	ConfirmModal
} from "../../itemOrdAdvertiser/ConfirmModal/ConfirmModal";
import { addModal, removeModal } from "../../../../reducers/appReducer";

const deletePlatformModalId = "deletePlatform";
const confirmSendToOrdModalId = "confirmSendToOrd";

export const PlatformForm = ({ platform, isEdit, onBack }) => {
	const dispatch = useDispatch();
	const { handleSubmit, control, register, formState } = useForm({
		defaultValues: {
			[fieldNames.partner]: platform?.partner ? {
				value: platform.partner._id,
				label: platform.partner.email
			} : null,
			[fieldNames.company]: platform?.company ? EntityOptions.find(option => option.value === platform.company).value : EntityOptions[0].value,
			[fieldNames.type]: platform?.type ? PlatformTypeOptions.find(option => option.value === platform.type).value : PlatformTypeOptions[0].value,
			[fieldNames.isOwned]: platform?.isOwned || false,
			[fieldNames.name]: platform?.name || "",
			[fieldNames.url]: platform?.url || "",
			[fieldNames.category]: platform?.category ? PlatformCategoryOptions.find(option => option.value === platform.category).value : PlatformCategoryOptions[0].value
		},
		resolver: yupResolver(validationSchema)
	});
	const { errors } = formState;
	
	const onSubmit = async (data) => {
		const submitData = {
			...data,
			[fieldNames.partner]: data[fieldNames.partner].value,
			sendToOrd: true
		};
		
		if (isEdit) {
			await dispatch(updatePlatform(platform._id, submitData));
			dispatch(removeModal(confirmSendToOrdModalId));
			return;
		}
		
		await dispatch(createPlatform(submitData));
		dispatch(removeModal(confirmSendToOrdModalId));
	}
	
	const onSubmitWithoutOrd = async (data) => {
		const submitData = {
			...data,
			[fieldNames.partner]: data[fieldNames.partner].value
		};
		
		if (isEdit) {
			await dispatch(updatePlatform(platform._id, submitData));
			return;
		}
		
		await dispatch(createPlatform(submitData));
	};
	
	const handleOpenSendToOrdModal = (e) => {
		e.preventDefault();
		const modalParams = {
			id: confirmSendToOrdModalId,
			size: "middle",
			children: <ConfirmModal
				title="Данные будут отправлены в ЕРИР. <br/> Подтвердите отправку."
				onConfirm={handleSubmit(onSubmit)}
				onCancel={handleCancel(confirmSendToOrdModalId)}/>
		};
		dispatch(addModal(modalParams));
	};
	
	
	const handleCancel = (modalId) => () => {
		dispatch(removeModal(modalId));
	};
	
	const handleDeleteCreative = async () => {
		dispatch(removeModal(deletePlatformModalId));
		await dispatch(deletePlatform(platform._id));
		onBack();
	};
	
	
	const handleOpenConfirmModal = () => {
		const modalParams = {
			id: deletePlatformModalId,
			size: "middle",
			children: <ConfirmModal
				title="Площадка будет удалена.<br/>Подтвердите."
				onConfirm={handleDeleteCreative}
				onCancel={handleCancel(deletePlatformModalId)}/>
		};
		dispatch(addModal(modalParams));
	};
	
	return (
		<div>
			<BackButton onClick={onBack}/>
			<div className={styles.wrapper}>
				<div className={styles.formHeader}>
					<div className={styles.titleWrapper}>
						<div className={styles.title}>
							<h2>
								{isEdit ? "Редактирование площадки" : "Добавление площадки"}
							</h2>
						</div>
						<div className={styles.subTitle}>
							{!isEdit && "Заполните информацию о площадке"}
							{isEdit && <SendingStatus isSent={!!platform?.ordId}/>}
						</div>
					</div>
					{isEdit && (
						<Button type="danger"
						        className={styles.deleteBtn}
						        disabled={!!platform?.ordId}
						        onClick={handleOpenConfirmModal}>
							Удалить
						</Button>
					)}
				</div>
				<form className={styles.form}>
					<div className={styles.field}>
						<SelectPartner control={control}
						               fieldName={fieldNames.partner}
						               errorsMessage={errors[fieldNames.partner]?.message}
						               label="Вебмастер X-Partners*"/>
					</div>
					<div className={styles.field}>
						<Controller control={control} render={({ field }) => (
							<Select
								{...field}
								label="Юридическое лицо"
								options={EntityOptions}
								isSearchable={false}
								value={EntityOptions.find(option => option.value === field.value)}
								onChange={option => field.onChange(option.value)}
								isRequired
							/>
						)} name={fieldNames.company}/>
					</div>
					<div className={styles.field}>
						<Controller control={control} render={({ field }) => (
							<Select
								{...field}
								label="Тип площадки"
								options={PlatformTypeOptions}
								isSearchable={false}
								value={PlatformTypeOptions.find(option => option.value === field.value)}
								onChange={option => field.onChange(option.value)}
								isRequired
							/>
						)} name={fieldNames.type}/>
					</div>
					<div className={styles.field}>
						<Controller render={
							({ field }) => (
								<Checkbox
									{...field}
									label="Принадлежит моей организации"
									checked={field.value}
								/>
							)
						} control={control} name={fieldNames.isOwned}/>
					</div>
					<div className={styles.field}>
						<Input
							label="Название площадки"
							{...register(fieldNames.name)}
							isRequired
							errorMessage={errors[fieldNames.name]?.message}
						/>
					</div>
					<div className={styles.field}>
						<Input
							label="Ссылка на площадку"
							{...register(fieldNames.url)}
							isRequired
							errorMessage={errors[fieldNames.url]?.message}
						/>
					</div>
					<div className={styles.field}>
						<Controller control={control} render={({ field }) => (
							<Select
								{...field}
								label="Категория"
								options={PlatformCategoryOptions}
								isSearchable={false}
								value={PlatformCategoryOptions.find(option => option.value === field.value)}
								onChange={option => field.onChange(option.value)}
								isRequired
							/>
						)} name={fieldNames.category}/>
					</div>
					<div className={styles.buttons}>
						<Button type="secondary" onClick={handleSubmit(onSubmitWithoutOrd)} disabled={!!platform?.ordId}>
							Сохранить без отправки
						</Button>
						<Button onClick={handleOpenSendToOrdModal} disabled={!!platform?.ordId}>
							Сохранить и отправить в ОРД
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};