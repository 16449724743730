import * as yup from "yup";

export const fieldNames = {
	number: "number",
	date: "date",
	startDate: "startDate",
	endDate: "endDate",
	period: "period",
	amount: "amount",
	isVat: "isVat",
	customerRole: "customerRole",
	executorRole: "executorRole",
	contractId: "contractId",
	description: "description"
};

export const validationSchema = yup.object({
	[fieldNames.number]: yup.string().required("Обязательное поле"),
	[fieldNames.date]: yup.date().required("Обязательное поле"),
	[fieldNames.amount]: yup.number().typeError("Должно быть числом").required("Обязательное поле"),
	[fieldNames.description]: yup.string().required("Обязательное поле"),
	[fieldNames.period]: yup.array().of(yup.string().required("Обязательное поле")).required("Обязательное поле")
});