import { forwardRef } from "react";
import styles from "./DatePickerRange.module.css";
import DefaultDatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";

export const DatePickerRange = forwardRef(({
	label,
	classNameLabel = "",
	classNameInput = "",
	isRequired,
	startAdornment,
	startDate,
	endDate,
	endAdornment,
	error,
	...props
}, ref) => {
	
	return (
		<div className={styles.wrapper}>
			{label && (
				<label className={`${styles.label} ${classNameLabel}`}>
					{label}
					{isRequired && "*"}
				</label>)}
			<div className={styles.inputWrapper}>
				{startAdornment &&
					<div className={styles.adornment}>{startAdornment}</div>}
				<DefaultDatePicker
					startDate={startDate}
					endDate={endDate}
					selectsRange={true}
					ref={ref}
					locale={ru}
					className={`${styles.input} ${classNameInput}`}
					{...props}
				/>
				{endAdornment && <div className={styles.adornment}>{endAdornment}</div>}
			</div>
			{error && <div className={styles.errorMessage}>
				{error[0] && !error[1] && 'Начальная дата обязательна'}
				{error[1] && !error[0] && 'Конечная дата обязательна'}
				{error[0] && error[1] && 'Обязательное поле'}
			</div>}
		</div>
	);
});
