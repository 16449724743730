import $api from '../core/api';
import {processError} from "../core/helpers";


export const fetchManagers = (setManagersData) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/managers`);

            setManagersData(response.data);
        } catch (e) {
            processError(dispatch, e);
        }
    }
};