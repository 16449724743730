import { NavLink } from "react-router-dom";
import {
	ReactComponent as ArrowIcon
} from "../../../assets/img/arrow-icon.svg";
import styles from "./BackButton.module.css";

export const BackButton = ({ to, onClick }) => {
	
	const renderButton = () => {
		return (
			<button className={styles.btn} onClick={onClick}>
				<ArrowIcon className={styles.icon}/>
				Назад
			</button>
		);
	};
	
	const renderLink = () => {
		return (
			<NavLink to={to} className={styles.btn}>
				<ArrowIcon className={styles.icon}/>
				Назад
			</NavLink>
		);
	};
	
	return to ? renderLink() : renderButton();
};