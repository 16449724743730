import { AddButton } from "../../../../ui/addButton/AddButton";
import styles from "./ContractsTable.module.css";
import { Table } from "../../../../ui/Table/Table";
import { ContractTableRow } from "../ContractTableRow/ContractTableRow";
import React, { useEffect, useState } from "react";
import { ContractForm } from "../ContractForm/ContractForm";
import { BackButton } from "../../../../ui/BackButton/BackButton";
import { fetchContracts } from "../../../../../actions/contracts";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../ui/pagination/Pagination";

const LIMIT_PER_PAGE = 10;

export const ContractsTable = ({ advertiser }) => {
	const dispatch = useDispatch();
	const { title, id } = advertiser;
	const [isAddContract, setIsAddContract] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [editingContract, setEditingContract] = useState(null);
	const {
		loading,
		items,
		pagination
	} = useSelector(state => state.contracts);
	
	useEffect(() => {
		if (id) {
			dispatch(fetchContracts({
				advId: id,
				page: 1,
				limit: LIMIT_PER_PAGE
			}));
		}
	}, [id, advertiser?.id, dispatch]);
	
	const handlePageChange = (page) => {
		if (page === pagination.page) {
			return;
		}
		
		dispatch(fetchContracts({
			advId: id,
			page,
			limit: LIMIT_PER_PAGE
		}));
	};
	
	const handleAddContract = () => {
		setIsAddContract(true);
	};
	
	const handleEditContract = (contractId) => () => {
		setIsEdit(true);
		setEditingContract(contractId);
	};
	
	const handleCancel = () => {
		setIsAddContract(false);
		setIsEdit(false);
	};
	
	if (isAddContract) {
		return <ContractForm advertiser={advertiser} onCancel={handleCancel}/>;
	}
	
	if (isEdit) {
		return (
			<ContractForm
				advertiser={advertiser}
				contract={items.find(item => item._id === editingContract)}
				onCancel={handleCancel}
				isEdit={true}
			/>
		);
	}
	
	return (
		<div>
			<BackButton to="/ord-advertisers-empty"/>
			<h2 className={styles.title}>{title}</h2>
			<AddButton
				className={styles.btn}
				label="Добавить договор"
				onClick={handleAddContract}
			/>
			<Table
				loading={loading}
				thead={
					<tr>
						<th>ID договора в ОРД</th>
						<th>№ и дата</th>
						<th>Статус ЕРИР</th>
						<th>Заказчик</th>
						<th>Исполнитель</th>
						<th>Изначальный договор</th>
						<th>Описание</th>
						<th></th>
					</tr>
				}
				tbody={items.map(item => (
					<ContractTableRow item={item} key={item._id}
					                  onEdit={handleEditContract(item._id)}/>
				))}
			/>
			<Pagination paginationData={pagination} setParamData={handlePageChange}/>
		</div>
	);
};