const CREATE_ACT_SUCCESS = "CREATE_ACT_SUCCESS";
const FETCH_ACTS_SUCCESS = "FETCH_ACTS_SUCCESS";
const DELETE_ACT_SUCCESS = "DELETE_ACT_SUCCESS";
const UPDATE_ACT_SUCCESS = "UPDATE_ACT_SUCCESS";

const defaultState = {
	items: [],
	current: null,
	errors: false,
	pagination: "",
	loading: true
};

export default function actReducer(state = defaultState, action) {
	switch (action.type) {
		case FETCH_ACTS_SUCCESS:
			const { docs, ...pagination } = action.payload;
			
			return {
				...state,
				items: docs,
				pagination,
				loading: false
			};
		case CREATE_ACT_SUCCESS:
			return {
				...state,
				items: state.items ? [action.payload, ...state.items] : [action.payload],
				loading: false
			};
		case UPDATE_ACT_SUCCESS:
			return {
				...state,
				items: state.items.map(item => item._id === action.payload._id ? action.payload : item),
				loading: false
			};
		case DELETE_ACT_SUCCESS:
			return {
				...state,
				items: state.items.filter(item => item._id !== action.payload),
				loading: false
			};
		default:
			return state;
	}
}

export const createActSuccess = (act) => ({
	type: CREATE_ACT_SUCCESS,
	payload: act
});

export const fetchActsSuccess = (act) => ({
	type: FETCH_ACTS_SUCCESS,
	payload: act
});

export const deleteActSuccess = (actId) => ({
	type: DELETE_ACT_SUCCESS,
	payload: actId
});

export const updateActSuccess = (act) => ({
	type: UPDATE_ACT_SUCCESS,
	payload: act
});