import { v4 as uuid } from "uuid";
import { forwardRef } from "react";
import styles from "./Checkbox.module.css";

export const Checkbox = forwardRef(({ label, ...props }, ref) => {
	const id = uuid();
	
	return (
		<div className={styles.wrapper}>
			<input
				ref={ref}
				id={id}
				type="checkbox"
				className={styles.checkbox}
				{...props}
			/>
			<label htmlFor={id} className={styles.label}>
				{label}
			</label>
		</div>
	);
});