import {
	ReactComponent as EditIcon
} from "../../../../../../assets/img/edit-icon-2.svg";
import styles from "./CreativeTableRow.module.css";
import { truncateString } from "../../../../../../utils/truncateString";
import { format } from "date-fns";

export const CreativeTableRow = ({ item, onEdit }) => {
	const archive = item?.mediaDataPath ? item.mediaDataPath.match(/[^\\/]+\.[^\\/]+$/)[0] : null;
	
	return (
		<tr>
			<td>{truncateString(item._id, 6, 6) ?? "-"}</td>
			<td>status</td>
			<td>
				{item.initialContractId}
				<br/>
				<span
					className={styles.secondary}>{item?.createdAt && format(new Date(item.createdAt), "dd.MM.yyyy")}</span>
			</td>
			<td>{item.erid ?? "-"}</td>
			<td>
				{archive ? truncateString(archive, 0, 8) : "-"}
			</td>
			<td>{item.description}</td>
			<td>
				<button className={styles.editBtn} onClick={onEdit}>
					<EditIcon className={styles.editIcon}/>
				</button>
			</td>
		</tr>
	);
};