import { forwardRef } from "react";
import styles from "./Input.module.css";

const Input = forwardRef(({
	label = "",
	placeholder = "",
	startAdornment = null,
	endAdornment = null,
	isRequired,
	errorMessage = "",
	...inputProps
}, ref) => {
	
	return (
		<div className={styles.wrapper}>
			{label && (
				<label className={styles.label}>
					{label}
					{isRequired && "*"}
				</label>
			)}
			<div className={`${styles.inputWrapper} ${errorMessage && styles.error}`}>
				{startAdornment &&
					<div className={styles.adornment}>{startAdornment}</div>}
				<input
					{...inputProps}
					ref={ref}
					placeholder={placeholder}
					className={styles.input}/>
				{endAdornment && <div className={styles.adornment}>{endAdornment}</div>}
			</div>
			{errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
		</div>
	);
});

Input.displayName = "Input";

export { Input };
