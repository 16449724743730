import AppContent from "../../appContent/AppContent";
import styles from "./ItemOrdAdvertiser.module.css";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Sidebar } from "../../ui/Sidebar/Sidebar";
import { General } from "./general/General";
import { Contracts } from "./contracts/Contracts";
import { Offers } from "./offers/Offers";
import { Acts } from "./acts/Acts";
import { fetchAdvertiserById } from "../../../actions/advertisers";
import { useDispatch } from "react-redux";
import { Loader } from "../../ui/updated/Loader/Loader";

const tabsIds = {
	GENERAL: "general",
	CONTRACTS: "contracts",
	OFFERS: "offers",
	ACTS: "acts"
};

const tabs = [
	{ id: tabsIds.GENERAL, title: "Основное" },
	{ id: tabsIds.CONTRACTS, title: "Договоры" },
	{ id: tabsIds.OFFERS, title: "Офферы" },
	{ id: tabsIds.ACTS, title: "Акты" }
];


const ItemOrdAdvertiser = () => {
	const { advertiserId } = useParams();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	
	const getQueryParam = (param) => {
		const searchParams = new URLSearchParams(location.search);
		return searchParams.get(param);
	};
	
	const [activeTab, setActiveTab] = useState(() => {
		const initialTab = getQueryParam("tab");
		return initialTab && Object.values(tabsIds).includes(initialTab)
			? initialTab
			: tabsIds.GENERAL;
	});
	
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set("tab", activeTab);
		history.push({ search: searchParams.toString() });
	}, [activeTab, history, location.search]);
	
	const tabsContent = {
		[tabsIds.GENERAL]: <General/>,
		[tabsIds.CONTRACTS]: <Contracts/>,
		[tabsIds.OFFERS]: <Offers advertiserId={advertiserId}/>,
		[tabsIds.ACTS]: <Acts/>
	};
	
	useEffect(() => {
		if (advertiserId) {
			dispatch(fetchAdvertiserById(advertiserId, setLoading));
		}
	}, [advertiserId, dispatch]);
	
	const handleTabChange = (tabId) => {
		setActiveTab(tabId);
	};
	
	return (
		<AppContent>
			<div className="container wrapper">
				<div className={styles.innerWrapper}>
					<div className={styles.sidebar}>
						<Sidebar
							tabs={tabs}
							onChange={handleTabChange}
							activeTab={activeTab}
						/>
					</div>
					{loading ? (
						<div className={styles.loaderWrapper}>
							<Loader/>
						</div>
					) : (
						<div className={styles.tabContent}>
							{tabsContent[activeTab]}
						</div>
					)}
				</div>
			</div>
		</AppContent>
	);
};

export default ItemOrdAdvertiser;