import React, { useState } from 'react';
import styles from './AdvertisersFilters.module.css';
import Button from "../../../ui/button_v2/ButtonV2";
import Search from "../../../ui/seacrh/Search";
import BillingFilter from "../../billing/filters/billingFilter/BillingFilter";
import { setFilterParam } from "../../../../core/helpers";
import { useDispatch } from 'react-redux';
import { fetchAdvertisers } from '../../../../actions/advertisers';
import { filterParamsAdvertiser } from '../../../../core/config';
import './AdvertisersFilters.css'

const AdvertisersFilters = ({ params, setParams, setLoading }) => {
    const dispatch = useDispatch();
    let filters = filterParamsAdvertiser;
    let paramsDefault = {
        filters: filters,
        page: 1,
    };

    const [fillData, setFillData] = useState({ country: 'RU', search: '', entity: 'all' });

    const requestFilterData = (pm) => {
        setParams({
            filter: [
                setFilterParam(pm, 'country', fillData.country),
                setFilterParam(pm, 'search', fillData.search),
                setFilterParam(pm, 'entity', fillData.entity),
            ],
            page: 1
        });
    };


    const onSearchValueUpdate = (value) => {
        if (!value) {
            const updateData = paramsDefault.filters.map((item) => {
                return { name: item.name, value: item.name === 'search' ? '' : item.value }
            });
            setFillData({ ...fillData, search: '' });
            dispatch(fetchAdvertisers({ filters: updateData, page: paramsDefault.page }));
            return;
        }
        setFillData({ ...fillData, search: value });
    };

    return (
        <div className={styles.container}>
            <div className={styles.input_block}>
                <Search fontSize={12} maxWidth='unset' placeholder='e-mail, логин' default={fillData} value={fillData.search} setValue={onSearchValueUpdate}
                    label='Поиск' />
            </div>
            <div className={styles.input_block}>
                <BillingFilter fontSize='fs-14' type='country' label='Регион' placeholder="Все" fillData={fillData}
                    onChange={(val) => setFillData({ ...fillData, country: val })} />
            </div>
            <div className={styles.input_block}>
                <BillingFilter fontSize='fs-14' type='entity' label='Юридическое лицо' placeholder="Все" fillData={fillData}
                    onChange={(val) => setFillData({ ...fillData, entity: val })} />
            </div>
            <div className={`${styles.BtnBlock}`}>
                <Button onClick={() => requestFilterData(params)} type='primary'>Найти</Button>
            </div>
        </div>

    );
};

export default AdvertisersFilters;
