import * as yup from "yup";

export const fieldNames = {
	partner: "partner",
	company: "company",
	type: "type",
	isOwned: "isOwned",
	name: "name",
	url: "url",
	category: "category",
}

export const validationSchema = yup.object({
	[fieldNames.partner]: yup.object().typeError('Обязательное поле').required('Обязательное поле'),
	[fieldNames.name]: yup.string().required('Обязательное поле'),
	[fieldNames.url]: yup.string().required('Обязательное поле'),
})