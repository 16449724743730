import { forwardRef } from "react";
import styles from "./AsyncSelect.module.css";
import "./AsyncSelect.css";
import { components } from "react-select";
import DefaultAsyncSelect from "react-select/async";
import {
	ReactComponent as SearchIcon
} from "../../../../assets/img/search-icon.svg";

const CustomDropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
			<SearchIcon/>
		</components.DropdownIndicator>
	);
};

const AsyncSelect = forwardRef(({
	label = "",
	isRequired,
	...selectProps
}, ref) => {
	return (
		<div className={styles.wrapper}>
			<label className={styles.label}>
				{label}
				{isRequired && "*"}
			</label>
			<DefaultAsyncSelect
				{...selectProps}
				isClearable
				components={{ DropdownIndicator: CustomDropdownIndicator }}
				ref={ref}
				classNamePrefix="select"
			/>
		</div>
	);
});

AsyncSelect.displayName = "Select";

export { AsyncSelect };