export const truncateString = (
	str,
	start,
	end,
	separator = "..."
) => {
	if (start + end >= str.length) return str;
	
	return str.slice(0, start) + separator + str.slice(str.length - end);
};
