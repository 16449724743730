import { Table } from "../../../../../ui/Table/Table";
import { BackButton } from "../../../../../ui/BackButton/BackButton";
import styles from "./CreativeTable.module.css";
import { CompanyLabel } from "../../../../../ui/companyLabel/CompanyLabel";
import { AddButton } from "../../../../../ui/addButton/AddButton";
import React, { useState } from "react";
import { CreativeForm } from "../CreativeForm/CreativeForm";
import { useSelector } from "react-redux";
import { CreativeTableRow } from "../CreativeTableRow/CreativeTableRow";
import Pagination from "../../../../../ui/pagination/Pagination";

export const CreativeTable = ({ offer, onBack, onChangePage }) => {
	const [isAddCreative, setIsAddCreative] = useState(false);
	const { items, pagination, loading } = useSelector(state => state.creative);
	const [isEdit, setIsEdit] = useState(false);
	const [editingCreative, setEditingCreative] = useState(null);
	
	const handleAddCreative = () => {
		setIsAddCreative(true);
	};
	
	const handleCancel = () => {
		setIsAddCreative(false);
		setIsEdit(false);
	};
	
	const handleEditCreative = (creativeId) => () => {
		setIsEdit(true);
		setEditingCreative(creativeId);
	};
	
	if (isAddCreative) {
		return <CreativeForm offer={offer} onCancel={handleCancel}/>;
	}
	
	if (isEdit) {
		return (
			<CreativeForm
				offer={offer}
				creative={items.find(item => item._id === editingCreative)}
				onCancel={handleCancel}
				isEdit={true}
			/>
		);
	}
	
	return (
		<>
			<BackButton onClick={onBack}/>
			<div className={styles.wrapper}>
				<div className={styles.title}>
					<h2>Креативы</h2>
					<CompanyLabel>
						{offer.title}
					</CompanyLabel>
				</div>
				<AddButton
					className={styles.btn}
					label="Добавить креатив"
					onClick={handleAddCreative}
				/>
				<Table
					thead={
						<tr>
							<th>Внутренний ID</th>
							<th>Статус Ерир</th>
							<th>№ и Дата</th>
							<th>Токен</th>
							<th>Архив</th>
							<th>Описание</th>
							<th></th>
						</tr>
					}
					loading={loading}
					tbody={items && items.map(item => <CreativeTableRow key={item._id}
					                                                    item={item}
					                                                    onEdit={handleEditCreative(item._id)}/>)}
				/>
				<Pagination paginationData={pagination} setParamData={onChangePage}/>
			</div>
		</>
	);
};