import styles from "./Table.module.css";
import { Loader } from "../updated/Loader/Loader";

export const Table = ({ thead, tbody, loading = false }) => {
	const renderEmptyRow = () => {
		return (
			<tr>
				<td className={styles.empty} colSpan={thead.props.children.length}>
					Нет данных
				</td>
			</tr>
		);
	};
	
	const renderLoader = () => {
		return (
			<tr>
				<td colSpan={thead.props.children.length}>
					<Loader/>
				</td>
			</tr>
		);
	};
	
	return (
		<div className={styles.tableWrapper}>
			<table className={styles.table}>
				<thead className={styles.thead}>
				{thead}
				</thead>
				<tbody className={styles.tbody}>
				{loading ? renderLoader() : tbody}
				{tbody?.length === 0 && !loading && renderEmptyRow()}
				</tbody>
			</table>
		</div>
	);
};