import { Controller } from "react-hook-form";
import { useState } from "react";
import $api from "../../../../../core/api";
import { AsyncSelect } from "../../../../ui/updated/AsyncSelect/AsyncSelect";
import styles from "../../../../ui/ContractsSelect/ContractsSelect.module.css";

export const SelectPartner = ({
	control,
	label = "",
	errorsMessage = "",
	fieldName
}) => {
	const [partners, setPartners] = useState([]);
	
	const loadOptions = async (inputValue, callback) => {
		if (inputValue.length < 2) {
			setPartners([]);
			callback([]);
			return;
		}
		
		try {
			const response = await $api.get("/api/partners/search-partners", {
				params: { searchTerm: inputValue }
			});
			
			if (response.data) {
				const options = response.data.map(partner => ({
					value: partner._id,
					label: `(${partner.affiseId}) ${partner.email}`
				}));
				setPartners(options);
				callback(options);
				return;
			}
			
			setPartners([]);
			callback([]);
			
		} catch (err) {
			setPartners([]);
			callback([]);
		}
	};
	
	
	return (
		<Controller control={control} render={({ field }) => {
			return (
				<>
					<AsyncSelect
						label={label}
						placeholder="Id, email вебмастера"
						loadOptions={loadOptions}
						defaultOptions={false}
						options={partners.map(partner => ({
							value: partner._id,
							label: `(${partner.affiseId}) ${partner.email}`
						}))}
						{...field} />
					{errorsMessage &&
						<div className={styles.errorMessage}>{errorsMessage}</div>}
				</>
			);
		}} name={fieldName}/>
	);
};