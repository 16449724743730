import styles from "./OfferTableRow.module.css";
import { OfferStatus } from "../../../../ui/offerStatus/OfferStatus";
import { Button } from "../../../../ui/updated/Button/Button";

export const OfferTableRow = ({ item, onClick }) => {
	
	return (
		<tr>
			<td>{item.affiseId}</td>
			<td className={styles.name}>{item.title}</td>
			<td>
				<OfferStatus status={item.status}/>
			</td>
			<td>{item.creatives?.length ?? 0}</td>
			<td>
				<Button type="secondary" className={styles.btn} onClick={onClick}>
					Креативы
				</Button>
			</td>
		</tr>
	);
};