import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdOffers } from "../../../../actions/ord-offers";
import { OffersTable } from "./OffersTable/OffersTable";

const LIMIT_PER_PAGE = 10;

export const Offers = () => {
	const dispatch = useDispatch();
	const { pagination } = useSelector(state => state.ordOffers);
	const advertiser = useSelector(state => state.advertisers.current);
	
	useEffect(() => {
		if (advertiser?.id) {
			dispatch(fetchOrdOffers({
				advId: advertiser.id,
				page: 1,
				limit: LIMIT_PER_PAGE
			}));
		}
	}, [advertiser, dispatch]);
	
	const handlePageChange = (page) => {
		if (page === pagination.page) {
			return;
		}
		
		dispatch(fetchOrdOffers({
			advId: advertiser.id,
			page,
			limit: LIMIT_PER_PAGE
		}));
	};
	
	
	// TODO add search
	return (
		<div>
			<OffersTable onChangePage={handlePageChange}/>
		</div>
	);
};