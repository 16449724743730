/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styles from "./BillingPDFConfirmModal.module.css";
import Button from "../../ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../reducers/appReducer";
import './BillingPDFConfirmModal.css';
import { API_URL } from "../../../core/config";
import { removeInvoicePDF } from "../../../actions/billing";
import { setBillingInvoicePDFPath } from "../../../reducers/billingReducer";

const BillingPDFConfirmModal = ({ data }) => {
    const dispatch = useDispatch();
    const billing = useSelector(state => state.billing.current);

    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden";
        return () => {
            document.querySelector("body").style.overflow = "unset";
            dispatch(setBillingInvoicePDFPath(''));
        };
    }, [dispatch]);

    const onCancelHandler = () => {
        if (!data.isNullInvoice && data.billingInvoicePDFPath) {
            dispatch(removeInvoicePDF(data.billingInvoicePDFPath));
        }
        dispatch(closeModal("BillingPDFConfirmModal"));
    };

    const onCloseHandler = () => {
        dispatch(closeModal("BillingPDFConfirmModal"));
    };

    const onSendInvoice = () => {
        if (!data.isNullInvoice && data.send) {
            data.send(data.billingInvoicePDFPath);
        }
    };

    const onConfirmClose = () => {
        if (data.confirmClose) {
            data.confirmClose();
        }
    };

    return (
        <div className={styles.ModalModule} id="ModalModule">
            {!data.isNullInvoice ? (
                <>
                    <iframe
                        id="pdfViewer"
                        title="PDF Document Viewer"
                        width="100%"
                        height="600px"
                        src={`${API_URL}/${data.billingInvoicePDFPath}`}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                    {(billing?.invoice && billing?.status === 2) ? (
                        <div className={styles.btns}>
                            <Button className={styles.createArticle} onClick={onCloseHandler}>
                                Закрыть
                            </Button>
                        </div>
                    ) : (
                        <div className={styles.btns}>
                            <Button className={styles.viewBtn} onClick={onCancelHandler}>
                                Отменить
                            </Button>
                            <Button className={styles.createArticle} onClick={onSendInvoice}>
                                Оплатить
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.ModalModule} id="ModalModule">
                    <p>{data.message || "Счет нулевой. Подтвердите закрытие счета."}</p>
                    <div className={styles.btns}>
                        <Button className={styles.viewBtn} onClick={onCancelHandler}>
                            Отменить
                        </Button>
                        <Button className={styles.createArticle}  onClick={onConfirmClose}>
                            Подтвердить
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BillingPDFConfirmModal;
