import { forwardRef } from "react";
import styles from "./Textarea.module.css";

export const Textarea = forwardRef(({
	label = "",
	className = "",
	isRequired = false,
	errorMessage = "",
	...props
}, ref) => {
	return (
		<div className={styles.wrapper}>
			{label && (
				<label className={styles.label}>
					{label}
					{isRequired && "*"}
				</label>
			)}
			<textarea
				ref={ref}
				className={`${styles.textarea} ${errorMessage && styles.error} ${className}`}
				{...props}
			/>
			{errorMessage &&
				<div className={styles.errorMessage}>{errorMessage}</div>}
		</div>
	);
});