import styles from "./OrdAdvertisersTableRow.module.css";
import { Button } from "../../../../ui/updated/Button/Button";
import {
	ReactComponent as PlusIcon
} from "../../../../../assets/img/plus-icon.svg";
import {
	ReactComponent as EditIcon
} from "../../../../../assets/img/edit-icon-2.svg";
import { NavLink } from "react-router-dom";

export const OrdAdvertisersTableRow = ({ item }) => {
	return (
		<tr key={item.id}>
			<td>{item.title}</td>
			<td>{`${item.manager.firstName} ${item.manager.lastName}`}</td>
			<td>{item.ordEntity || ""}</td>
			<td>{item?.offers ? item.offers.length : ""}</td>
			<td>
				<div className={styles.controls}>
					<NavLink to={`/item-ord-advertiser/${item.id}`}
					         className={styles.editBtn}>
						<EditIcon className={styles.editIcon}/>
					</NavLink>
					<NavLink to={`/item-ord-advertiser/${item.id}?tab=acts&action=add`}>
						<Button type="secondary" className={styles.addBtn}>
							<PlusIcon className={styles.addIcon}/>
							Добавить акт
						</Button>
					</NavLink>
				</div>
			</td>
		</tr>
	);
};