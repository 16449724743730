const CREATE_PLATFORM_SUCCESS = "CREATE_PLATFORM_SUCCESS";
const FETCH_PLATFORMS_SUCCESS = "FETCH_PLATFORMS_SUCCESS";
const UPDATE_PLATFORM_SUCCESS = "UPDATE_PLATFORM_SUCCESS";
const DELETE_PLATFORM_SUCCESS = "DELETE_PLATFORM_SUCCESS";


const defaultState = {
	items: [],
	errors: [],
	loading: true,
	pagination: ''
};

export default function platformReducer(state = defaultState, action) {
	switch (action.type) {
		case FETCH_PLATFORMS_SUCCESS:
			const { docs, ...pagination } = action.payload;
			return {
				...state,
				items: docs,
				pagination,
				loading: false
			};
		case CREATE_PLATFORM_SUCCESS:
			return {
				...state,
				items: [action.payload, ...state.items],
				loading: false
			};
		case UPDATE_PLATFORM_SUCCESS:
			return {
				...state,
				items: state.items.map(item => item._id === action.payload._id ? action.payload : item),
				loading: false
			};
		case DELETE_PLATFORM_SUCCESS:
			return {
				...state,
				items: state.items.filter(item => item._id !== action.payload),
				loading: false
			};
		default:
			return state;
	}
}

export const fetchPlatformsSuccess = (platforms) => ({
	type: FETCH_PLATFORMS_SUCCESS,
	payload: platforms
});

export const createPlatformSuccess = (platform) => ({
	type: CREATE_PLATFORM_SUCCESS,
	payload: platform
});

export const updatePlatformSuccess = (platform) => ({
	type: UPDATE_PLATFORM_SUCCESS,
	payload: platform
});

export const deletePlatformSuccess = (platformId) => ({
	type: DELETE_PLATFORM_SUCCESS,
	payload: platformId
});