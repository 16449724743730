import styles from "./ActForm.module.css";
import { Button } from "../../../../ui/updated/Button/Button";
import {
	ReactComponent as ArrowIcon
} from "../../../../../assets/img/arrow-icon.svg";
import { CompanyLabel } from "../../../../ui/companyLabel/CompanyLabel";
import { Controller, useForm } from "react-hook-form";
import { customerOrExecutorRoleOptions } from "../../../../../core/config";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import {
	ReactComponent as CalendarIcon
} from "../../../../../assets/img/calendar-icon.svg";
import { useEffect } from "react";
import { SendingStatus } from "../../../../ui/sendingStatus/SendingStatus";
import {
	ContractsSelect
} from "../../../../ui/ContractsSelect/ContractsSelect";
import { Input } from "../../../../ui/updated/Input/Input";
import { Select } from "../../../../ui/updated/Select/Select";
import { DatePicker } from "../../../../ui/updated/DatePicker/DatePicker";
import {
	DatePickerRange
} from "../../../../ui/updated/DatePickerRange/DatePickerRange";
import { Checkbox } from "../../../../ui/updated/Checkbox/Checkbox";
import { Textarea } from "../../../../ui/updated/Textarea/Textarea";
import { createAct, deleteAct, updateAct } from "../../../../../actions/act";
import { addModal, removeModal } from "../../../../../reducers/appReducer";
import { RepeatModal } from "../../RepeatModal/RepeatModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validationSchema } from "./validationSchema";

const deleteModalId = "deleteContract";
const confirmSendToOrdModalId = "confirmSendToOrd";
const errorSendToOrdModalId = "errorSendToOrd";

export const ActForm = ({
		advertiser,
		act,
		isEdit = false,
		onCancel
	}) => {
		const dispatch = useDispatch();
		const { errors } = useSelector(state => state.contracts);
		const { id, title } = advertiser;
		const defaultStartDate = act?.startDate ? new Date(act.startDate) : null;
		const defaultEndDate = act?.endDate ? new Date(act.endDate) : null;
		const { register, control, handleSubmit, formState } = useForm({
			defaultValues: {
				[fieldNames.contractId]: act?.contractId || "",
				[fieldNames.number]: act?.number || "",
				[fieldNames.customerRole]: act?.customerRole ? customerOrExecutorRoleOptions.find(option => option.value === act.customerRole).value : customerOrExecutorRoleOptions[0].value,
				[fieldNames.executorRole]: act?.executorRole ? customerOrExecutorRoleOptions.find(option => option.value === act.executorRole).value : customerOrExecutorRoleOptions[0].value,
				[fieldNames.date]: act?.date ? new Date(act.date) : new Date(),
				[fieldNames.period]: [defaultStartDate, defaultEndDate],
				[fieldNames.amount]: act?.amount || "",
				[fieldNames.isVat]: act?.isVat || false,
				[fieldNames.description]: act?.description || ""
			},
			resolver: yupResolver(validationSchema)
		});
		const { isSubmitted, errors: validationErrors } = formState;
		
		const handleRepeat = () => {
			dispatch(removeModal(errorSendToOrdModalId));
			handleSubmit(onSubmit)();
		};
		
		
		useEffect(() => {
			if (isSubmitted && !errors.length) {
				dispatch(removeModal(confirmSendToOrdModalId));
				
				return;
			}
			
			if (isSubmitted && errors.length) {
				const modalParams = {
					id: errorSendToOrdModalId,
					size: "middle",
					children: <RepeatModal
						title="В данных есть ошибка. <br/> Данные не отправлены."
						onRepeat={handleRepeat}/>
				};
				dispatch(addModal(modalParams));
			}
			// eslint-disable-next-line
		}, [errors, isSubmitted, dispatch]);
		
		const onSubmitWithoutOrd = async (data) => {
			const { period, contractId, ...restData } = data;
			const submitData = {
				...restData,
				contractId: contractId.value,
				startDate: period[0],
				endDate: period[1],
				advId: id
			};
			if (isEdit) {
				await dispatch(updateAct(act._id, submitData));
				return;
			}
			
			await dispatch(createAct(submitData));
		};
		
		
		const onSubmit = async (data) => {
			const { period, contractId, ...restData } = data;
			if (isEdit) {
				const submitData = {
					...restData,
					contractId,
					startDate: period[0],
					endDate: period[1],
					sendToOrd: true
				};
				await dispatch(updateAct(act._id, submitData));
				dispatch(removeModal(confirmSendToOrdModalId));
				return;
			}
			const submitData = {
				...restData,
				contractId: contractId.value,
				startDate: period[0],
				endDate: period[1],
				sendToOrd: true,
				advId: id
			};
			await dispatch(createAct(submitData));
			dispatch(removeModal(confirmSendToOrdModalId));
		};
		
		const handleDeleteAct = async () => {
			await dispatch(deleteAct(act._id));
			dispatch(removeModal(deleteModalId));
			onCancel();
		};
		
		const handleOpenSendToOrdModal = (e) => {
			e.preventDefault();
			const modalParams = {
				id: confirmSendToOrdModalId,
				size: "middle",
				children: <ConfirmModal
					title="Данные будут отправлены в ЕРИР. <br/> Подтвердите отправку."
					onConfirm={handleSubmit(onSubmit)}
					onCancel={handleCancel(confirmSendToOrdModalId)}/>
			};
			dispatch(addModal(modalParams));
		};
		
		const handleCancel = (modalId) => () => {
			dispatch(removeModal(modalId));
		};
		
		const handleOpenConfirmDeleteModal = () => {
			const modalParams = {
				id: deleteModalId,
				size: "middle",
				children: <ConfirmModal
					title="Акт будет удален.<br/>Подтвердите."
					onConfirm={handleDeleteAct}
					onCancel={handleCancel(deleteModalId)}/>
			};
			dispatch(addModal(modalParams));
		};
		
		return (
			<div>
				<Button type="secondary" onClick={onCancel}>
					<ArrowIcon className={styles.arrowIcon}/>
					Назад
				</Button>
				<div className={styles.wrapper}>
					<div className={styles.formHeader}>
						<div className={styles.titleWrapper}>
							<div className={styles.title}>
								<h2>
									{isEdit ? "Редактирование акта" : "Добавление акта"}
								</h2>
								<CompanyLabel>
									{title}
								</CompanyLabel>
							</div>
							<div className={styles.subTitle}>
								{!isEdit && "Заполните информацию об акте"}
								{isEdit && <SendingStatus isSent={!!act.ordId}/>}
							</div>
						</div>
						{isEdit && (
							<Button
								type="danger"
								className={styles.deleteBtn}
								onClick={handleOpenConfirmDeleteModal}
								disabled={!!act?.ordId}
							>
								Удалить
							</Button>
						)}
					</div>
					<form className={styles.form}>
						<div className={styles.field}>
							<ContractsSelect
								control={control}
								fieldName={fieldNames.contractId}
								label="Договор для прикрепления"
							/>
						</div>
						<div className={styles.field}>
							<Input
								label="Номер акта"
								{...register(fieldNames.number)}
								isRequired
								errorMessage={validationErrors[fieldNames.number]?.message}
							/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<Select
									{...field}
									label="Роль заказчика в акте"
									options={customerOrExecutorRoleOptions}
									value={customerOrExecutorRoleOptions.find(option => option.value === field.value)}
									onChange={(value) => field.onChange(value.value)}
									isSearchable={false}
									isRequired
								/>
							)} name={fieldNames.customerRole}/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<Select
									{...field}
									label="Роль исполнителя в акте"
									options={customerOrExecutorRoleOptions}
									value={customerOrExecutorRoleOptions.find(option => option.value === field.value)}
									onChange={(value) => field.onChange(value.value)}
									isSearchable={false}
									isRequired
								/>
							)} name={fieldNames.executorRole}/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<DatePicker
									{...field}
									label="Дата договора"
									dateFormat="dd.MM.yyyy"
									endAdornment={<CalendarIcon className={styles.calendarIcon}/>}
									isRequired
									errorMessage={validationErrors[fieldNames.date]?.message}
								/>
							)} name={fieldNames.date}/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<DatePickerRange
									{...field}
									startDate={field.value ? field.value[0] : null}
									endDate={field.value ? field.value[1] : null}
									onChange={(update) => {
										field.onChange(update);
									}}
									label="Период оказания услуг"
									dateFormat="dd.MM.yyyy"
									endAdornment={<CalendarIcon className={styles.calendarIcon}/>}
									isRequired
									error={validationErrors[fieldNames.period]}
								/>
							)} name={fieldNames.period}/>
						</div>
						<div className={styles.field}>
							<Input
								type="number"
								label="Сумма акта, ₽"
								{...register(fieldNames.amount)}
								isRequired
								errorMessage={validationErrors[fieldNames.amount]?.message}
							/>
						</div>
						<div className={styles.field}>
							<Controller render={
								({ field }) => (
									<Checkbox
										{...field}
										label="С НДС"
										checked={field.value}
									/>
								)
							} control={control} name={fieldNames.isVat}/>
						</div>
						<div className={styles.field}>
							<Textarea
								label="Описание"
								placeholder="Оказание услуг по размещению..."
								{...register(fieldNames.description)}
								isRequired
								errorMessage={validationErrors[fieldNames.description]?.message}
							/>
						</div>
						<div className={styles.buttons}>
							<Button type="secondary" onClick={handleSubmit(onSubmitWithoutOrd)}
							        disabled={!!act?.ordId}>
								Сохранить без отправки
							</Button>
							<Button onClick={handleOpenSendToOrdModal} disabled={!!act?.ordId}>
								Сохранить и отправить в ОРД
							</Button>
						</div>
					</form>
				</div>
			</div>
		);
	}
;