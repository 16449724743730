import styles from "./Sidebar.module.css";

export const Sidebar = ({ tabs, onChange, activeTab }) => {
	
	const handleTabChange = (tabId) => () => {
		onChange(tabId);
	};
	
	return (
		<ul className={styles.sidebar}>
			{tabs.map(tab => (
				<li
					key={tab.id}
					className={`${styles.tab} ${activeTab === tab.id ? styles.active : ""}`}
					onClick={handleTabChange(tab.id)}
				>
					{tab.title}
				</li>
			))}
		</ul>
	);
};