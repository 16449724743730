const FETCH_CREATIVE_SUCCESS = "FETCH_CREATIVE_SUCCESS";
const CREATE_CREATIVE_SUCCESS = "CREATE_CREATIVE_SUCCESS";
const UPDATE_CREATIVE_SUCCESS = "UPDATE_CREATIVE_SUCCESS";
const DELETE_CREATIVE_SUCCESS = "DELETE_CREATIVE_SUCCESS";

const defaultState = {
	items: null,
	current: null,
	errors: false,
	pagination: "",
	loading: true
};

export default function creativeReducer(state = defaultState, action) {
	switch (action.type) {
		case FETCH_CREATIVE_SUCCESS:
			const { docs, ...pagination } = action.payload;
			
			return {
				...state,
				items: docs,
				pagination,
				loading: false
			};
		case CREATE_CREATIVE_SUCCESS:
			return {
				...state,
				items: state.items ? [action.payload, ...state.items] : [action.payload],
				loading: false
			};
		case UPDATE_CREATIVE_SUCCESS:
			return {
				...state,
				items: state.items.map(item => item._id === action.payload._id ? action.payload : item),
				loading: false
			};
		case DELETE_CREATIVE_SUCCESS:
			return {
				...state,
				items: state.items.filter(item => item._id !== action.payload),
				loading: false
			};
		default:
			return state;
	}
}

export const createCreativeSuccess = (creative) => ({
	type: CREATE_CREATIVE_SUCCESS,
	payload: creative
});

export const fetchCreativeSuccess = (creative) => ({
	type: FETCH_CREATIVE_SUCCESS,
	payload: creative
});

export const updateCreativeSuccess = (creative) => ({
	type: UPDATE_CREATIVE_SUCCESS,
	payload: creative
});

export const deleteCreativeSuccess = (creativeId) => ({
	type: DELETE_CREATIVE_SUCCESS,
	payload: creativeId
});
