import styles from "./RepeatModal.module.css";
import { Button } from "../../../ui/updated/Button/Button";

export const RepeatModal = ({ title, onRepeat }) => {
	return (
		<div className={styles.wrapper}>
			<p dangerouslySetInnerHTML={{ __html: title }}/>
			<Button type="secondary" className={styles.button}
			        onClick={onRepeat}>Повторить</Button>
		</div>
	);
};