import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getFilterStatusOptions } from "../../../../../core/config";
import {
  getCurrencySymbol,
  getCompanyName,
  getRequisitesPartner,
} from "../../../../../core/helpers";
import BillingCreditStatus from "../../billingCreditStatus/BillingCreditStatus";
import styles from "./BillingContentLi.module.css";
import DetailsIcon from '../../../../../assets/img/details-icon.png'
import DetailsArrowIcon from '../../../../../assets/img/details-arrow-icon.png'

const BillingContentLi = ({ billing, index, setDropDown, isActive, page }) => {
    const billingStatusObj = getFilterStatusOptions(billing.billingComment).find(
        (status) => status.value === billing.status
    );
    const currencySymbol = getCurrencySymbol(billing.currency)
        ? getCurrencySymbol(billing.currency)
        : "";
    const convertCurrencySymbol = getCurrencySymbol(billing.convertCurrency)
        ? getCurrencySymbol(billing.convertCurrency)
        : "";
    const companyName = getCompanyName(billing.payAcc)
        ? getCompanyName(billing.payAcc)
        : "";
    const currencySymbolTax = billing.convertCurrency
        ? getCurrencySymbol(billing.convertCurrency)
        : getCurrencySymbol(billing.currency)
            ? getCurrencySymbol(billing.currency)
            : "";
    const modals = useSelector((state) => state.app.modals);
    const [active, setActive] = useState(false);
    const billingLastHistory =
        billing.history.length !== 0 && billing.history[0].error
            ? billing.history[0].message
            : "";
    const requisites = getRequisitesPartner(
        billing.partner,
        billing.paySystem,
        billing.payAcc
    );
    let type = "";
    
    //console.log('Поле isConversionAndRef:', billing.isConversionAndRef);

    if (
        billing.billingType === "credit" ||
        billing.billingType === "adjustment"
    ) {
        type = <BillingCreditStatus billing={billing} size="big" />;
    } else if (billing.credit) {
        type = <span className={styles.Alert}>Списание долга</span>;
    } else if (billing.isConversionAndRef) {
        type = "Оплата за реферальные и конверсии";
    }  else {
        type = billing.referral ? "Реферальные выплаты" : "Оплата за конверсии";
    }

    useEffect(() => {
        if (active && modals.length === 0) {
            setActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modals]);

    return (
        <li className={`${styles.Item} row`}>
            <div className={`${styles.Col} ${styles.Num}`}>
                {billing.affiseId.toString() === "-99" ? "" : billing.affiseId}
            </div>
            <div className={`${styles.Col} ${styles.Partner}`}>
                <div className={styles.Email}>{billing.partner.email} </div>
                <div className={styles.Login}>
                    {billing.partner.login} ID: {billing.partner.affiseId}
                </div>
            </div>
            <div className={`${styles.Col} ${styles.Manager}`}>
                <div className={styles.Email}>{billing.manager.email} </div>
                <div className={styles.Login}>
                    {billing.manager.firstName} {billing.manager.lastName}
                </div>
            </div>
            <div className={`${styles.Col} ${styles.Type}`}>{type}</div>
            <div className={` ${styles.Status} `}>
                {billingStatusObj?.label}
                <div className={styles.Alert}>{billingLastHistory}</div>
            </div>
            <div className={`${styles.Col} ${styles.PaySystem} `}>
                <span>{billing.paySystemName}</span>
            </div>
            <div className={`${styles.Col} ${styles.Recipient} `}>
                {companyName ? <span>{companyName}</span>:
                <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Col} ${styles.Sum}`}>
                {billing.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ")}{" "}
                {currencySymbol}
            </div>
            <div className={`${styles.Col} ${styles.ConvertSum}`}>
                {billing.convertAmount ? billing.convertAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ") : 
                <span className={styles.Dash}>-</span>}{" "}
                {convertCurrencySymbol}
            </div>


            <div className={`${styles.Col} ${styles.Tax} `}>
                {billing.tax
                    ? billing.tax
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ") +
                    " " +
                    currencySymbolTax
                    : billing.status === 2
                        ? 0 + " " + currencySymbolTax
                        : <span className={styles.Dash}>-</span>}
            </div>
            <div className={`${styles.Col} ${styles.Date} `}>
                {format(new Date(billing.postedDate), "dd.MM.yyyy HH:mm:ss")}
            </div>
            <div className={`${styles.BtnBlock} ${styles.Col} `}>
                <img src={DetailsArrowIcon} className={isActive ? styles.detailsArrowIconRotate : {}} alt=""
                     onClick={() => setDropDown(isActive === true ? false : index)}/>
                <NavLink className={styles.detailsLink} to={`/billing/${billing.id}`}>
                    <img src={DetailsIcon} alt=""/>
                </NavLink>
            </div>
            <div
                className={
                    isActive === true
                        ? `${styles.HiddenContainer} ${styles.Col} ${styles.Active}`
                        : `${styles.HiddenContainer} ${styles.Col}`
                }
            >
                <ul>
                    <li className={styles.Requisites}>
                        <div className={styles.HiddenTitle}>Реквизиты</div>
                        <div className={styles.HiddenValue}>
                            {requisites
                                ? requisites
                                : "Отсутствуют данные реквизиты у партнера " + billing.payAcc}
                        </div>
                    </li>
                    <li className={styles.PaySystem}></li>
                    <li className={styles.AffiseComment}>
                        <div className={styles.HiddenTitle}>Affise</div>
                        <div className={styles.HiddenValue}>{billing.affiseComment}</div>
                    </li>
                    <li className={styles.Comment}>
                        <div className={styles.HiddenTitle}>Комментарии</div>
                        <div className={styles.HiddenValue}>{billing.notes}</div>
                    </li>
                </ul>
            </div>
        </li>
    );
};
export default BillingContentLi;
