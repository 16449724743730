const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";

const defaultState = {
	items: [],
	errors: false,
	pagination: "",
	loading: true
};

export default function ordOffersReducer(state = defaultState, action) {
	switch (action.type) {
		case FETCH_OFFERS_SUCCESS:
			const { docs, ...pagination } = action.payload;
			
			return {
				...state,
				items: docs,
				pagination,
				loading: false
			};
		default:
			return state;
	}
}


export const fetchOffersSuccess = (offers) => ({
	type: FETCH_OFFERS_SUCCESS,
	payload: offers
});
