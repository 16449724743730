import styles from './OfferStatus.module.css';

const statusMap = {
	"suspended": {
		color: "#9B9B9B",
		text: "В паузе"
	},
	"active": {
		color: "#6FBD85",
		text: "Активен"
	},
	"stopped": {
		color: "#F35A5A",
		text: "Остановлен"
	},
	"notAvailable": {
		color: "transparent",
		text: "-"
	},
};

export const OfferStatus = ({ status = statusMap.notAvailable }) => {
	return (
		<div className={styles.wrapper}>
			<span className={styles.label} style={{ backgroundColor: statusMap[status]?.color }}/>
			<span>{statusMap[status].text}</span>
		</div>
	);
};