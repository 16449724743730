import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreativeTable } from "./CreativeTable/CreativeTable";
import { fetchCreatives } from "../../../../../actions/creative";

const LIMIT_PER_PAGE = 10;

export const Creatives = ({ offer, onBack }) => {
	const dispatch = useDispatch();
	const { pagination } = useSelector(state => state.contracts);
	
	useEffect(() => {
		if (offer._id) {
			dispatch(fetchCreatives({
				offerId: offer._id,
				page: 1,
				limit: LIMIT_PER_PAGE
			}));
		}
	}, [offer, dispatch]);
	
	const handlePageChange = (page) => {
		if (page === pagination.page) {
			return;
		}
		
		dispatch(fetchCreatives({
			offerId: offer._id,
			page,
			limit: LIMIT_PER_PAGE
		}));
	};
	
	
	return <CreativeTable offer={offer} onBack={onBack}
	                      onChangePage={handlePageChange}/>;
	
};