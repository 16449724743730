import * as yup from 'yup';

export const fieldNames = {
	customerType: "customerType",
	customerInn: "customerInn",
	executorType: "executorType",
	executorInn: "executorInn",
	number: "number",
	date: "date",
	isInitialContract: "isInitialContract",
	type: "type",
	subjectType: "subjectType",
	actionType: "actionType",
	amount: "amount",
	isVat: "isVat",
	description: "description"
};

export const validationSchema = yup.object({
	[fieldNames.customerInn]: yup.string().required('Обязательное поле'),
	[fieldNames.executorInn]: yup.string().required('Обязательное поле'),
	[fieldNames.number]: yup.string().required('Обязательное поле'),
	[fieldNames.amount]: yup.number().typeError('Должно быть числом').required('Обязательное поле'),
	[fieldNames.description]: yup.string().required('Обязательное поле')
})