import styles from "./Filters.module.css";
import {
	ReactComponent as SearchIcon
} from "../../../../assets/img/search-icon.svg";
import { Input } from "../../../ui/updated/Input/Input";
import { Select } from "../../../ui/updated/Select/Select";
import { FilterEntity } from "../../../../core/config";
import { Button } from "../../../ui/updated/Button/Button";
import { Controller, useForm } from "react-hook-form";
import { fetchAdvertisers } from "../../../../actions/advertisers";
import { useDispatch } from "react-redux";

const fieldNames = {
	entity: "entity",
	search: "search"
};

export const Filters = () => {
	const dispatch = useDispatch();
	const { handleSubmit, register, control, formState } = useForm({});
	const { isSubmitting } = formState;
	
	const onSubmit = async (data) => {
		const filters = [{
			name: fieldNames.entity,
			value: data.entity?.value
		},
			{
				name: fieldNames.search,
				value: data.search
			},
			{
				name: "country",
				value: "RU"
			},
			{
				name: "searchFields",
				value: ["title"]
			}
		];
		await dispatch(fetchAdvertisers({
			filters,
			page: 1,
			wasAddedToOrd: false
		}));
	};
	
	return (
		<form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.inputWrapper}>
				<Controller control={control} render={({ field }) => (
					<Select
						{...field}
						label="Юридическое лицо"
						options={FilterEntity}
						defaultValue={FilterEntity[0]}
						isSearchable={false}
					/>
				)} name={fieldNames.entity}/>
			</div>
			<div className={styles.inputWrapper}>
				<Input
					{...register(fieldNames.search)}
					label="Поиск"
					placeholder="Название"
					endAdornment={<SearchIcon className={styles.searchIcon}/>}
				/>
			</div>
			<Button className={styles.button} disabled={isSubmitting}>найти</Button>
		</form>
	);
};
