import $api from "../core/api";
import { processError } from "../core/helpers";
import {
	createCreativeSuccess,
	deleteCreativeSuccess,
	fetchCreativeSuccess,
	updateCreativeSuccess
} from "../reducers/creativeReducer";

const prefixUrl = "/api/creative";

export const fetchCreatives = (params) => {
	return async dispatch => {
		try {
			const response = await $api.get(`${prefixUrl}/`, { params });
			if (response && response.data) {
				dispatch(fetchCreativeSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const updateCreative = (creativeId, data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/update/${creativeId}`, data);
			if (response.data) {
				dispatch(updateCreativeSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const createCreative = (data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/create`, data);
			if (response && response.data) {
				dispatch(createCreativeSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const deleteCreativeMedia = (creativeId, mediaPath) => {
	return async dispatch => {
		try {
			const response = await $api.delete(`${prefixUrl}/delete-media/${creativeId}/${mediaPath}`);
			if (response && response.data) {
				dispatch(updateCreativeSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
}

export const deleteCreative = (creativeId) => {
	return async dispatch => {
		try {
			const response = await $api.delete(`${prefixUrl}/delete/${creativeId}`);
			if (response && response.data) {
				dispatch(deleteCreativeSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};