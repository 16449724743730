import styles from "./Button.module.css";

export const Button = ({
	children,
	type = "primary",
	className = "",
	...props
}) => {
	return (
		<button
			{...props}
			className={`${styles.button} ${styles[type]} ${className}`}
		>
			{children}
		</button>
	);
};
