import { format } from "date-fns";
import styles from "./OrdReportTable.module.css";

export const OrdReportTable = ({ data }) => {
	const getOfferWord = (count) => {
		const lastDigit = count % 10;
		const lastTwoDigits = count % 100;
		
		if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
			return "офферов";
		}
		
		if (lastDigit === 1) {
			return "оффер";
		}
		
		if (lastDigit >= 2 && lastDigit <= 4) {
			return "оффера";
		}
		
		return "офферов";
	};
	const count = data ? Object.values(data).length : 0;
	const word = getOfferWord(count);
	
	
	const formatBlankDate = (date) => {
		return date ? format(new Date(date), "dd.MM.yyyy") : "";
	};
	
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				РЕЗУЛЬТАТЫ: <span>{count} {word}</span>
			</div>
			<div className={styles.tableWrapper}>
				<table className={styles.table}>
					<thead className={styles.thead}>
					<tr>
						<th>Рекламодатель</th>
						<th>Оффер</th>
						<th>Токен</th>
						<th>ID&nbsp;площадки</th>
						<th>план</th>
						<th>факт</th>
						<th>Начало показов</th>
						<th>Окончание показов</th>
						<th>Фактическое начало показов</th>
						<th>Фактическое окончание</th>
						<th>Стоимость оказанных услуг, RUB</th>
						<th>Стоимость единицы оказания услуг, RUB</th>
						<th>Признак "с НДС"</th>
						<th>Акт за отчетный месяц</th>
						<th className={styles.nowrap}>кол-во<br/>выдач</th>
						<th>ID&nbsp;Вебмастера</th>
					</tr>
					</thead>
					<tbody className={styles.tbody}>
					{(data && Object.values(data).length) ? Object.values(data).map((mainData, idx) =>
						mainData.offers.map((offer, index) => (
							<tr key={`${idx}-${index}`}>
								<td>{index === 0 ? mainData.title : ""}</td>
								<td>{offer.title}</td>
								<td></td>
								<td>{!!offer.affiliate?.platforms && !!offer.affiliate?.platforms[0]?.ordId ? offer.affiliate.platforms[0].ordId : ""}</td>
								<td>{offer.clicks}</td>
								<td>{offer.clicks}</td>
								<td>{formatBlankDate(offer.period.from)}</td>
								<td>{formatBlankDate(offer.period.to)}</td>
								<td>{formatBlankDate(offer.period.from)}</td>
								<td>{formatBlankDate(offer.period.to)}</td>
								<td>{offer.charge}</td>
								<td></td>
								<td>{/* Признак с НДС */}</td>
								<td>{/* Акт за месяц */}</td>
								<td>{offer.conversions}</td>
								<td>{offer.affiliate.id}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="16" className={styles.empty}>Нет данных</td>
						</tr>
					)}
					</tbody>
				</table>
			</div>
		</div>
	);
};