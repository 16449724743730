const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
const CREATE_CONTRACT_FAILURE = "CREATE_CONTRACT_FAILURE";
const UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS";
const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";

const defaultState = {
	items: [],
	errors: [],
	pagination: "",
	loading: true
};

export default function contractsReducer(state = defaultState, action) {
	switch (action.type) {
		case FETCH_CONTRACTS_SUCCESS:
			const { docs, ...pagination } = action.payload;
			
			return {
				...state,
				items: docs,
				pagination,
				loading: false
			};
		case CREATE_CONTRACT_SUCCESS:
			return {
				...state,
				items: [action.payload, ...state.items],
				loading: false
			};
		case CREATE_CONTRACT_FAILURE:
			return {
				...state,
				errors: action.payload,
				loading: false
			};
		case UPDATE_CONTRACT_SUCCESS:
			return {
				...state,
				items: state.items.map(item => item._id === action.payload._id ? action.payload : item),
				loading: false
			};
		case DELETE_CONTRACT_SUCCESS:
			return {
				...state,
				items: state.items.filter(item => item._id !== action.payload),
				loading: false
			};
		default:
			return state;
	}
}

export const fetchContractsSuccess = (items) => ({
	type: FETCH_CONTRACTS_SUCCESS,
	payload: items
});
export const createContractSuccess = (contract) => ({
	type: CREATE_CONTRACT_SUCCESS,
	payload: contract
});
export const updateContractSuccess = (contract) => ({
	type: UPDATE_CONTRACT_SUCCESS,
	payload: contract
});
export const deleteContractSuccess = (contractId) => ({
	type: DELETE_CONTRACT_SUCCESS,
	payload: contractId
});
export const createContractFailure = (errors) => ({
	type: CREATE_CONTRACT_FAILURE,
	payload: errors
});