import {
	ReactComponent as FileUploadIcon
} from "../../../assets/img/file-upload-icon.svg";
import {
	ReactComponent as UploadedFileIcon
} from "../../../assets/img/uploaded-file-icon.svg";
import styles from "./FileUpload.module.css";
import { useEffect, useState } from "react";

export const FileUpload = ({ title, description, register, fieldName, initialFilePath, onDelete }) => {
	const [file, setFile] = useState(null);
	
	useEffect(() => {
		if (initialFilePath) {
			const fileName = initialFilePath.split("/").pop();
			setFile({ name: fileName, path: initialFilePath });
		}
	}, [initialFilePath]);
	
	const handleUploadedFile = async (event) => {
		const selectedFile = event.target.files?.[0];
		if (selectedFile) {
			setFile(selectedFile);
		}
	};
	
	const handleDelete = () => {
		if (onDelete && initialFilePath) {
			onDelete(initialFilePath);
		}
		setFile(null);
	}
	
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{title}</div>
			<div className={styles.fileUpload}>
				<input
					id="avatar-upload-input"
					type="file"
					className={styles.input}
					accept=".zip"
					{...register(fieldName, {
						onChange: handleUploadedFile
					})}
				/>
				{file && (
					<div className={styles.fileNameWrapper}>
						<div className={styles.fileName}>
							<UploadedFileIcon/>
							<span>{file.name}</span>
						</div>
						<button
							type="button"
							className={styles.deleteBtn}
							onClick={handleDelete}
						>
							Удалить
						</button>
					</div>
				)}
				{!file && (
					<div className={styles.fileUploadButton}>
						<FileUploadIcon/>
						<label htmlFor="avatar-upload-input">Загрузить файл</label>
					</div>
				)}
			</div>
			<div className={styles.description}>{description}</div>
		</div>
	);
};