import { AddButton } from "../../../../ui/addButton/AddButton";
import styles from "./ActsTable.module.css";
import { Table } from "../../../../ui/Table/Table";
import { ActsTableRow } from "../ActsTableRow/ActsTableRow";
import React, { useCallback, useEffect, useState } from "react";
import { BackButton } from "../../../../ui/BackButton/BackButton";
import { ActForm } from "../ActForm/ActForm";
import { fetchActsByAdvertiser } from "../../../../../actions/act";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../ui/pagination/Pagination";
import { useLocation } from "react-router-dom";

const LIMIT_PER_PAGE = 10;

export const ActsTable = ({ advertiser }) => {
	const dispatch = useDispatch();
	const [isAddAct, setIsAddAct] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [editingAct, setEditingAct] = useState(null);
	const { pagination, loading, items } = useSelector(state => state.act);
	const { title, id } = advertiser;
	const location = useLocation();
	
	const getQueryParam = useCallback((param) => {
		const searchParams = new URLSearchParams(location.search);
		return searchParams.get(param);
	}, [location.search]);
	
	useEffect(() => {
		const action = getQueryParam("action");
		if (action === "add") {
			setIsAddAct(true);
		}
	}, [getQueryParam, location.search]);
	
	
	useEffect(() => {
		if (id) {
			dispatch(fetchActsByAdvertiser({
				advId: id,
				page: 1,
				limit: LIMIT_PER_PAGE
			}));
		}
	}, [id, advertiser?.id, dispatch]);
	
	const handlePageChange = async (page) => {
		if (page === pagination.page) {
			return;
		}
		
		await dispatch(fetchActsByAdvertiser({
			advId: id,
			page,
			limit: LIMIT_PER_PAGE
		}));
	};
	
	const handleAddAct = () => {
		setIsAddAct(true);
	};
	
	const handleEditAct = (actId) => () => {
		setIsEdit(true);
		setEditingAct(actId);
	};
	
	const handleCancel = () => {
		setIsAddAct(false);
		setIsEdit(false);
	};
	
	
	if (isAddAct) {
		return <ActForm advertiser={advertiser} onCancel={handleCancel}/>;
	}
	
	if (isEdit) {
		return (
			<ActForm
				advertiser={advertiser}
				act={items.find(item => item._id === editingAct)}
				onCancel={handleCancel}
				isEdit={true}
			/>
		);
	}
	
	
	return (
		<div>
			<BackButton to="/ord-advertisers-empty"/>
			<h2 className={styles.title}>{title}</h2>
			<AddButton
				className={styles.btn}
				label="Добавить акт"
				onClick={handleAddAct}
			/>
			<Table
				loading={loading}
				thead={
					<tr>
						<th>ОРД ID</th>
						<th>№ и дата</th>
						<th>Статус ЕРИР</th>
						<th>Дата</th>
						<th>Сумма</th>
						<th>Описание</th>
						<th></th>
					</tr>
				}
				tbody={items.map(act => (<ActsTableRow key={act._id} item={act}
				                                       onEdit={handleEditAct(act._id)}/>))}
			/>
			<Pagination paginationData={pagination} setParamData={handlePageChange}/>
		</div>
	);
};