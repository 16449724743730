import React, { useState } from 'react';
import './Navigation.css'
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../reducers/userReducer";
import logo from '../../../assets/img/header-logo.png';
import ArrowIcon from '../../ui/icons/ArrowIcon.jsx';
import { MODULE_TYPE, getModuleRule, isEdit, isRead } from "../../../core/helpers";
import LogoutIcon from '../../../assets/img/logout-icon.png'
import { setBillingCommentOpenState } from '../../../reducers/billingReducer';

const Navigation = () => {
    const user = useSelector(state => state.user.currentUser);
    const ruleUser = getModuleRule(user.rules, MODULE_TYPE.USERS);
    const ruleBilling = getModuleRule(user.rules, MODULE_TYPE.BILLING);
    const ruleConversions = getModuleRule(user.rules, MODULE_TYPE.CONVERSIONS);
    const rulePartners = getModuleRule(user.rules, MODULE_TYPE.PARTNERS);
    const ruleOffers = getModuleRule(user.rules, MODULE_TYPE.OFFERS);
    const ruleAdvertisers = getModuleRule(user.rules, MODULE_TYPE.ADVERTISERS);
    const ruleKnowledge = getModuleRule(user.rules, MODULE_TYPE.KNOWLEDGE);
    const ruleReports = getModuleRule(user.rules, MODULE_TYPE.REPORTS);
    const dispatch = useDispatch();
    const rule = getModuleRule(user.rules, MODULE_TYPE.KNOWLEDGE);
    const [isGuest] = useState(user?.capability === 3 && !isEdit(rule));
    const billingComment = useSelector(state => state.billing.billingComment);

    // Временно ограничиваем раздел к ОРД всем кроме С. Кленко
    const isSpecialUser = user.id === '65f2ee70739b32b53af2ab9f';

    return (
        <div className="navigation" onClick={(e) => {
            if (!e.target.closest('.add_comment')) {
                if (!billingComment.trim()) {
                    dispatch(setBillingCommentOpenState(false));
                }
            }
        }}>
            <div className="container">
                <div className="logoBlock"><NavLink to="/"><img src={logo} alt="" /></NavLink></div>
                <div className="container" style={{ justifyContent: isGuest ? 'flex-start' : 'center' }}>
                    {
                        !isGuest && <div className="menu">
                            {isRead(ruleBilling) && <div className="navItem"><NavLink to="/billing">Оплаты вебмастерам</NavLink></div>}
                            {isRead(ruleUser) && <div className="navItem"><NavLink to="/users">Пользователи</NavLink></div>}
                            {isRead(ruleConversions) && <div className="navItem innerBlock">
                                <div className='navItemLabel'>
                                    <span>Конверсии</span>
                                    <ArrowIcon />
                                </div>
                                <ul className='InnerList'>
                                    <li> <NavLink to="/conversions">Управление конверсиями</NavLink> </li>
                                    <li> <NavLink to="/conversionsreconciliation">Сверка дохода конверсий</NavLink></li>
                                    <li> <NavLink to="/ConversionsCSV">Обновление конверсий CSV файлом</NavLink></li>
                                </ul>

                            </div>}
                            {isRead(rulePartners) && <div className="navItem"><NavLink to="/partners">Вебмастера</NavLink></div>}
                            {isRead(ruleOffers) && <div className="navItem"><NavLink to="/offers">Офферы</NavLink></div>}
                            {isRead(ruleAdvertisers) && <div className="navItem"><NavLink to="/advertisers">Рекламодатели</NavLink></div>}
                            {isRead(ruleKnowledge) && <div className="navItem"><NavLink to="/knowledge">База знаний</NavLink></div>}
                            {isRead(ruleReports) &&
                                <div className="navItem innerBlock">
                                    <div className='navItemLabel'>
                                        <span>Отчеты медиабаинга</span>
                                        <ArrowIcon />
                                    </div>
                                    <ul className='InnerList'>
                                        <li><NavLink to="/reports">Отчеты</NavLink></li>
                                        <li><NavLink to="/mb-projects">Каталог проектов</NavLink></li>
                                        <li><NavLink to="/expense-categories">Статьи расходов</NavLink></li>
                                        <li><NavLink to="/income-categories">Статьи доходов</NavLink></li>
                                        <li><NavLink to="/currency-rates">Курсы валют</NavLink></li>
                                    </ul>
                                </div>
                            }
                            {isSpecialUser && isRead(ruleReports) &&
                                <div className="navItem innerBlock">
                                    <div className='navItemLabel'>
                                        <span>ОРД</span>
                                        <ArrowIcon />
                                    </div>
                                    <ul className='InnerList'>
                                        <li><NavLink to="/ord-advertisers">ОРД Рекламодатели</NavLink></li>
                                        <li><NavLink to="/ord-reports">ОРД Отчет</NavLink></li>
                                        <li><NavLink to="/platforms">ОРД Площадки</NavLink></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                    {/* <div className="menu">
                        {isRead(ruleBilling) && <div className="navItem"><NavLink to="/billing">Оплаты вебмастерам</NavLink></div>}
                        {isRead(ruleUser) && <div className="navItem"><NavLink to="/users">Пользователи</NavLink></div>}
                        {isRead(ruleConversions) && <div className="navItem innerBlock">
                            <div className='navItemLabel'>
                                <span>Конверсии</span>
                                <ArrowIcon />
                            </div>
                            <ul className='InnerList'>
                                <li> <NavLink to="/conversions">Управление конверсиями</NavLink> </li>
                                <li> <NavLink to="/conversionsreconciliation">Сверка дохода конверсий</NavLink></li>
                                <li> <NavLink to="/ConversionsCSV">Обновление конверсий CSV файлом</NavLink></li>
                            </ul>

                        </div>}
                        {isRead(rulePartners) && <div className="navItem"><NavLink to="/partners">Вебмастера</NavLink></div>}
                        {isRead(ruleOffers) && <div className="navItem"><NavLink to="/offers">Офферы</NavLink></div>}
                        {isRead(ruleAdvertisers) && <div className="navItem"><NavLink to="/advertisers">Рекламодатели</NavLink></div>}
                        {isRead(ruleKnowledge) && <div className="navItem"><NavLink to="/knowledge">База знаний</NavLink></div>}
                    </div> */}
                </div>
                <div className="auth-block">
                    <div className="logout">
                        <span className="mail_text" >{user.email}</span>
                        <img className="logout_icon" src={LogoutIcon} alt="" onClick={() => dispatch(logoutUser())} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Navigation;
