import styles from "./OrdAdvertisersEmptyTableRow.module.css";
import { NavLink } from "react-router-dom";

export const OrdAdvertisersEmptyTableRow = ({ item }) => {
	return (
		<tr key={item.id}>
			<td>{item.title}</td>
			<td>{`${item.manager.firstName} ${item.manager.lastName}`}</td>
			<td>{item.country}</td>
			<td>
				<div className={styles.controls}>
					<NavLink to={`/item-ord-advertiser/${item.id}`}
					         className={styles.addBtn}>
						Добавить в ОРД
					</NavLink>
				</div>
			</td>
		</tr>
	);
};