import styles from "./ConfirmModal.module.css";
import { Button } from "../../../ui/updated/Button/Button";

export const ConfirmModal = ({ title, onConfirm, onCancel }) => {
	return (
		<div className={styles.wrapper}>
			<p dangerouslySetInnerHTML={{__html: title}} />
			<div className={styles.buttons}>
				<Button onClick={onConfirm}>Да</Button>
				<Button type="secondary" onClick={onCancel}>Нет</Button>
			</div>
		</div>
	);
};