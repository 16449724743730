import {
    setBillingItems,
    payBilling,
    setVerification,
    setCurrencyRates,
    setCurrentBilling, addVerificationError, setBillingInvoicePDFPath,
    setIsNullInvoice
} from '../reducers/billingReducer';
import {v4 as uuidv4} from 'uuid';
import $api from "../core/api";
import {createNotification} from "./app";
import {closeModal, removeModal} from "../reducers/appReducer";
import {processError} from "../core/helpers";
import { delayByLoader } from '../utils/deleyByLoader';

export const fetchBillings = (params,setLoading) => {
    return async dispatch => {
        setLoading?.(true)
        try {
            const response = await $api.post(`/api/billing`, params);
            dispatch(setBillingItems(response.data));
            return {success: true};

        } catch (e) {
            processError(dispatch, e);
        }finally{
          delayByLoader(false, setLoading, 300);
        }

    }
}

export const getBillingById = (billingId ) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/billing/${billingId}`);
            if ( response && response.data ) {
                dispatch( setCurrentBilling( response.data ) );
                return response.data;
            } else {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Ошибка получения данных биллинга'
                }));
                return false;
            }

        } catch (e) {
            processError(dispatch, e);
            return false;
        }
    }
}

export const payment = (param) => {
    return async dispatch => {
        try {
            if ( param.email && param.email.indexOf('@x-partners.com') !== -1 ) {
                dispatch(createNotification({
                    type: 'error',
                    children: 'Запрещены авансы и выплаты для вебмастеров с почтой в домене "x-partners.com"'
                }));
                return;
            }

            

            let response;
            if (param.payType === 'setPaidWiretransferNullInvoice') {
                response = await $api.post(`/api/billing/set-billing-paid-wiretransfer-nullinvoice`, param);
            } else if (param.payType === 'setPaid') {
                response = await $api.post(`/api/billing/set-billing-paid`, param);
            } else if (param.payType === 'sendInvoice') {
                response = await $api.post(`/api/billing/send-invoice`, param);
            } else {
                response = await $api.post(`/api/billing/pay`, param);
            }

            if ( response && response.data ) {
                // если результат инвойса NULL_INVOICE_POPUP значит нужно отобразить модальное окно
                // с предупреждением, что счет будет закрыт в счет долга
                if (response.data.status === 'NULL_INVOICE_POPUP') {
                    dispatch(closeModal('billingModalVerification'));
                    dispatch(setIsNullInvoice(true));
                    return { 
                        status: 'NULL_INVOICE_POPUP',
                        success: true
                    };
                }

                if (response.data.invoicePDFPath ){
                    dispatch(closeModal('billingModalVerification'));
                    dispatch(setBillingInvoicePDFPath(response.data.invoicePDFPath));
                    return true;
                }
                if ( response.data === 'verify' ) {
                    dispatch(createNotification({
                        type: 'primary',
                        children: "Введите код с email"
                    }));

                    dispatch(setVerification( true ));
                    return true;
                }
                dispatch(setVerification(false));

                if( response.data.history.length !== 0 ){
                    if ( response.data.history[0].error ) {
                        dispatch(createNotification({
                            type: 'error',
                            children: response.data.history[0].message
                        }));
                    } else {
                        dispatch(createNotification({
                            type: 'primary',
                            children: response.data.history[0].message
                        }));
                    }
                }

                dispatch(setBillingInvoicePDFPath(''));
                dispatch( closeModal('billingModalVerification') );
                dispatch(closeModal('BillingPDFConfirmModal') );
                dispatch(setCurrentBilling(response.data));
            }
            return {success: true};

        } catch (e) {
            console.error(e);
            if ( e.response && e.response.data ) {
                if ( e.response.data.message !== 'Неверный код верификации' ) {
                    dispatch( closeModal('billingModalVerification') );
                } else {
                    dispatch( addVerificationError(1) );
                }
            } else {
                dispatch( closeModal('billingModalVerification') );
            }
            dispatch(setVerification(false));
            processError(dispatch, e);
        }
    }

}

export const payChildBilling = ( params ) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/billing/pay-child`, params);
            if (response && response.data) {
                if ( response.data === 'verify' ) {
                    return response.data;
                }

                const childBilling = response.data.children.find( item => item._id === params.billingId );

                if( childBilling.history.length > 0 ){
                    if ( childBilling.history[0].error ) {
                        dispatch(createNotification({
                            type: 'error',
                            children: childBilling.history[0].message
                        }));
                    } else {
                        dispatch(createNotification({
                            type: 'primary',
                            children: childBilling.history[0].message
                        }));
                    }
                }

                dispatch( closeModal('billingModalVerification') );
                dispatch(setCurrentBilling(response.data));
            }

            return false;
        } catch (e) {
            if (e.response && e.response.data) {
                if (e.response.data.message !== 'Неверный код верификации') {
                    dispatch(closeModal('billingModalVerification'));
                } else {
                    dispatch(addVerificationError(1));
                }
            } else {
                dispatch(closeModal('billingModalVerification'));
            }
            processError(dispatch, e);
        }
    }
}

export const deletePayment = (param) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/billing/deletePayment`, param);

            if ( response.data ) {
                if (response.data === 'verify') {
                    dispatch(createNotification({
                        type: 'primary',
                        children: "Введите код с email"
                    }));
                    dispatch(setVerification(true));
                    return true;
                }

                if ( response.data === param.id ) {
                    window.location.href = '/billing';
                }
            }

            return false;

        } catch (e) {
            dispatch(closeModal('billingModalVerification'));
            processError(dispatch, e);
        }
    }

}

export const uploadFile = ( files, billingId, nds, code = '' ) => {
    return async dispatch => {
        try {
            let formData = new FormData();

            if ( files.length > 0 ) {
                let index = 0;
                for( const file of files ){
                    const format = file.name.split('.');
                    const fileName = uuidv4();
                    formData.append(
                        `document[${index}]`,
                        file,
                        `${fileName}-document.${format[format.length - 1]}`
                    );
                    index++;
                }
            }

            formData.append('billingId', billingId);
            formData.append('nds', nds);
            formData.append('code', code);

            const response = await $api.post(`/api/billing/uploadfile`, formData );

            if ( response && response.data ) {

                if ( response.data === 'verify' ) {
                    return response.data;
                }

                dispatch(removeModal('billingModalVerification'));
                dispatch(createNotification({
                    type: 'primary',
                    children: "Платежное поручение загружено"
                }));
                dispatch(setCurrentBilling(response.data));

                return true;
            }
            return false;

        } catch (e) {
            console.log(e)
            dispatch(closeModal('billingModalVerification'));
            processError(dispatch, e);
            return false;
        }
    }
}

export const getDocument = (params) => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/billing/getdocument`, params);
            if (response.data) {
                return response.data;
            }
        } catch (e) {
            processError(dispatch, e);
        }
    }
}

export const deleteDocument = (params, code = '') => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/billing/deletedocument`, {data: params, code: code});
            await dispatch(payBilling(response.data));

            if (response.data === 'verify') {
                return response.data;
            }
            if (response.data) {
                dispatch(closeModal('billingModalVerification'));
                dispatch(createNotification({
                    type: 'primary',
                    children: "Платежное поручение удалено"
                }));
                dispatch(setCurrentBilling(response.data));
                return true;
            }
            return false;
        } catch (e) {
            dispatch(closeModal('billingModalVerification'));
            processError(dispatch, e);
        }
    }
}

export const convertCurrencyBilling = (param,code) => {
    return async dispatch => {
        try {

            const response = await $api.post(`/api/billing/convertCurrency`, {...param,code:code});
            if (response) {
                if ( response.data === 'verify' ) {
                    dispatch(createNotification({
                        type: 'primary',
                        children: "Введите код с email"
                    }));
                    // dispatch(setVerification( 'verify' ));
                    return true;
                }
                // dispatch(setVerification(false));
                if (response.data.history[0].error) {
                    dispatch(createNotification({
                        type: 'error',
                        children: response.data.history[0].message
                    }));
                } else {
                    dispatch(createNotification({
                        type: 'primary',
                        children: response.data.history[0].message
                    }));
                }
                dispatch(setCurrentBilling(response.data));
                dispatch(closeModal('billingModalVerification'));
            }
            return {success: true};

        } catch (e) {
            console.error(e);
            dispatch(setVerification(false));
            dispatch(closeModal('billingModalVerification'));
            processError(dispatch, e);
        }
    }

}

export const getCurrencyRates = () => {
    return async dispatch => {
        try {
            const response = await $api.post(`/api/billing/getcurrencyrates`);
            dispatch(setCurrencyRates(response.data));
        } catch (e) {
            dispatch(createNotification({
                type: 'error',
                children: e.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
            }));
        }
    }
}

export const payOrderLinkUpload = ( billingId, token, file ) => {
    return async dispatch => {
        try {
            const formData = new FormData();

            formData.append('billingId', billingId );
            formData.append('token', token );
            formData.append('pdf', file, file.name );

            const response = await $api.post(`/api/billing/pay-order-upload`, formData );

            if ( response && response.data ) {
                return response.data;
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
            return false;
        }
    }
}

export const getBillingAffiseId = ( billingId ) => {
    return async dispatch => {
        try {
            const response = await $api.get(`/api/billing/get-affise-id/${billingId}`);

            if ( response && response.data ) {
                return response.data
            }
            return false;
        } catch (e) {
            processError(dispatch, e);
            return false;
        }
    }
}

export const addComment = (body,addBillingCommentSuccess)=>{
  return async dispatch =>{
    try {
        const response =  await $api.post(`/api/billing/add-comment`,body);
        addBillingCommentSuccess(response.data)
    } catch (e) {
        processError(dispatch, e);
    }
  }
}

export const removeInvoicePDF = (path)=>{
  return async dispatch =>{
    try {
        await $api.delete(`/api/billing/delete-invoice-pdf/?path=${path}`);
        dispatch(setBillingInvoicePDFPath(''));
    } catch (e) {
        processError(dispatch, e);
    }
  }
}

