import styles from "./General.module.css";
import { BackButton } from "../../../ui/BackButton/BackButton";
import { AdvertiserForm } from "./AdvertiserForm/AdvertiserForm";
import { useSelector } from "react-redux";

export const General = () => {
	const advertiser = useSelector(state => state.advertisers.current);
	
	return (
		<>
			<BackButton to="/ord-advertisers-empty"/>
			<div className={styles.wrapper}>
				<h2>{advertiser?.title}</h2>
				<AdvertiserForm advertiser={advertiser}/>
			</div>
		</>
	);
};