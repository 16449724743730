const SET_PAGES_ADVERTISERS =  "SET_PAGES_ADVERTISERS";
const SET_ADVERTISER =  "SET_ADVERTISER";
const SET_CURRENT_ADVERTISER =  "SET_CURRENT_ADVERTISER";
const ADVERTISER_RESET_PAGINATION = "ADVERTISER_RESET_PAGINATION";


const defaultState = {
    items: [],
    current: null,
    errors: false,
    pagination:'',
};
export default function advertisersReducer(state = defaultState, action){
    switch (action.type) {
        case SET_PAGES_ADVERTISERS:
            const { docs, ...pagination } = action.payload;

            return {
                ...state,
                items:  docs,
                pagination,
            };
        case SET_ADVERTISER:
        {

            return {
                ...state,
                items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
            };
        }
        case SET_CURRENT_ADVERTISER:
            return {
                ...state,
                current: action.payload,
            };
        case ADVERTISER_RESET_PAGINATION:
            return {
                ...state,
                pagination: {
                  ...state.pagination,
                  page: 1
                }
            };
        default:
            return state
    }
}
export const setCurrentAdvertiser = (item) => ({type: SET_CURRENT_ADVERTISER, payload: item});
export const setAdvertiserItem = (item) => ({type: SET_ADVERTISER, payload: item});
export const setAdvertisersPageItems = (items) => ({type: SET_PAGES_ADVERTISERS, payload: items});
export const setAdvertiserPaginationReset = () => ({type: ADVERTISER_RESET_PAGINATION});
