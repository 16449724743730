import $api from "../core/api";
import { processError } from "../core/helpers";
import {
	createContractSuccess,
	deleteContractSuccess,
	fetchContractsSuccess,
	updateContractSuccess,
	createContractFailure
} from "../reducers/contactsReducer";

export const prefixUrl = "/api/contracts";

export const fetchContracts = (params) => {
	return async dispatch => {
		try {
			const response = await $api.get(prefixUrl, { params });
			if (response && response.data) {
				dispatch(fetchContractsSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const createContract = (data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/create`, data);
			if (response.data && response?.data.errors) {
				dispatch(createContractFailure(response.data.errors));
				return;
			}
			if (response && response.data) {
				dispatch(createContractSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const updateContract = (contractId, data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/update/${contractId}`, data);
			if (response.data) {
				dispatch(updateContractSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const deleteContract = (contractId) => {
	return async dispatch => {
		try {
			const response = await $api.delete(`${prefixUrl}/delete/${contractId}`);
			if (response.data) {
				dispatch(deleteContractSuccess(contractId));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};
