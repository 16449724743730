import { forwardRef } from "react";
import styles from "./DatePicker.module.css";
import DefaultDatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";

export const DatePicker = forwardRef(({
	label,
	classNameLabel = "",
	classNameInput = "",
	isRequired,
	value,
	startAdornment,
	endAdornment,
	errorMessage = "",
	...props
}, ref) => {
	
	return (
		<div className={styles.wrapper}>
			{label && (
				<label className={`${styles.label} ${classNameLabel}`}>
					{label}
					{isRequired && "*"}
				</label>)}
			<div className={styles.inputWrapper}>
				{startAdornment &&
					<div className={styles.adornment}>{startAdornment}</div>}
				<DefaultDatePicker
					selected={value}
					ref={ref}
					locale={ru}
					className={`${styles.input} ${classNameInput}`}
					{...props}
				/>
				{endAdornment && <div className={styles.adornment}>{endAdornment}</div>}
			</div>
			{errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
		</div>
	);
});
