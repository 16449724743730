import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchAdvertisers } from "../../../../actions/advertisers";
import styles from "./OrdAdvertisersTable.module.css";
import Pagination from "../../../ui/pagination/Pagination";
import {
	OrdAdvertisersTableRow
} from "./OrdAdvertisersTableRow/OrdAdvertisersTableRow";
import { Table } from "../../../ui/Table/Table";

const defaultFilters = {
	filters: [{ name: "country", value: "RU" }],
	wasAddedToOrd: true
};

export const OrdAdvertisersTable = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const { items, pagination } = useSelector(state => state.advertisers);
	
	useEffect(() => {
		dispatch(fetchAdvertisers({ ...defaultFilters, page: 1 }, setLoading));
		// eslint-disable-next-line
	}, []);
	
	const handlePaginationClick = (value) => {
		if (value === pagination.page) {
			return;
		}
		
		dispatch(fetchAdvertisers({ ...defaultFilters, page: value }, setLoading));
	};
	
	return (
		<div className={styles.wrapper}>
			<Table
				thead={
					<tr>
						<th>Название</th>
						<th>Менеджер в ОРД</th>
						<th>Юр. лицо</th>
						<th>Офферы ОРД</th>
						<th></th>
					</tr>
				}
				tbody={items.map((item) => (
					<OrdAdvertisersTableRow item={item} key={item.id}/>))}
				loading={loading}
			/>
			<Pagination paginationData={pagination}
			            setParamData={handlePaginationClick}/>
		</div>
	);
};