import styles from "./ContractForm.module.css";
import { Button } from "../../../../ui/updated/Button/Button";
import {
	ReactComponent as ArrowIcon
} from "../../../../../assets/img/arrow-icon.svg";
import { CompanyLabel } from "../../../../ui/companyLabel/CompanyLabel";
import { Controller, useForm } from "react-hook-form";
import { Select } from "../../../../ui/updated/Select/Select";
import {
	actionTypesOptions,
	companyTypesOptions,
	contractTypesOptions,
	subjectTypesOptions
} from "../../../../../core/config";
import { Input } from "../../../../ui/updated/Input/Input";
import { DatePicker } from "../../../../ui/updated/DatePicker/DatePicker";
import {
	ReactComponent as CalendarIcon
} from "../../../../../assets/img/calendar-icon.svg";
import { Checkbox } from "../../../../ui/updated/Checkbox/Checkbox";
import { Textarea } from "../../../../ui/updated/Textarea/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
	createContract,
	deleteContract,
	updateContract
} from "../../../../../actions/contracts";
import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { RepeatModal } from "../../RepeatModal/RepeatModal";
import { addModal, removeModal } from "../../../../../reducers/appReducer";
import { useCallback, useEffect } from "react";
import { SendingStatus } from "../../../../ui/sendingStatus/SendingStatus";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validationSchema } from "./validationSchema";


const deleteModalId = "deleteContract";
const confirmSendToOrdModalId = "confirmSendToOrd";
const errorSendToOrdModalId = "errorSendToOrd";

export const ContractForm = ({
	advertiser,
	contract,
	isEdit = false,
	onCancel
}) => {
	const dispatch = useDispatch();
	const { errors } = useSelector(state => state.contracts);
	const { id, title } = advertiser;
	const { register, control, handleSubmit, formState } = useForm({
		defaultValues: {
			[fieldNames.customerType]: contract?.customerType ? companyTypesOptions.find(option => option.value === contract.customerType).value : companyTypesOptions[0].value,
			[fieldNames.customerInn]: contract?.customerInn || "",
			[fieldNames.executorType]: contract?.executorType ? companyTypesOptions.find(option => option.value === contract.executorType).value : companyTypesOptions[0].value,
			[fieldNames.executorInn]: contract?.executorInn || "",
			[fieldNames.number]: contract?.number || "",
			[fieldNames.date]: contract?.date ? new Date(contract.date) : new Date(),
			[fieldNames.isInitialContract]: contract?.isInitialContract || false,
			[fieldNames.type]: contract?.type ? contractTypesOptions.find(option => option.value === contract.type).value : contractTypesOptions[0].value,
			[fieldNames.subjectType]: contract?.subjectType ? subjectTypesOptions.find(option => option.value === contract.subjectType).value : subjectTypesOptions[0].value,
			[fieldNames.amount]: contract?.amount || "",
			[fieldNames.isVat]: contract?.isVat || false,
			[fieldNames.description]: contract?.description || "",
			[fieldNames.actionType]: contract?.actionType ? actionTypesOptions.find(option => option.value === contract.actionType).value : actionTypesOptions[0].value
		},
		resolver: yupResolver(validationSchema)
	});
	const { isSubmitted, errors: validationErrors } = formState;
	
	const handleRepeat = useCallback(() => {
		dispatch(removeModal(errorSendToOrdModalId));
		handleSubmit(onSubmit)();
		// eslint-disable-next-line
	}, [handleSubmit, dispatch]);
	
	
	useEffect(() => {
		if (isSubmitted && !errors.length) {
			dispatch(removeModal(confirmSendToOrdModalId));
			
			return;
		}
		
		if (isSubmitted && errors.length) {
			const modalParams = {
				id: errorSendToOrdModalId,
				size: "middle",
				children: <RepeatModal
					title="В данных есть ошибка. <br/> Данные не отправлены."
					onRepeat={handleRepeat}/>
			};
			dispatch(addModal(modalParams));
		}
	}, [handleRepeat, errors, isSubmitted, dispatch]);
	
	const onSubmitWithoutOrd = async (data) => {
		if (isEdit) {
			await dispatch(updateContract(contract._id, data));
			return;
		}
		
		await dispatch(createContract({ ...data, advId: id }));
	};
	
	
	const onSubmit = async (data) => {
		if (isEdit) {
			await dispatch(updateContract(contract._id, {
				...data,
				advId: id,
				sendToOrd: true
			}));
			return;
		}
		await dispatch(createContract({ ...data, advId: id, sendToOrd: true }));
		dispatch(removeModal(confirmSendToOrdModalId));
	};
	
	const handleDeleteContract = async () => {
		await dispatch(deleteContract(contract._id));
		dispatch(removeModal(deleteModalId));
		onCancel();
	};
	
	const handleOpenSendToOrdModal = (e) => {
		e.preventDefault();
		const modalParams = {
			id: confirmSendToOrdModalId,
			size: "middle",
			children: <ConfirmModal
				title="Данные будут отправлены в ЕРИР. <br/> Подтвердите отправку."
				onConfirm={handleSubmit(onSubmit)}
				onCancel={handleCancel(confirmSendToOrdModalId)}/>
		};
		dispatch(addModal(modalParams));
	};
	
	const handleCancel = (modalId) => () => {
		dispatch(removeModal(modalId));
	};
	
	const handleOpenConfirmDeleteModal = () => {
		const modalParams = {
			id: deleteModalId,
			size: "middle",
			children: <ConfirmModal
				title="Договор будет удален.<br/>Подтвердите."
				onConfirm={handleDeleteContract}
				onCancel={handleCancel(deleteModalId)}/>
		};
		dispatch(addModal(modalParams));
	};
	
	
	return (
		<div>
			<Button type="secondary" onClick={onCancel}>
				<ArrowIcon className={styles.arrowIcon}/>
				Назад
			</Button>
			<div className={styles.wrapper}>
				<div className={styles.formHeader}>
					<div className={styles.titleWrapper}>
						<div className={styles.title}>
							<h2>
								{isEdit ? "Редактирование договора" : "Добавление договора"}
							</h2>
							<CompanyLabel>
								{title}
							</CompanyLabel>
						</div>
						<div className={styles.subTitle}>
							{!isEdit && "Заполните информацию о договоре"}
							{isEdit && <SendingStatus isSent={!!contract.ordId}/>}
						</div>
					</div>
					{isEdit && (
						<Button
							type="danger"
							className={styles.deleteBtn}
							onClick={handleOpenConfirmDeleteModal}
							disabled={!!contract?.ordId}
						>
							Удалить
						</Button>
					)}
				</div>
				<form className={styles.form}>
					<div className={styles.fieldGroup}>
						<div className={styles.formTitle}>
							Данные заказчика
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<Select
									{...field}
									label="Тип организации"
									options={companyTypesOptions}
									value={companyTypesOptions.find(option => option.value === field.value)}
									onChange={(value) => field.onChange(value.value)}
									isSearchable={false}
									isRequired
								/>
							)} name={fieldNames.customerType}/>
						</div>
						<div className={styles.field}>
							<Input
								label="ИНН"
								{...register(fieldNames.customerInn)}
								isRequired
								errorMessage={validationErrors[fieldNames.customerInn]?.message}
							/>
						</div>
					</div>
					<div className={styles.fieldGroup}>
						<div className={styles.formTitle}>
							Данные исполнителя
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<Select
									{...field}
									label="Тип организации"
									options={companyTypesOptions}
									value={companyTypesOptions.find(option => option.value === field.value)}
									onChange={(value) => field.onChange(value.value)}
									isSearchable={false}
									isRequired
								/>
							)} name={fieldNames.executorType}/>
						</div>
						<div className={styles.field}>
							<Input
								label="ИНН"
								{...register(fieldNames.executorInn)}
								isRequired
								errorMessage={validationErrors[fieldNames.executorInn]?.message}
							/>
						</div>
					</div>
					<div className={styles.fieldGroup}>
						<div className={styles.formTitle}>
							Данные договора
						</div>
						<div className={styles.field}>
							<Input
								label="Номер договора"
								{...register(fieldNames.number)}
								isRequired
								errorMessage={validationErrors[fieldNames.number]?.message}
							/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<DatePicker
									{...field}
									label="Дата договора"
									dateFormat="dd.MM.yyyy"
									endAdornment={<CalendarIcon className={styles.calendarIcon}/>}
									isRequired
								/>
							)} name={fieldNames.date}/>
						</div>
						<div className={styles.field}>
							<Controller render={
								({ field }) => (
									<Checkbox
										{...field}
										label="Является изначальным договором"
										checked={field.value}
									/>
								)
							} control={control} name={fieldNames.isInitialContract}/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<Select
									{...field}
									label="Тип договора"
									options={contractTypesOptions}
									value={contractTypesOptions.find(option => option.value === field.value)}
									onChange={(value) => field.onChange(value.value)}
									isSearchable={false}
									isRequired
								/>
							)} name={fieldNames.type}/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<Select
									{...field}
									label="Сведения о предмете договора"
									options={subjectTypesOptions}
									value={subjectTypesOptions.find(option => option.value === field.value)}
									onChange={(value) => field.onChange(value.value)}
									isSearchable={false}
									isRequired
								/>
							)} name={fieldNames.subjectType}/>
						</div>
						<div className={styles.field}>
							<Controller control={control} render={({ field }) => (
								<Select
									{...field}
									label="Описание осуществляемых посредником-представителем действий"
									options={actionTypesOptions}
									value={actionTypesOptions.find(option => option.value === field.value)}
									onChange={(value) => field.onChange(value.value)}
									isSearchable={false}
									isRequired
								/>
							)} name={fieldNames.actionType}/>
						</div>
						<div className={styles.field}>
							<Input
								type="number"
								label="Стоимость услуг по договору, ₽"
								{...register(fieldNames.amount)}
								isRequired
								errorMessage={validationErrors[fieldNames.amount]?.message}
							/>
						</div>
						<div className={styles.field}>
							<Controller render={
								({ field }) => (
									<Checkbox
										{...field}
										label="С НДС"
										checked={field.value}
									/>
								)
							} control={control} name={fieldNames.isVat}/>
						</div>
						<div className={styles.field}>
							<Textarea
								label="Описание"
								placeholder="Введите описание"
								{...register(fieldNames.description)}
								isRequired
								errorMessage={validationErrors[fieldNames.description]?.message}
							/>
						</div>
					</div>
					<div className={styles.buttons}>
						<Button type="secondary" onClick={handleSubmit(onSubmitWithoutOrd)}
						        disabled={!!contract?.ordId}>
							Сохранить без отправки
						</Button>
						<Button onClick={handleOpenSendToOrdModal}
						        disabled={!!contract?.ordId}>
							Сохранить и отправить в ОРД
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
};