import AppContent from "../../appContent/AppContent";
import { useSelector } from "react-redux";
import { getModuleRule, isRead, MODULE_TYPE } from "../../../core/helpers";
import { Redirect } from "react-router-dom";
import { Filters } from "./Filters/Filters";
import { OrdAdvertisersTable } from "./OrdAdvertisersTable/OrdAdvertisersTable";
import { AddButton } from "../../ui/addButton/AddButton";

const OrdAdvertisers = () => {
	const rule = getModuleRule(useSelector(state => state.user.currentUser.rules), MODULE_TYPE.ADVERTISERS);
	
	if (!isRead(rule)) {
		return (
			<Redirect to="/"/>
		);
	}
	
	return (
		<AppContent>
			<div className="container wrapper">
				<AddButton label="Добавить рекламодателя" to="/ord-advertisers-empty"/>
				<Filters/>
				<OrdAdvertisersTable/>
			</div>
		</AppContent>
	);
};

export default OrdAdvertisers;
