import React, { Suspense, lazy, useEffect, useState } from "react";
import styles from "./Login.module.css";
import { useDispatch, useSelector } from "react-redux";
import { delayByLoader } from "../../utils/deleyByLoader";
import { Redirect } from "react-router-dom";
import InputLabel from "../ui/input/inputLabel/InputLabel";
import Button from "../ui/button/Button";
import { login } from "../../actions/user";
import { addModal, removeModal } from "../../reducers/appReducer";
import ContentLoader from "../ui/contentLoader/ContentLoader";
import Logo from "../../assets/img/header-logo.png";
import { NavLink } from "react-router-dom";
import AppContent from "../appContent/AppContent";
import EyeIcon from "../../assets/img/eye-icon.png";

const ModalVerification = lazy(() =>
  import("../modals/billingModalVerification/BillingModalVerification")
);

const Login = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const userErrors = useSelector((state) => state.user.errors);
  const [loading, setLoading] = useState(true);
  const [eye, setEye] = useState(false);

  const [authData, setAuthData] = useState({
    email: { value: "", errors: false },
    password: { value: "", errors: false },
  });

  useEffect(() => {
    delayByLoader(true, setLoading, 300);
  }, []);

  useEffect(() => {
    if (userErrors) {
      let theFields = { ...authData };
      userErrors.map((error) => {
        if (error.param in authData) {
          theFields[error.param] = {
            value: authData[error.param].value,
            error: error.msg,
          };
        }
        return error;
      });
      setAuthData({ ...theFields });
    }
    // eslint-disable-next-line
  }, [userErrors]);

  const onLoginHandler = () => {
    setLoading(true);
    const fetchData = async () => {
      const res = await dispatch(
        login(authData.email.value, authData.password.value)
      );
      if (res === "verify") {
        dispatch(
          addModal({
            id: "ModalVerification",
            size: "big",
            children: (
              <Suspense fallback={<ContentLoader />}>
                {ModalVerification && (
                  <ModalVerification
                    type="login"
                    params={{
                      email: authData.email.value,
                      password: authData.password.value,
                    }}
                    onError={() => {
                      dispatch(removeModal("ModalVerification"));
                    }}
                  />
                )}
              </Suspense>
            ),
          })
        );
      }
    };
    fetchData().then(() => setLoading(false));
  };

  if (isAuth) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const onEyeHandler = () => {
    setEye(!eye);
  };

  return (
    <AppContent paddingReset>
      <div className={styles.container}>
        <div className={styles.content}>
          {loading && <ContentLoader />}
          <div className={styles.logo}>
            <img src={Logo} alt="" />
          </div>
          <div className={styles.inputs}>
            <InputLabel
              type="email"
              value={authData.email.value}
              placeholder="E-mail"
              setValue={(val) => {
                setAuthData({
                  ...authData,
                  email: { value: val, error: false },
                });
              }}
              className={styles.email_input}
              onKeyPress={(e) => e.key === "Enter" && onLoginHandler()}
            />
            {authData.email.error && (
              <span className={styles.error_text}>{authData.email.error}</span>
            )}
            <InputLabel
              type={eye ? "text" : "password"}
              value={authData.password.value}
              placeholder="Password"
              setValue={(val) => {
                setAuthData({
                  ...authData,
                  password: { value: val, error: false },
                });
              }}
              className={styles.password_input}
              onKeyPress={(e) => e.key === "Enter" && onLoginHandler()}
              icon={
                <img
                  src={EyeIcon}
                  alt="eye"
                  className={styles.eye_icon}
                  onClick={onEyeHandler}
                />
              }
            />
            {authData.password.error && (
              <span className={styles.error_text}>
                {authData.password.error}
              </span>
            )}

            <Button className={styles.login_btn} onClick={onLoginHandler}>
              Войти
            </Button>
          </div>
          <div className={styles.recover_link}>
            <NavLink to="/recover">Забыли пароль?</NavLink>
          </div>
        </div>
      </div>
    </AppContent>
  );
};

export default Login;
