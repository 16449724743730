import styles from "./OrdReports.module.css";
import { format } from "date-fns";
import AppContent from "../../appContent/AppContent";
import { Controller, useForm } from "react-hook-form";
import { Select } from "../../ui/updated/Select/Select";
import { EntityOptions } from "../../../core/config";
import {
	DatePickerRange
} from "../../ui/updated/DatePickerRange/DatePickerRange";
import {
	ReactComponent as CalendarIcon
} from "../../../assets/img/calendar-icon.svg";
// import {
// 	ManagersSelect
// } from "../ordAdvertisers/Filters/ManagersSelect/ManagersSelect";
import { Button } from "../../ui/updated/Button/Button";
import $api from "../../../core/api";
import { useState } from "react";
import { OrdReportTable } from "./ordReportTable/OrdReportTable";

const fieldNames = {
	ordEntity: "ordEntity",
	period: "period",
	manager: "manager"
};

const OrdReports = () => {
	const [reportData, setReportData] = useState(null);
	const { control, handleSubmit } = useForm({
		defaultValues: {
			[fieldNames.ordEntity]: EntityOptions[0],
			[fieldNames.period]: [null, null],
			[fieldNames.manager]: { label: "Все", value: "" }
		}
	});
	
	const onSubmit = async (data) => {
		const submitData = {
			ordEntity: data[fieldNames.ordEntity].value,
			period: data[fieldNames.period].map(date => date ? format(date, "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd")),
			manager: data[fieldNames.manager].value
		};
		const response = await $api.post(`/api/ord-reports/create`, submitData);
		if (response && response.data && Object.keys(response.data).length > 0) {
			setReportData(response.data);
		}
	};
	
	return (
		<AppContent>
			<div className="container wrapper">
				<h2>ОРД отчет</h2>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.field}>
						<Controller control={control} render={({ field }) => (
							<Select
								{...field}
								label="Юридическое лицо"
								options={EntityOptions}
								isSearchable={false}
							/>
						)} name={fieldNames.ordEntity}/>
					</div>
					<div className={styles.field}>
						<Controller control={control} render={({ field }) => (
							<DatePickerRange
								{...field}
								startDate={field.value ? field.value[0] : null}
								endDate={field.value ? field.value[1] : null}
								onChange={(update) => {
									field.onChange(update);
								}}
								label="Период"
								dateFormat="dd.MM.yyyy"
								endAdornment={<CalendarIcon className={styles.calendarIcon}/>}
							/>
						)} name={fieldNames.period}/>
					</div>
					{/*<div className={styles.field}>*/}
					{/*	<Controller control={control} render={({ field }) => (*/}
					{/*		<ManagersSelect*/}
					{/*			label="Менеджер ОРД"*/}
					{/*			additionalOptions={[{ label: "Все", value: "" }]}*/}
					{/*			field={field}/>*/}
					{/*	)} name={fieldNames.manager}/>*/}
					{/*</div>*/}
					<Button type="primary">Сформировать</Button>
				</form>
				<OrdReportTable data={reportData}/>
			</div>
		</AppContent>
	);
};

export default OrdReports;