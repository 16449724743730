/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AppContent from "../../appContent/AppContent";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import styles from "./Advertisers.module.css";
import AdvertisersContent from "./content/AdvertisersContent";
import AdvertisersFilters from "./filters/AdvertisersFilters";
import { useDispatch, useSelector } from "react-redux";
import { MODULE_TYPE, getModuleRule, isRead } from "../../../core/helpers";
import { Redirect } from "react-router-dom";
import { fetchAdvertisers } from "../../../actions/advertisers";
import Pagination from "../../ui/pagination/Pagination";
import { filterParamsAdvertiser } from "../../../core/config";
import { setAdvertiserPaginationReset } from '../../../reducers/advertisersReduser';

const Advertisers = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const data = useSelector(state => state.advertisers.items);
    const pagination = useSelector(state => state.advertisers.pagination);
    const rule = getModuleRule(useSelector(state => state.user.currentUser.rules), MODULE_TYPE.ADVERTISERS);
    let filters = JSON.parse(JSON.stringify(filterParamsAdvertiser));

    let paramsDefault = {
        filters: filters,
        page: pagination.page,
    };

    useEffect(() => {
        return () => {
            dispatch(setAdvertiserPaginationReset());
        }
    }, []);

    const [params, setParams] = useState(paramsDefault);

    useEffect(() => {
        params.filters.push({ name: 'searchFields', value: ['email', 'title'] });
        dispatch(fetchAdvertisers(params, setLoading));
    }, [dispatch, params]);

    if (!isRead(rule)) {
        return (
            <Redirect to='/' />
        );
    }

    return (
        <AppContent>
            <div className={`partnersContainer container wrapper`}>
                <div className={`${styles.TitleBlock}`}>
                    <h2>Рекламодатели</h2>
                </div>

                <div className={`${styles.FiltersBlock}`}>
                    <AdvertisersFilters params={params.filters} setLoading={setLoading}
                        setParams={(val) => setParams({ ...params, filters: val.filter, page: val.page })} />
                </div>
                {loading ? <ContentLoader /> : (
                    <div className={styles.ContentBlock}>
                        {params.filters.search && <div className={styles.DescSearch}>Результаты поиска</div>}
                        <AdvertisersContent data={data} loading={loading} />
                    </div>
                )}

                <div className={styles.PaginationBlock}>
                    <Pagination paginationData={pagination} paramData={params}
                        setParamData={(val) => setParams({ ...params, page: val })} />
                </div>
            </div>
        </AppContent>
    );
};

export default Advertisers;