const SET_PAGE_BILLINGS = "SET_BILLINGS";
const SET_CURRENT_BILLING = "SET_CURRENT_BILLING";
const PAY_BILLING = "PAY_BILLING";
const DELETE_BILLING = "DELETE_BILLING";
const SET_BILLING_ERRORS = "SET_BILLING_ERRORS";
const SET_VERIFICATION = "SET_VERIFICATION";
const SET_SORT = "SET_SORT";
const SET_CURRENCY_RATES_DATA = "SET_CURRENCY_RATES_DATA";
const ADD_VERIFICATION_ERRORS = "ADD_VERIFICATION_ERRORS";
const SET_VERIFICATION_ERRORS = "SET_VERIFICATION_ERRORS";
const SET_DATEPICKER_OPEN = "SET_DATEPICKER_OPEN";
const SET_FILTER_RESET = "SET_FILTER_RESET";
const BILLING_RESET_PAGINATION = "BILLING_RESET_PAGINATION";
const SET_BILLING_COMMENT = "SET_BILLING_COMMENT";
const SET_BILLING_COMMENT_OPEN_STATE = "SET_BILLING_COMMENT_OPEN_STATE";
const SET_BILLING_INVOICE_PDF_PATH = "SET_BILLING_INVOICE_PDF_PATH";
const SET_IS_NULL_INVOICE = "SET_IS_NULL_INVOICE";

const defaultState = {
    items: [],
    current: null,
    errors: false,
    verification: false,
    verificationErrors: 0,
    pagination: '',
    sort: { name: 'postedDate', value: 2 },
    sumTax: '',
    sumRevenue: '',
    currencyRatesData: [],
    datePickerOpen: false,
    filterReset: false,
    billingComment: '',
    billingCommentOpenState: false,
    billingInvoicePDFPath: '',
    isNullInvoice: false,
};

export default function billingReducer (state = defaultState, action) {
    switch (action.type) {
        case SET_IS_NULL_INVOICE:
            return {
                ...state,
                isNullInvoice: action.payload,
            };
        case SET_PAGE_BILLINGS:
            const item = action.payload.docs;
            const sumTax = action.payload.sum;
            const sumRevenue = action.payload.sumRevenue;
            const currencyRatesData = action.payload.currencyRatesData;
            delete action.payload.docs;
            return {
                ...state,
                items: item,
                pagination: action.payload,
                sumTax: sumTax,
                sumRevenue: sumRevenue,
                currencyRatesData: currencyRatesData,
            };
        case PAY_BILLING:
            return {
                ...state,
                items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
            };
        case DELETE_BILLING:
            return {
                ...state,
                items: state.items.filter(n => n.id !== action.payload),
            };
        case SET_CURRENT_BILLING:
            return {
                ...state,
                current: action.payload
            }
        case SET_SORT:
            return {
                ...state,
                sort: action.payload,
            }
        case SET_VERIFICATION:
            return {
                ...state,
                verification: action.payload,
            }
        case SET_BILLING_ERRORS:
            return {
                ...state,
                errors: action.payload
            };
        case SET_CURRENCY_RATES_DATA:
            return {
                ...state,
                currencyRatesData: action.payload
            };
        case ADD_VERIFICATION_ERRORS:
            return {
                ...state,
                verificationErrors: state.verificationErrors + action.payload
            };
        case SET_VERIFICATION_ERRORS:
            return {
                ...state,
                verificationErrors: action.payload
            };
        case SET_DATEPICKER_OPEN:
            return {
                ...state,
                datePickerOpen: action.payload
            };
        case SET_FILTER_RESET:
            return {
                ...state,
                filterReset: action.payload
            };
        case BILLING_RESET_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: 1
                }
            };
        case SET_BILLING_COMMENT:
            return {
                ...state,
                billingComment: action.payload
            };
        case SET_BILLING_COMMENT_OPEN_STATE:
            return {
                ...state,
                billingCommentOpenState: action.payload
            };
        case SET_BILLING_INVOICE_PDF_PATH:
            return {
                ...state,
                billingInvoicePDFPath: action.payload
            }
        default:
            return state
    }
}

export const setBillingItems = (items) => ({ type: SET_PAGE_BILLINGS, payload: items });
export const setCurrentBilling = (billing) => ({ type: SET_CURRENT_BILLING, payload: billing });
export const payBilling = item => ({ type: PAY_BILLING, payload: item });
export const setDeleteItem = item => ({ type: DELETE_BILLING, payload: item });
export const setBillingErrors = errors => ({ type: SET_BILLING_ERRORS, payload: errors });
export const setVerification = verification => ({ type: SET_VERIFICATION, payload: verification });
export const setSort = sort => ({ type: SET_SORT, payload: sort });
export const setCurrencyRates = currencyRatesData => ({ type: SET_CURRENCY_RATES_DATA, payload: currencyRatesData });
export const addVerificationError = (value) => ({ type: ADD_VERIFICATION_ERRORS, payload: value });
export const setVerificationError = (value) => ({ type: SET_VERIFICATION_ERRORS, payload: value });
export const setDatePickerOpen = (value) => ({ type: SET_DATEPICKER_OPEN, payload: value });
export const setFilterReset = (value) => ({ type: SET_FILTER_RESET, payload: value });
export const setBillingPaginationReset = () => ({ type: BILLING_RESET_PAGINATION });
export const setBillingComment = (value) => ({ type: SET_BILLING_COMMENT, payload: value });
export const setBillingCommentOpenState = (value) => ({ type: SET_BILLING_COMMENT_OPEN_STATE, payload: value });
export const setBillingInvoicePDFPath = (value) => ({ type: SET_BILLING_INVOICE_PDF_PATH, payload: value });
export const setIsNullInvoice = (value) => ({ type: SET_IS_NULL_INVOICE, payload: value });