import styles from "./CreativeForm.module.css";
import { BackButton } from "../../../../../ui/BackButton/BackButton";
import { CompanyLabel } from "../../../../../ui/companyLabel/CompanyLabel";
import { Controller, useForm } from "react-hook-form";
import { ContractsSelect } from "../../../../../ui/ContractsSelect/ContractsSelect";
import { Button } from "../../../../../ui/updated/Button/Button";
import {
	formAdvertisingCompanyOptions,
	typeAdvertisingCompanyOptions
} from "../../../../../../core/config";
import { Select } from "../../../../../ui/updated/Select/Select";
import { Checkbox } from "../../../../../ui/updated/Checkbox/Checkbox";
import { Input } from "../../../../../ui/updated/Input/Input";
import {
	createCreative,
	deleteCreative, deleteCreativeMedia,
	updateCreative
} from "../../../../../../actions/creative";
import { useDispatch } from "react-redux";
import { addModal, removeModal } from "../../../../../../reducers/appReducer";
import { ConfirmModal } from "../../../ConfirmModal/ConfirmModal";
import { SendingStatus } from "../../../../../ui/sendingStatus/SendingStatus";
import { FileUpload } from "../../../../../ui/fileUpload/FileUpload";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema, fieldNames } from "./validationSchema";


const deleteCreativeModalId = "deleteCreative";
const sendCreativeToOrdModalId = "sendCreativeToOrd";

export const CreativeForm = ({ offer, creative, isEdit = false, onCancel }) => {
	const dispatch = useDispatch();
	const { title } = offer;
	const { register, control, handleSubmit, formState } = useForm({
		defaultValues: {
			[fieldNames.initialContractId]: creative?.initialContractId || "",
			[fieldNames.type]: creative?.type ? typeAdvertisingCompanyOptions.find(option => option.value === creative.type).value : typeAdvertisingCompanyOptions[0].value,
			[fieldNames.form]: creative?.form ? formAdvertisingCompanyOptions.find(option => option.value === creative.form).value : formAdvertisingCompanyOptions[0].value,
			[fieldNames.isNative]: creative?.isNative || false,
			[fieldNames.urls]: creative?.urls || "",
			[fieldNames.okveds]: creative?.okveds || "",
			[fieldNames.description]: creative?.description || "",
			[fieldNames.isSocial]: creative?.isSocial || false,
		},
		resolver: yupResolver(validationSchema)
	});
	const { errors: validationErrors } = formState;
	
	const onSubmit = async (data) => {
		const { initialContractId, file, ...restData } = data;
		const formData = new FormData();
		Object.keys(restData).forEach((key) => {
			const value = restData[key];
			if (value !== null && value !== undefined) {
				formData.append(key, value);
			}
		});
		if (file[0]) {
			formData.append("file", file[0]);
		}
		formData.append("offerId", offer._id);
		formData.append("initialContractId", data.initialContractId.value);
		formData.append("sendToOrd", true);
		
		
		if (isEdit) {
			await dispatch(updateCreative(creative._id, formData));
			return;
		}
		
		await dispatch(createCreative(formData));
		dispatch(removeModal(sendCreativeToOrdModalId));
	};
	
	const handleSubmitWithoutOrd = async (data) => {
		const { initialContractId, file, ...restData } = data;
		const formData = new FormData();
		Object.keys(restData).forEach((key) => {
			const value = restData[key];
			if (value) {
				formData.append(key, value);
			}
		});
		if (file[0]) {
			formData.append(fieldNames.file, file[0]);
		}
		formData.append("offerId", offer._id);
		formData.append("initialContractId", data.initialContractId.value);
		
		if (isEdit) {
			dispatch(updateCreative(creative._id, formData));
			return;
		}
		
		dispatch(createCreative(formData));
	};
	
	const handleOpenSendToOrdModal = (e) => {
		e.preventDefault();
		const modalParams = {
			id: sendCreativeToOrdModalId,
			size: "middle",
			children: <ConfirmModal
				title="Данные будут отправлены в ЕРИР. <br/> Подтвердите отправку."
				onConfirm={handleSubmit(onSubmit)}
				onCancel={handleCancel(sendCreativeToOrdModalId)}/>
		};
		dispatch(addModal(modalParams));
	};
	
	const handleDeleteCreative = async () => {
		await dispatch(deleteCreative(creative._id));
		dispatch(removeModal(deleteCreativeModalId));
		onCancel();
	};
	
	const handleCancel = (modalId) => () => {
		dispatch(removeModal(modalId));
	};
	
	const handleCancelDelete = () => {
		dispatch(removeModal(deleteCreativeModalId));
	};
	
	const handleDeleteCreativeMedia = async () => {
		await dispatch(deleteCreativeMedia(creative._id, creative.mediaDataPath));
	}
	
	const handleOpenConfirmModal = () => {
		const modalParams = {
			id: deleteCreativeModalId,
			size: "middle",
			children: <ConfirmModal
				title="Креатив будет удален.<br/>Подтвердите."
				onConfirm={handleDeleteCreative}
				onCancel={handleCancelDelete}/>
		};
		dispatch(addModal(modalParams));
	};
	
	// TODO add tooltip for isNative
	return (
		<div>
			<BackButton onClick={onCancel}/>
			<div className={styles.formHeader}>
				<div className={styles.titleWrapper}>
					<div className={styles.title}>
						<h2>
							{isEdit ? "Редактирование креатива" : "Создание креатива"}
						</h2>
						<CompanyLabel>
							{title}
						</CompanyLabel>
					</div>
					<div className={styles.subTitle}>
						{!isEdit && "Заполните информацию о договоре"}
						{isEdit && <SendingStatus isSent={!!creative?.erid}/>}
					</div>
				</div>
				{isEdit && (
					<Button type="danger"
					        className={styles.deleteBtn}
					        disabled={!!creative?.erid}
					        onClick={handleOpenConfirmModal}>
						Удалить
					</Button>
				)}
			</div>
			<form
				encType="multipart/form-data"
				className={styles.form}>
				<div className={styles.field}>
					<ContractsSelect
						control={control}
						fieldName={fieldNames.initialContractId}
						label="Изначальный договор"
						errorsMessage={validationErrors[fieldNames.initialContractId]?.message}
					/>
				</div>
				<div className={styles.field}>
					<Controller control={control} render={({ field }) => (
						<Select
							{...field}
							label="Тип рекламной компании"
							options={typeAdvertisingCompanyOptions}
							value={typeAdvertisingCompanyOptions.find(option => option.value === field.value)}
							onChange={(value) => field.onChange(value.value)}
							isSearchable={false}
							isRequired
						/>
					)} name={fieldNames.type}/>
				</div>
				<div className={styles.field}>
					<Controller control={control} render={({ field }) => (
						<Select
							{...field}
							label="Форма распространения рекламы"
							options={formAdvertisingCompanyOptions}
							value={formAdvertisingCompanyOptions.find(option => option.value === field.value)}
							onChange={(value) => field.onChange(value.value)}
							isSearchable={false}
							isRequired
						/>
					)} name={fieldNames.form}/>
				</div>
				<div className={styles.field}>
					<Controller render={
						({ field }) => (
							<Checkbox
								{...field}
								label="Является нативной рекламой"
								checked={field.value}
							/>
						)
					} control={control} name={fieldNames.isNative}/>
				</div>
				<div className={styles.field}>
					<Controller render={
						({ field }) => (
							<Checkbox
								{...field}
								label="Является социальной рекламой"
								checked={field.value}
							/>
						)
					} control={control} name={fieldNames.isSocial}/>
				</div>
				<div className={styles.field}>
					<Input
						label="Целевая ссылка (URL)"
						{...register(fieldNames.urls)}
						placeholder="Введите описание"
						isRequired
						errorMessage={validationErrors[fieldNames.urls]?.message}
					/>
				</div>
				<div className={styles.field}>
					<Input
						label="Коды ОКВЭД для объекта рекламирования"
						{...register(fieldNames.okveds)}
						placeholder="Введите описание"
						isRequired
						errorMessage={validationErrors[fieldNames.okveds]?.message}
					/>
				</div>
				<div className={styles.field}>
					<Input
						label="Описание объекта рекламирования"
						{...register(fieldNames.description)}
						placeholder="Введите описание"
						isRequired
						errorMessage={validationErrors[fieldNames.description]?.message}
					/>
				</div>
				<FileUpload
					title="Медиаданные"
					description="В файле необходимо указывать только созданные ранее площадки. Если у вас нет готовых площадок, создайте их по ссылке."
					register={register}
					fieldName={fieldNames.file}
					initialFilePath={creative?.mediaDataPath}
					onDelete={handleDeleteCreativeMedia}
				/>
				<div className={styles.buttons}>
					<Button type="secondary"
					        disabled={!!creative?.erid}
					        onClick={handleSubmit(handleSubmitWithoutOrd)}>
						Сохранить без отправки
					</Button>
					<Button
						onClick={handleOpenSendToOrdModal}
						disabled={!!creative?.erid}
					>
						Сохранить и отправить в ОРД
					</Button>
				</div>
			</form>
		</div>
	);
};