import { forwardRef } from "react";
import styles from "./Select.module.css";
import "./Select.css";
import DefaultSelect from "react-select";

const Select = forwardRef(({ label = "", isRequired, ...selectProps }, ref) => {
	return (
		<div className={styles.wrapper}>
			<label className={styles.label}>
				{label}
				{isRequired && "*"}
			</label>
			<DefaultSelect
				{...selectProps}
				ref={ref}
				classNamePrefix="select"
			/>
		</div>
	);
});

Select.displayName = "Select";

export { Select };