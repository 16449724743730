import $api from "../core/api";
import { processError } from "../core/helpers";
import {
	createPlatformSuccess, deletePlatformSuccess,
	fetchPlatformsSuccess,
	updatePlatformSuccess
} from "../reducers/platformReducer";


export const prefixUrl = "/api/platforms";

export const createPlatform = (data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/create`, data);
			if (response.data) {
				dispatch(createPlatformSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const updatePlatform = (platformId, data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/update/${platformId}`, data);
			if (response.data) {
				dispatch(updatePlatformSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const fetchPlatforms = (params) => {
	return async dispatch => {
		try {
			const response = await $api.get(prefixUrl, { params });
			if (response.data) {
				dispatch(fetchPlatformsSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const deletePlatform = (platformId) => {
	return async dispatch => {
		try {
			const response = await $api.delete(`${prefixUrl}/delete/${platformId}`);
			if (response.data) {
				dispatch(deletePlatformSuccess(platformId));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};