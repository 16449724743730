import { NavLink } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../../assets/img/plus-icon.svg";
import styles from "./AddButton.module.css";

export const AddButton = ({ label, to, onClick, className }) => {
	
	const renderButton = () => {
		return (
			<button onClick={onClick} className={`${styles.btn} ${className}`}>
				<PlusIcon className={styles.icon}/>
				{label}
			</button>
		);
	};
	
	const renderLink = () => {
		return (
			<NavLink className={`${styles.btn} ${className}`} to={to}>
				<PlusIcon className={styles.icon}/>
				{label}
			</NavLink>
		);
	};
	
	return to ? renderLink() : renderButton();
};