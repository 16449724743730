import $api from "../core/api";
import { processError } from "../core/helpers";
import {
	createActSuccess,
	deleteActSuccess,
	fetchActsSuccess,
	updateActSuccess
} from "../reducers/actReducer";

const prefixUrl = "/api/act";

export const createAct = (data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/create`, data);
			if (response && response.data) {
				dispatch(createActSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const fetchActsByAdvertiser = (params) => {
	return async dispatch => {
		try {
			const response = await $api.get(`${prefixUrl}/get-acts-by-advertiser`, { params });
			if (response && response.data) {
				dispatch(fetchActsSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const deleteAct = (id) => {
	return async dispatch => {
		try {
			const response = await $api.delete(`${prefixUrl}/delete/${id}`);
			if (response && response.data) {
				dispatch(deleteActSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};

export const updateAct = (id, data) => {
	return async dispatch => {
		try {
			const response = await $api.post(`${prefixUrl}/update/${id}`, data);
			if (response && response.data) {
				dispatch(updateActSuccess(response.data));
			}
		} catch (e) {
			processError(dispatch, e);
		}
	};
};