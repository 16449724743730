import styles from "./Loader.module.css";

export const Loader = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.loader}>
				<div className={`${styles.loading} ${styles.one}`}></div>
				<div className={`${styles.loading} ${styles.two}`}></div>
				<div className={`${styles.loading} ${styles.three}`}></div>
				<div className={`${styles.loading}`}></div>
			</div>
		</div>
	);
};