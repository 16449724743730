import { Select } from "../../../../ui/updated/Select/Select";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchManagers } from "../../../../../actions/managers";

export const ManagersSelect = ({
	label = "",
	additionalOptions = null,
	field
}) => {
	const dispatch = useDispatch();
	const [managers, setManagers] = useState([]);
	
	const setFormattedManagers = (data) => {
		const formattedManagers = data.reduce((acc, cur) => {
			acc.push({
				label: `${cur.lastName} ${cur.firstName} - ${cur.email}`,
				value: cur._id
			});
			
			return acc;
		}, []);
		
		if (additionalOptions) {
			setManagers([...additionalOptions, ...formattedManagers]);
			return;
		}
		
		setManagers(formattedManagers);
	};
	
	useEffect(() => {
		dispatch(fetchManagers(setFormattedManagers));
		// eslint-disable-next-line
	}, []);
	
	return (
		<Select
			{...field}
			disable={managers.length === 0}
			label={label}
			options={managers}
			placeholder="Все"
			isSearchable={false}
		/>
	);
};