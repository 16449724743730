import styles from "./AdvertiserForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { Select } from "../../../../ui/updated/Select/Select";
import { EntityOptions } from "../../../../../core/config";
import { Input } from "../../../../ui/updated/Input/Input";
import {
	ManagersSelect
} from "../../../ordAdvertisers/Filters/ManagersSelect/ManagersSelect";
import { Button } from "../../../../ui/updated/Button/Button";
import { update } from "../../../../../actions/advertisers";
import { useDispatch } from "react-redux";

const fieldNames = {
	ordEntity: "ordEntity",
	inn: "inn",
	manager: "manager"
};

export const AdvertiserForm = ({ advertiser }) => {
	const dispatch = useDispatch();
	const { manager, inn } = advertiser;
	const { control, register, handleSubmit } = useForm({
		defaultValues: {
			[fieldNames.inn]: inn,
			[fieldNames.manager]: {
				label: `${manager.lastName} ${manager.firstName} - ${manager.email}`,
				value: manager.id
			},
			[fieldNames.ordEntity]: advertiser?.ordEntity ? EntityOptions.find(option => option.value === advertiser.ordEntity).value : EntityOptions[0].value
		}
	});
	
	const onSubmit = (data) => {
		const submitData = {
			id: advertiser.id,
			inn: data[fieldNames.inn],
			managerId: { id: data[fieldNames.manager].value },
			ordEntity: data[fieldNames.ordEntity],
			wasAddedToOrd: true
		};
		dispatch(update(submitData));
	};
	
	return (
		<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
			<Controller control={control} render={({ field }) => (
				<Select
					{...field}
					label="Юр. лицо"
					options={EntityOptions}
					isSearchable={false}
					value={EntityOptions.find(option => option.value === field.value)}
					onChange={option => field.onChange(option.value)}
					isRequired
				/>
			)} name={fieldNames.ordEntity}/>
			<div className={styles.inputWrapper}>
				<Input
					{...register(fieldNames.inn)}
					label="ИНН рекламодателя"
				/>
			</div>
			<div className={styles.inputWrapper}>
				<Controller control={control} render={({ field }) => (
					<ManagersSelect
						label="Менеджер рекламодателя в ОРД"
						field={field}/>
				)} name={fieldNames.manager}/>
			</div>
			<Button type="primary" className={styles.btn}>Сохранить</Button>
		</form>
	);
};