import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { Select } from "../updated/Select/Select";
import styles from "./ContractsSelect.module.css";
import $api from "../../../core/api";
import { prefixUrl } from "../../../actions/contracts";

const InitialContractOption = (props) => {
	const { data } = props;
	
	return (
		<div {...props.innerRef} {...props.innerProps} className={styles.wrapper}>
			<p className={styles.title}>{data.label}</p>
			<p className={styles.subTitle}>{data.customer} {data.executor}</p>
		</div>
	);
};
const formatContract = (contract) => {
	const date = contract.date ? format(new Date(contract.date), "dd.MM.yyyy") : "Не указана";
	
	return {
		value: contract.ordId,
		label: `${contract.number ?? ""} от ${date} (id: ${contract.ordId})`,
		customer: `Заказчик: ${contract.customerName ?? `ИНН: ${contract.customerInn}`}`,
		executor: `Исполнитель: ${contract.executorName ?? `ИНН: ${contract.executorInn}`}`
	};
};


export const ContractsSelect = ({ control, label = '', errorsMessage = '', fieldName }) => {
	const [onlyIsInitialContract, setOnlyIsInitialContract] = useState([]);
	const advertiser = useSelector(state => state.advertisers.current);
	
	useEffect(() => {
		if (advertiser && !onlyIsInitialContract.length) {
			const fetchContracts = async () => {
				// Без признака isInitialContract и если нет ordId (значение "True") нельзя будет привязать изначальный договор к Креативам и Актам.
				// И если у договора isReadyForErir = false, то его тоже не показывать.
				return await $api.get(`${prefixUrl}/contracts-in-ord`, {
					params: {
						advId: advertiser.id,
						isInitialContract: true,
						isReadyForErir: true
					}
				});
			};
			fetchContracts().then(response => {
				if (!response?.data) {
					return [];
				}
				setOnlyIsInitialContract(response.data);
			}).catch(error => {
				console.error(error);
			});
		}
		// eslint-disable-next-line
	}, []);
	
	const initialContractOptions = useMemo(() => {
		if (!onlyIsInitialContract.length) {
			return [];
		}
		return onlyIsInitialContract.map(formatContract);
	}, [onlyIsInitialContract]);
	
	const renderEmpty = () => {
		return (
			<div className={styles.empty}>
				Нет доступных договоров <br/>
				Для привязки договора он должен быть готовым к ЭРИР.
			</div>
		);
	}
	
	return (
		<Controller control={control} render={({ field }) => {
			const selected = initialContractOptions.find(option => option.value === field.value);
			return (
				<>
					<Select
						{...field}
						noOptionsMessage={renderEmpty}
						label={label}
						options={initialContractOptions}
						value={selected}
						onChange={selectedOption => field.onChange(selectedOption)}
						isSearchable={false}
						placeholder="Выберите изначальный договор"
						components={{ Option: InitialContractOption }}
						isRequired
					/>
					{errorsMessage && <div className={styles.errorMessage}>{errorsMessage}</div>}
				</>
			);
		}} name={fieldName}/>
	);
};