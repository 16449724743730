import React, { useEffect, useState } from 'react';
import styles from './AdvertiserCard.module.css';
import { fetchAdvertiserById } from '../../../../actions/advertisers';
import { useDispatch, useSelector } from "react-redux";
import AppContent from "../../../appContent/AppContent";
import GeneralAdvTab from "./AdvertiserCardTabs/GeneralAdvTab";
import { NavLink } from "react-router-dom";
import DocumentsTab from "./AdvertiserCardTabs/DocumentsTab";
import OffersTab from "./AdvertiserCardTabs/OffersTab";
import ContentLoader from "../../../ui/contentLoader/ContentLoader";
import { setCurrentAdvertiser } from "../../../../reducers/advertisersReduser";
import { delayByLoader } from '../../../../utils/deleyByLoader';


const AdvertiserCard = ({ match }) => {
    const dispatch = useDispatch();
    const advertiserId = match.params.advertiserId;
    const advertiser = useSelector(state => state.advertisers.current);
    const [loading, setLoading] = useState(true);
    const [contentLoader, setContentLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('general');

    useEffect(() => {
        dispatch(fetchAdvertiserById(advertiserId, setLoading));
        return () => {
            dispatch(setCurrentAdvertiser(null));
        }
    }, [dispatch, advertiserId]);

    const onTabClickHandler = (value) => {
        delayByLoader(true, setContentLoader, 300);
        setActiveTab(value);
    };

    return (
        <AppContent>
            <div className={`container wrapper`}>
                {loading && <ContentLoader />}
                {advertiser &&
                    <div className='row'>
                        <div className='col-12'>
                            <div className='m-3'><NavLink className={styles.BackLink} to="/advertisers">Назад</NavLink></div>
                            <div className={styles.AdvCardContent}>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <h1 className={styles.Title}>Рекламодатель {advertiser.title}</h1>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className={`col-2 pr-0 ${styles.BorderRight}`}>
                                        <ul className={styles.TabLinks}>
                                            <li className={activeTab === 'general' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('general')}>Основное</li>
                                            <li className={activeTab === 'docs' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('docs')}>Документы</li>
                                            <li className={activeTab === 'offers' ? `${styles.Active}` : ''} onClick={() => onTabClickHandler('offers')}>Офферы</li>
                                        </ul>
                                    </div>
                                    <div className={`col-10 ${styles.advertiserCard}`} >
                                        {
                                            contentLoader ? <div className={styles.contentLoader}>
                                                <ContentLoader />
                                            </div> :
                                                <>
                                                    {activeTab === 'general' &&
                                                        <GeneralAdvTab advertiser={advertiser} />
                                                    }
                                                    {activeTab === 'docs' &&
                                                        <DocumentsTab advertiser={advertiser} />
                                                    }
                                                    {activeTab === 'offers' &&
                                                        <OffersTab offers={advertiser.offers} />
                                                    }
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppContent>
    );
};

export default AdvertiserCard;
