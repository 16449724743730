import styles from "./PlatformTableRow.module.css";
import {
	ReactComponent as EditIcon
} from "../../../../assets/img/edit-icon-2.svg";

export const PlatformTableRow = ({ item, onEdit }) => {
	return (
		<tr>
			<td className={styles.number}>{item.ordId ?? "-"}</td>
			<td>{item.partner?.affiseId}</td>
			<td>{item.partner?.email}</td>
			<td>status</td>
			<td>{item.company}</td>
			<td>{item.name}</td>
			<td>{item.url}</td>
			<td>{item.category}</td>
			<td>
				<button className={styles.editBtn} onClick={onEdit}>
					<EditIcon className={styles.editIcon}/>
				</button>
			</td>
		</tr>
	);
};
