import AppContent from "../../appContent/AppContent";
import { AddButton } from "../../ui/addButton/AddButton";
import React, { useEffect, useState } from "react";
import { PlatformForm } from "./AddPlatformForm/PlatformForm";
import { Table } from "../../ui/Table/Table";
import Pagination from "../../ui/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlatforms } from "../../../actions/platform";
import { PlatformTableRow } from "./PlatformTableRow/PlatformTableRow";

const LIMIT_PER_PAGE = 10;

const PlatformsPage = () => {
	const dispatch = useDispatch();
	const [addPlatform, setAddPlatform] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [editingPlatform, setEditingPlatform] = useState(null);
	const {
		loading,
		items,
		pagination
	} = useSelector(state => state.platform);
	
	useEffect(() => {
		dispatch(fetchPlatforms({
			page: 1,
			limit: LIMIT_PER_PAGE
		}));
		// eslint-disable-next-line
	}, []);
	
	const handleEditPlatform = (platformId) => () => {
		setIsEdit(true);
		setEditingPlatform(platformId);
	};
	
	const handlePageChange = (page) => {
		if (page === pagination.page) {
			return;
		}
		
		dispatch(fetchPlatforms({
			page,
			limit: LIMIT_PER_PAGE
		}));
	};
	
	const handleAddPlatform = () => {
		setAddPlatform(true);
	};
	
	const handleBack = () => {
		setIsEdit(false);
		setAddPlatform(false);
		dispatch(fetchPlatforms({
			page: 1,
			limit: LIMIT_PER_PAGE
		}));
	};
	
	const renderTable = () => {
		return <div>
			<AddButton label="Добавить площадку" onClick={handleAddPlatform}/>
			<Table
				loading={loading}
				thead={
					<tr>
						<th>Номер площадки в ОРД</th>
						<th>ID вебмастера XP</th>
						<th>Email вебмастера</th>
						<th>Статус</th>
						<th>Юр. лицо</th>
						<th>Название площадки</th>
						<th>Ссылка</th>
						<th>Категория офферов</th>
						<th></th>
					</tr>
				}
				tbody={items.map(item => (
					<PlatformTableRow item={item} key={item._id}
					                  onEdit={handleEditPlatform(item._id)}/>
				))}
			/>
			<Pagination paginationData={pagination}
			            setParamData={handlePageChange}/>
		</div>;
	};
	
	const renderAddPlatform = () => {
		return <PlatformForm onBack={handleBack}/>;
	};
	
	const renderEditPlatform = () => {
		return <PlatformForm onBack={handleBack}
		                     isEdit
		                     platform={items.find(item => item._id === editingPlatform)}/>;
	};
	
	return (
		<AppContent>
			<div className="container wrapper">
				{addPlatform ? renderAddPlatform() : isEdit ? renderEditPlatform() : renderTable()}
			</div>
		</AppContent>
	);
};

export default PlatformsPage;